import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Softwere Development.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const SoftwareDevelopment = () => {
  return (
    <>
    <Helmet>
        <title>Software Development Services in India | Deeva Payon</title>
        <meta
          name="description"
          content="Get custom software solutions from the best software development Services in India. Mobile app, web, cloud, CRM, e-commerce, testing, and API services."
        />
        <meta
          name="keywords"
          content="Software Development Services in India, Software solutions company in india, Software solutions company, Software Development Services"
        />
      </Helmet>
      <Header />
      {/* Hero Section */}
      <Box
        sx={{
          backgroundColor: '#fff',
          padding: { xs: '4rem 0', md: '7rem 0' },
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          {/* <Typography
            variant="h1"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: '#1976d2',
              marginBottom: '2rem',
              textTransform: 'uppercase',
              letterSpacing: '2px',
              fontSize: { xs: '2rem', md: '3rem' },
            }}
          >
            Software Development Company  in India
          </Typography> */}
          <Grid container spacing={6}>
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h1"
                sx={{
                  marginBottom: '1.5rem',
                  fontSize: "2.2rem",
                  color: '#1976d2',
                  fontWeight: '600',
                }}
              >
                Software Development Company  in India
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ color: '#000', lineHeight: '1.8' }}
              >
                As a leading provider of the best software development services
                in India, we excel in crafting high-quality, customized digital
                solutions designed to meet the unique requirements of businesses
                across various industries. Our team of experienced developers
                leverages the latest technologies and methodologies to ensure
                that your projects are completed on time and within budget.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ color: '#000', lineHeight: '1.8' }}
              >
                We empower your business with top-notch software development
                services, offering custom software solutions and a dedicated team
                of competent software developers who ensure streamlined business
                processes, productivity, and profitability.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Code />}
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>
            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <Card
                elevation={3}
                sx={{
                  borderRadius: '12px',
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={Softimg}
                  alt="Software Development"
                  sx={{
                    height: { xs: 'auto', md: '400px' },
                    objectFit: 'cover',
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Software Development Services Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          mb: 4, // Margin bottom for spacing
          
          backgroundColor: '#f4f6f8',
          padding: { xs: '4rem 0', md: '3rem 0' },
        }}
      >
        <Container maxWidth="lg">
          <Typography
  variant="h4"
  align="center"
  gutterBottom
  sx={{
    fontWeight: "bold",
    color: "#fff", // White text color
    background: "#1976d2", // Background color
    borderRadius: "16px", // Rounded corners
    padding: "10px 20px", // Padding for better spacing
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
    textTransform: "uppercase", // Uppercase text
    letterSpacing: "2px", // Spacing between letters
    display: "flex", // Use flexbox for centering
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    margin: "0 auto", // Center the box itself
    maxWidth: "fit-content", // Ensure the box only wraps the content
  }}
>
  Our Software Development Services
</Typography>
          <Typography
            variant="body1"
            align="center"
            paragraph
            sx={{ color: '#555', lineHeight: '1.8' }}
          >
            Explore our wide range of software development services tailored to
            meet your business needs.
          </Typography>
          <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
            {[
              {
                icon: <PhoneIphone />,
                title: 'Mobile App Development',
                description:
                  'In today’s mobile-driven world, having a robust mobile application is essential. Our mobile app development services create user-friendly, scalable, and secure applications for both Android and iOS platforms.',
              },
              {
                icon: <Web />,
                title: 'Website Development',
                description:
                  'Establishing a robust online presence is essential for the success of any business. Our website development services focus on creating responsive, visually appealing, and functional websites.',
              },
              {
                icon: <Cloud />,
                title: 'Cloud Solutions',
                description:
                  'Embrace the power of cloud computing with our cloud solutions. We provide scalable cloud services that enhance collaboration, increase efficiency, and reduce operational costs.',
              },
              {
                icon: <People />,
                title: 'CRM Solutions',
                description:
                  'Manage customer relationships effectively with our custom CRM solutions. We develop CRM systems that help you track interactions, manage sales pipelines, and improve customer service.',
              },
              {
                icon: <ShoppingCart />,
                title: 'E-Commerce Services',
                description:
                  'Expand your business online with our comprehensive e-commerce services. We build secure and scalable e-commerce platforms that provide seamless shopping experiences.',
              },
              {
                icon: <Api />,
                title: 'API Development',
                description:
                  'Enhance your application’s functionality with our robust API development services. We create APIs that facilitate seamless integration between different systems and platforms.',
              },
            ].map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: '#f9f9f9',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                    },
                  }}
                >
                  <CardContent>
                    <Avatar
                      sx={{
                        backgroundColor: '#1976d2',
                        width: '50px',
                        height: '50px',
                        margin: '0 auto 1rem',
                      }}
                    >
                      {service.icon}
                    </Avatar>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
                    >
                      {service.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      paragraph
                      sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
                    >
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Why Choose Us Section */}
      <Box
  sx={{
    backgroundColor: '#f4f6f8',
    padding: { xs: '4rem 0', md: '3rem 0' },
  }}
>
  <Container maxWidth="lg">
    {/* Title Section */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Background color
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us?
    </Typography>

    {/* Introductory Description */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.8' }}
    >
      Expertise in diverse technologies, client-centric approach, and cost-effective solutions make us the best choice for your software development needs.
    </Typography>

    {/* List of Reasons with Descriptions */}
    <List
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'center',
        gap: '1rem',
        marginTop: '2rem',
      }}
    >
      {[
        {
          icon: <CheckCircleOutline />,
          title: 'Expertise in Diverse Technologies',
          description:
            'Our team is proficient in various programming languages and frameworks, ensuring we can handle projects of any complexity.',
        },
        {
          icon: <People />,
          title: 'Client-Centric Approach',
          description:
            'We prioritize understanding your business needs to deliver customized solutions that drive results.',
        },
        {
          icon: <Code />,
          title: 'Cost-Effective Solutions',
          description:
            'Being based in India allows us to offer competitive pricing without compromising on quality.',
        },
        {
          icon: <Api />,
          title: 'Proven Track Record',
          description:
            'With numerous successful projects under our belt, we have established ourselves as a trusted partner for software development services in India.',
        },
      ].map((reason, index) => (
        <ListItem
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: '#fff',
            padding: '1.5rem 2rem',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'translateY(-5px)',
            },
          }}
        >
          {/* Icon and Title */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem',
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px', color: '#1976d2' }}>
              {reason.icon}
            </ListItemIcon>
            <ListItemText
              primary={reason.title}
              primaryTypographyProps={{
                variant: 'body1',
                sx: { fontWeight: '600', color: '#333' },
              }}
            />
          </Box>

          {/* Description */}
          <Typography
            variant="body2"
            sx={{
              color: '#555',
              lineHeight: '1.6',
              textAlign: 'left',
            }}
          >
            {reason.description}
          </Typography>
        </ListItem>
      ))}
    </List>

    {/* Conclusion Section */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{
        color: '#555',
        lineHeight: '1.8',
        marginTop: '3rem',
      }}
    >
      Let's collaborate on your software development needs and experience innovative solutions designed to propel your business forward because we have the best software development services in India.{' '}
      <Link href="/contact" underline="always" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
        Contact us
      </Link>{' '}
      to discuss how we can help you achieve your goals!
    </Typography>
  </Container>
</Box>
      <CallToAction />
      <Footer />
    </>
  );
};

export default SoftwareDevelopment;