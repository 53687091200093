// src/pages/SocialMediaGraphics.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  FormatPaint,
  Facebook,
  Instagram,
  Twitter,
  LinkedIn,
  Pinterest,
  ContentCopy,
  EventAvailable,
  Animation,
  SearchOff,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deeva Payon Social Media Marketing Services India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const SocialMediaGraphics = () => {
  return (
    <>

<Helmet>
  <title>Social Media Graphics Design Services | Creative Social Media Visuals</title>
  <meta name="description" content="Boost your social media presence with custom social media graphics. Our professional design services include Facebook, Instagram, Twitter, LinkedIn, and more. Engage your audience with visually stunning content." />
  <meta name="keywords" content="Social Media Graphics, Social Media Design Services, Custom Social Media Graphics, Facebook Graphic Design, Instagram Post Design, Twitter Graphics Design, LinkedIn Banner Design, Social Media Ads Design, Animated Social Media Graphics, Pinterest Pin Design" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Social Media Graphics | Creative Designs for Social Media Platforms
              </Typography>
              <Typography variant="body1" paragraph>
              In today’s digital age, social media graphics are essential for creating a strong online presence. Whether you're looking to promote a product, engage with followers, or establish brand consistency, visually appealing social media designs are key to standing out. 
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we specialize in crafting engaging social media graphics that capture attention and drive results.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Social Media Graphics Design"
                title="Deevapayon, Social Media Graphics Design Services"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us for Your Social Media Graphics?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading provider of social media graphics, we understand that each platform—whether it’s Facebook, Instagram, Twitter, LinkedIn, or Pinterest—requires unique design styles to capture the attention of its audience. Our creative team at [Your Company Name] works closely with you to design graphics that align with your brand identity and speak directly to your target audience.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Tune />,
          title: 'Tailored Designs',
          description:
            'We create unique, platform-specific social media graphics that ensure your message resonates with your audience.',
        },
        {
          icon: <FormatPaint />,
          title: 'Brand Consistency',
          description:
            'We ensure that your graphics maintain consistency across all platforms, helping to build a cohesive and recognizable brand image.',
        },
        {
          icon: <ThumbUp />,
          title: 'Engaging Content',
          description:
            'Our designs are crafted to be visually compelling, encouraging likes, shares, comments, and increased engagement.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Social Media Graphics Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer a range of social media graphics services to elevate your online marketing efforts. From custom designs to timely content creation, we provide all the services you need to succeed on social media.
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Facebook />,
          title: 'Facebook Graphics Design',
          description:
            'Make your Facebook posts, banners, and ads stand out with professional Facebook graphics design. We create visuals that are optimized for Facebook’s dimensions and designed to capture the attention of your followers.',
        },
        {
          icon: <Instagram />,
          title: 'Instagram Post & Story Graphics',
          description:
            'Instagram graphics are all about creativity and storytelling. We design eye-catching posts, Stories, and Highlights that align with your brand aesthetic and engage your audience.',
        },
        {
          icon: <Twitter />,
          title: 'Twitter Header & Post Design',
          description:
            'Create a strong visual impact on Twitter with our Twitter graphics services. We design custom header images, tweets, and graphics that stand out in the fast-paced Twitter feed and drive engagement.',
        },
        {
          icon: <LinkedIn />,
          title: 'LinkedIn Graphics Design',
          description:
            'For a professional and polished image, our LinkedIn graphics design services help create banners, infographics, and posts that reflect your expertise and elevate your brand’s authority on the platform.',
        },
        {
          icon: <Pinterest />,
          title: 'Pinterest Pin Design',
          description:
            'Pinterest is a visual-driven platform that thrives on high-quality, attention-grabbing graphics. Our Pinterest pin design services help you create visually stunning and shareable pins to boost your visibility and engagement.',
        },
        {
          icon: <SearchOff />,
          title: 'Social Media Ads Design',
          description:
            'We specialize in designing social media ads that are visually compelling and conversion-driven. Whether it’s for Facebook, Instagram, or Google Ads, our social media ads design services are tailored to grab attention and encourage clicks.',
        },
        {
          icon: <Animation />,
          title: 'Animated Social Media Graphics',
          description:
            'Add motion to your posts with animated social media graphics. We offer custom animated graphics that increase user interaction and make your content more dynamic and engaging.',
        },
        {
          icon: <Animation />,
          title: 'Custom Social Media Templates',
          description:
            'Save time and maintain consistency with custom social media templates. We create editable templates for posts, Stories, and banners, ensuring your social media content is always on-brand and ready to go.',
        },
        {
          icon: <EventAvailable />,
          title: 'Event Promotion Graphics',
          description:
            'Promote your events effectively with specially designed event graphics. Our event promotion graphic design services help create excitement and boost attendance through visually appealing event banners and posts for social media.',
        },
        {
          icon: <ContentCopy />,
          title: 'Social Media Content Packages',
          description:
            'If you need ongoing content for your social channels, our social media content package services offer a bundle of customized graphics, including posts, stories, banners, and ads, designed to keep your social presence active and engaging.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Why Social Media Graphics Matter
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Strong visuals are proven to drive more engagement, clicks, and conversions on social media. As social media becomes more competitive, businesses need to create graphics that not only look great but also resonate with their target audience. High-quality social media graphics can increase your brand visibility, improve customer engagement, and enhance the overall user experience.

    </Typography>

    <Typography variant="h4" align="center" gutterBottom>
    Get Started Today!
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Ready to boost your social media presence with stunning social media graphics? Deevapayon is here to help you create visuals that engage, inform, and convert. Contact us today to get started, and let’s take your social media marketing to the next level!

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default SocialMediaGraphics;