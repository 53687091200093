import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CartProvider from './CartContext';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider

// Create the root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app with HelmetProvider and CartProvider
root.render(
  <React.StrictMode>
    <HelmetProvider> {/* Wrap the app with HelmetProvider */}
      <CartProvider>
        <App />
      </CartProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();