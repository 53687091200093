import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Modal, Fade, Backdrop, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';

const ContactFormPopup = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: '',
    dateTime: new Date().toLocaleString(),
  });
  const [alert, setAlert] = useState({ open: false, severity: 'success', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const scriptURL =
        "https://script.google.com/macros/s/AKfycbyzTI3c8OSU3kIqnH7dGfAAtarsm0f_BA-86Cf778-nIqL1g0Ju6-ZENrK900JbIttAFw/exec";
      await axios.post(scriptURL, new URLSearchParams(formData));
      setAlert({ open: true, severity: 'success', message: 'Form submitted successfully!' });
      setFormData({
        name: '',
        email: '',
        phone: '',
        service: '',
        message: '',
        dateTime: new Date().toLocaleString(),
      });
      onClose(); // Close the modal after successful submission
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Submission error, please try again' });
    }
  };

  const handleCloseAlert = () => setAlert({ ...alert, open: false });

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { xs: '90%', md: 700 },
              bgcolor: 'background.paper',
              borderRadius: '16px',
              boxShadow: 24,
              overflow: 'hidden',
            }}
          >
            {/* Left Side */}
            <Box
              sx={{
                flex: 1,
                p: 3,
                bgcolor: '#f5f3eb',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundImage: 'url(https://www.transparenttextures.com/patterns/diamond-upholstery.png)',
                color: '#333',
              }}
            >
              <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                DeevaPayon Promises
              </Typography>
              <ul>
                {[
                  'NDA acknowledged and signed',
                  'Free Advice & Estimate',
                  'Confirmed Client Satisfaction',
                  'Cheapest Price Guaranteed',
                  'Free post-delivery support for one month',
                  'Scalable and effective apps',
                ].map((item) => (
                  <li key={item} style={{ marginBottom: '10px' }}>
                    {item}
                  </li>
                ))}
              </ul>
            </Box>
            {/* Right Side Form */}
            <Box sx={{ flex: 1, p: 3, bgcolor: '#fff', position: 'relative' }}>
              <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" mb={2} fontWeight="bold">
                Schedule a Call with Our Expert
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField
                    name="name"
                    label="Name"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    name="phone"
                    label="Mobile Number"
                    fullWidth
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField
                    name="email"
                    label="Email"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    name="service"
                    label="Service Required"
                    fullWidth
                    value={formData.service}
                    onChange={handleChange}
                  />
                </Box>
                <TextField
                  name="message"
                  label="Let us know a bit more about the project you have in mind ..."
                  multiline
                  rows={4}
                  fullWidth
                  value={formData.message}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    bgcolor: '#007BFF',
                    color: '#fff',
                    textTransform: 'none',
                    ':hover': { bgcolor: '#0056b3' },
                  }}
                >
                  Let's start a conversation
                </Button>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Snackbar open={alert.open} autoHideDuration={4000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactFormPopup;