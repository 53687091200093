import React from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";

// Animation for sliding and fading in
const fadeInSlideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CompanyBanner = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #1976d2, #64b5f6)", // Gradient using your primary blue
        color: "#fff",
        textAlign: "center",
        padding: { xs: "12px 16px", sm: "18px 25px" }, // Responsive padding
        borderRadius: "12px", // Slightly rounded corners
        margin: "30px auto",
        maxWidth: "1200px",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.2)", // Soft shadow effect
        animation: `${fadeInSlideUp} 0.8s ease-out`, // Slide-up animation
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          boxShadow: "0 8px 25px rgba(0, 0, 0, 0.3)", // Stronger shadow on hover
          transform: "translateY(-5px)", // Slight lift effect
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: { xs: "18px", sm: "22px", md: "24px" }, // Responsive font size
          fontWeight: "bold",
          letterSpacing: "0.8px",
          textTransform: "uppercase", // Stylish text transformation
          background: "linear-gradient(45deg, #ffffff, #f0f8ff)", // Subtle gradient for text
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Leading Software Development Company in India
      </Typography>
    </Box>
  );
};

export default CompanyBanner;