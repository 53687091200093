// src/components/Hero.js
import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { blue } from '@mui/material/colors';
import ContactFormPopup from './ContactFormPopup'; // Import the popup form

// import videoSrc from "../../public/";

// const Hero = () => {
//   const scrollToServices = () => {
//     document.getElementById('services-section').scrollIntoView({ behavior: 'smooth' });
//   };

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleOpenModal = () => setIsModalOpen(true); // Open the modal
  const handleCloseModal = () => setIsModalOpen(false); // Close the modal

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        color: '#ffff',
        textAlign: 'center',
      }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {/* Video Background */}
      <Box
        component="video"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover', // Make the video cover the whole area
          zIndex: -1,
        }}
        src={`${process.env.PUBLIC_URL}/hero-video.mp4`} // Use PUBLIC_URL to access public directory
        autoPlay
        loop
        muted
      />

<Typography
  variant="h1"
  gutterBottom
  sx={{
    fontWeight: 'bold',
    letterSpacing: '0.15rem',
    color: '#1A7BB2', 
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', 
    fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, 
    textAlign: 'center', 
    lineHeight: 1.2, 
    WebkitTextStroke: '1px black', 
  }}
>
Deeva Payon, 
</Typography>

<Typography
  variant="h1"
  gutterBottom
  sx={{
    fontWeight: 'bold',
    letterSpacing: '0.15rem',
    color: '#1A7BB2', 
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', 
    fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, 
    textAlign: 'center', 
    lineHeight: 1.2, 
    WebkitTextStroke: '1px black', 
  }}
>
Transform Your Business with Expert Software Development Company in India
</Typography>

      {/* <Button
        variant="contained"
        color="secondary"
        onClick={scrollToServices}
        sx={{ 
          padding: '0.75rem 1.5rem', 
          fontSize: '1rem',
          backgroundColor: '#FF5733', // Change button background color
          '&:hover': {
            backgroundColor: '#FFC300', // Change button hover color
            boxShadow: '0px 4px 20px rgba(255, 87, 51, 0.5)', // Add shadow on hover
          }
        }}
        component={motion.div}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Discover More 
      </Button> */}

      {/* Updated Button */}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpenModal} // Open the modal on click
        sx={{
          padding: '0.75rem 1.5rem',
          fontSize: '1rem',
          backgroundColor: '#FF5733', // Change button background color
          '&:hover': {
            backgroundColor: '#FFC300', // Change button hover color
            boxShadow: '0px 4px 20px rgba(255, 87, 51, 0.5)', // Add shadow on hover
          },
        }}
        component={motion.div}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Book a Free Consultation
      </Button>

      {/* Contact Form Popup */}
      <ContactFormPopup isOpen={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default Hero;