// src/pages/HostingServer.js
import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CheckCircleOutline,
  Code,
  PhoneIphone,
  Web,
  Cloud,
  People,
  ShoppingCart,
  Api,
  DirectionsBus,
  Bolt,
  WaterDrop,
  LocalFireDepartment,
  Toll,
  CreditCard,
  Fingerprint,
  AccountBalance,
  Paid,
  Dns,
  Computer,
  SettingsApplications,
  Https,
  Email,
  WebAsset,
  Storage,
  Public,
  BarChart,
  Settings,
  NotificationsActive,
  HttpsOutlined,
  Sync,
  Shield,
  SupportAgent,
  DomainAdd,
  TrendingUp,
  VerifiedUser,
  PriceCheck,
  Speed,
  LocationOn,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Best Domain Service Provider in India.jpeg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { FaWordpress } from 'react-icons/fa';

const Domain = () => {
  return (
    <>
      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Best Domain Service Provider in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deeva Payon, we are proud to be recognized as the best domain service provider in India, offering a seamless and efficient experience for registering, managing, and securing your domain name. Whether you’re launching a personal blog, building an online store, or establishing a corporate presence, we ensure that your domain needs are met with professionalism, reliability, and affordability.
              </Typography>
              <Typography variant="body1" paragraph>
              Our goal is to make it easy for you to secure your online identity and provide you with the tools necessary to succeed on the web.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Best domain registration services in India - Secure and reliable"
                title="Best Domain Service Provider in India - Deeva Payon"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deeva Payon as the Best Domain Service Provider in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '3' }}
    >
      As a leading <b>domain service provider</b> we offer a range of benefits to our customers:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
        {
          icon: <DomainAdd />,
          title: 'Wide Range of Domain Extensions',
          description:
            'Choose from a wide selection of domain extensions (.com, .in, .net, .org, and more) to best match your brand, business, or personal project. Our diverse options allow you to find the perfect domain that fits your unique online identity.',
        },
        {
          icon: <Storage />,
          title: 'Affordable Domain Registration',
          description:
            'As the best domain service provider in India, we offer competitive and transparent pricing with no hidden fees. Our domain registration services are designed to provide maximum value at affordable rates, making it easy for anyone to start their online journey.',
        },
        {
          icon: <Web />,
          title: 'Easy-to-Use Control Panel',
          description:
            'We provide an intuitive and user-friendly control panel that allows you to manage your domain effortlessly. From DNS settings to domain forwarding and email management, our platform offers a comprehensive suite of tools for managing your domain.',
        },
        {
          icon: <SupportAgent />,
          title: '24/7 Customer Support',
          description:
            'Our expert customer support team is available around the clock to assist you with any domain-related queries. Whether you need help with registration, domain transfer, or DNS settings, we are here to provide timely and effective solutions.',
        },
        {
          icon: <Shield />,
          title: 'Domain Privacy Protection',
          description:
            'We understand the importance of protecting your personal information online. Our domain privacy protection service helps shield your personal details from public WHOIS databases, keeping your data secure and confidential.',
        },
        {
          icon: <Sync />,
          title: 'Domain Transfer Services',
          description:
            'Moving your domain to Deeva Payon is a seamless process. Our domain transfer services ensure a hassle-free transition with minimal downtime, so you can continue your online operations without interruption.',
        },
        {
          icon: <Email />,
          title: 'Website and Email Hosting',
          description:
            'In addition to domain registration, we also offer reliable web hosting and email hosting services. With high-speed servers, strong security, and 24/7 customer support, you can manage both your domain and hosting needs under one roof.',
        },
        {
          icon: <HttpsOutlined />,
          title: 'SSL Certificates for Secure Websites',
          description:
            'Ensure your website’s security with our SSL certificates. By adding SSL to your domain, you can encrypt sensitive data and boost trust with your visitors, ensuring secure transactions and online interactions.',
        },
        {
          icon: <NotificationsActive />,
          title: 'Domain Renewal Services',
          description:
            'Never worry about losing your domain again with our easy domain renewal reminders. We make it simple to keep your domain registration up to date, ensuring that your online presence remains uninterrupted.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why We Are the Best Domain Service Provider in India
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <LocationOn />,
          title: 'Local Expertise',
          description:
            'As a trusted domain service provider in India, we understand the unique needs of the Indian market and offer tailored solutions.',
        },
        {
          icon: <Speed />,
          title: 'Quick and Reliable Domain Registration',
          description:
            'We offer fast, reliable, and secure domain registration services, ensuring ease and confidence.',
        },
        {
          icon: <VerifiedUser />,
          title: 'Trusted by Thousands of Customers',
          description:
            'With years of experience, we have helped thousands of customers secure their online identities.',
        },
        {
          icon: <TrendingUp />,
          title: 'Scalable Solutions',
          description:
            'Whether you are an individual or a growing business, we offer scalable domain services that grow with you.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
      sx={{
        padding: { xs: '4rem 0', md: '3rem 0' },
        backgroundColor: '#f4f6f8', // Light gray background
      }}
    >
      <Container maxWidth="lg">
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#fff", // White text color
            background: "#1976d2", // Blue background
            borderRadius: "16px", // Rounded corners
            padding: "10px 20px", // Padding for better spacing
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
            textTransform: "uppercase", // Uppercase text
            letterSpacing: "2px", // Spacing between letters
            display: "flex", // Use flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            margin: "0 auto", // Center the box itself
            maxWidth: "fit-content", // Ensure the box only wraps the content
            marginBottom: '2rem',
          }}
        >
          Our Domain Services
        </Typography>

        {/* Features Grid */}
        <Grid container spacing={6}>
          {[
            {
              icon: <Link />,
              title: 'Domain Registration',
              description: 'Secure your perfect domain name with our easy-to-use registration service.',
            },
            {
              icon: <Sync />,
              title: 'Domain Transfers',
              description: 'Effortlessly transfer your domain to Deeva Payon with minimal downtime.',
            },
            {
              icon: <Shield />,
              title: 'Domain Privacy',
              description: 'Protect your personal information with our domain privacy services.',
            },
            {
              icon: <Dns />,
              title: 'DNS Management',
              description: 'Easily manage your domain’s DNS settings through our intuitive control panel.',
            },
            {
              icon: <NotificationsActive />,
              title: 'Domain Renewal',
              description: 'Keep your domain active with our straightforward renewal process.',
            },
            {
              icon: <Https />,
              title: 'SSL Certificates',
              description: 'Enhance website security with SSL certificates to protect data and build trust.',
            },
            {
              icon: <Email />,
              title: 'Email Hosting',
              description: 'Set up professional email addresses with your domain name for a polished online presence.',
            },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  backgroundColor: '#ffffff', // White card background
                  borderRadius: '12px', // Rounded corners
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
                  padding: '1.5rem', // Internal padding
                  transition: 'transform 0.3s ease', // Smooth hover effect
                  '&:hover': {
                    transform: 'translateY(-5px)', // Lift on hover
                  },
                  textAlign: 'center', // Center-align content
                }}
              >
                {/* Icon */}
                <Avatar
                  sx={{
                    backgroundColor: '#1976d2', // Blue background
                    width: '50px',
                    height: '50px',
                    margin: '0 auto 1rem', // Centered with spacing below
                  }}
                >
                  {feature.icon}
                </Avatar>

                {/* Title */}
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: '600', color: '#333' }}
                >
                  {feature.title}
                </Typography>

                {/* Description */}
                <Typography
                  variant="body2"
                  sx={{ color: '#555', lineHeight: '1.8' }}
                >
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Domain Service Provider in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Choosing the right domain is the first step toward building a strong online presence. At Deeva Payon, we are committed to providing the best domain services in India with fast, reliable, and secure solutions. Our team is here to assist you in finding the perfect domain name for your business, project, or personal website.
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Contact us today to get started with your domain registration, and experience the best customer support and domain management tools available in India!
    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default Domain;