// src/pages/CRMSystems.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Assignment,
  DesignServices,
  FactCheck,
  CloudUpload,
  Settings,
  ListAlt,
  TouchApp,
  Security,
  LocalHospital,
  HomeWork,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/CRM Software Company in india.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CRMSystems = () => {
  return (
    <>

<Helmet>
  <title>CRM Software Company in India - Custom CRM Solutions for Businesses</title>
  <meta
    name="description"
    content="Looking for a leading CRM Software Company in India? We provide innovative, tailored CRM software solutions to enhance customer relationships, improve sales, and boost business growth."
  />
  <meta
    name="keywords"
    content="CRM Software Company in India, Custom CRM Solutions, CRM Systems Development, Sales Automation, Customer Management Software, Business CRM Solutions"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                CRM Software Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deeva Payon, we are a leading CRM Software Company in India, offering innovative Customer Relationship Management solutions that help businesses strengthen customer relationships, streamline processes, and drive growth. As a trusted CRM provider, we deliver tailored solutions to meet the unique needs of diverse industries.
              </Typography>
              <Typography variant="body1" paragraph>
              With years of experience and a commitment to excellence, we empower organizations to manage customer interactions, boost sales efficiency, and achieve success through smart CRM strategies, enhancing overall business performance.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="CRM Software Company in India"
                title="CRM Software Solutions for Business Growth"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our CRM Software Development Process
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading graphic design company in India, we offer a wide range of creative services designed to enhance your brand's visual presence and create memorable experiences for your audience. Our offerings include:
    </Typography> */}

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
          {
            icon: <Assignment />,
            title: 'Requirement Analysis',
            description:
              'We begin by understanding your business processes, customer management needs, and specific challenges. Through detailed consultations, we ensure that we capture all your requirements to create a CRM solution that is truly aligned with your goals.',
          },
          {
            icon: <DesignServices />,
            title: 'Design & Development',
            description:
              'Our team designs and develops your CRM software using the latest technologies and best practices. We focus on creating a solution that is scalable, secure, and easy to integrate with other systems.',
          },
          {
            icon: <FactCheck />,
            title: 'Testing & Quality Assurance',
            description:
              'Before the CRM system goes live, we rigorously test it to ensure it meets all quality standards. Our QA team ensures that the software is bug-free, functions as expected, and is optimized for performance.',
          },
          {
            icon: <CloudUpload />,
            title: 'Deployment & Training',
            description:
              'Once your CRM solution is tested and ready, we deploy it within your organization. We also provide in-depth training for your team to ensure smooth adoption of the new CRM system.',
          },
          {
            icon: <SupportAgent />,
            title: 'Ongoing Support & Optimization',
            description:
              'After deployment, we offer continuous support, maintenance, and optimization services to ensure your CRM software remains up-to-date and aligned with your evolving business needs.',
          },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
     Why Choose Us as Your CRM Software Company in India?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
          {
            icon: <Settings />,
            title: 'Customized CRM Solutions for Every Business',
            description:
              'At Deeva Payon, we understand that every business is unique. As a leading CRM Software Company in India, we offer custom CRM software that is built to suit your specific business needs. From small startups to large enterprises, our solutions are designed to enhance customer engagement, improve sales, and drive business performance.',
          },
          {
            icon: <ListAlt />,
            title: 'Comprehensive CRM Features',
            description:
              'Our CRM software offers a wide range of features designed to provide a 360-degree view of customer interactions. Key features include: Contact management, Lead generation and tracking, Sales automation, Marketing automation, Reporting and analytics, Task and project management, Customer support tools',
          },
          {
            icon: <TouchApp />,
            title: 'User-Friendly Interface',
            description:
              'We prioritize ease of use in our CRM software. Our platforms are designed to be intuitive, with an easy-to-navigate interface that makes it simple for your team to manage customer data, track interactions, and use CRM tools effectively, without extensive training.',
          },
          {
            icon: <TrendingUp />,
            title: 'Scalable and Flexible Solutions',
            description:
              'As your business grows, your CRM requirements will evolve. Our CRM software is highly scalable, allowing you to expand features, integrate third-party tools, and adapt to new business challenges. This flexibility ensures that your CRM system can support your growth at every stage of your journey.',
          },
          {
            icon: <BarChart />,
            title: 'Enhanced Customer Insights',
            description:
              'With our CRM software, you gain valuable insights into customer behavior, preferences, and trends. By using advanced analytics and reporting tools, you can make data-driven decisions that help you better serve your customers, increase retention, and drive sales.',
          },
          {
            icon: <Groups />,
            title: 'Improved Sales & Marketing Collaboration',
            description:
              'Our CRM software helps break down silos between your sales and marketing teams by providing a unified platform where both teams can collaborate on lead generation, nurturing, and closing deals. This integration ensures that your marketing efforts are aligned with your sales goals for better results.',
          },
          {
            icon: <Security />,
            title: 'Secure and Reliable',
            description:
              'Security is a top priority for us. Our CRM software solutions come with robust security features to ensure that your customer data is always protected from unauthorized access, data breaches, or loss. We use encryption, secure cloud hosting, and regular data backups to maintain the highest level of security.',
          },
          {
            icon: <SupportAgent />,
            title: 'Expert Support & Maintenance',
            description:
              'As a leading CRM Software Company in India, we offer ongoing support and maintenance to ensure that your CRM system functions optimally. Our team of experts is always available to assist with troubleshooting, updates, and system enhancements, keeping your CRM platform running smoothly.',
          },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
     Industries We Serve
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <ShoppingCart />,
          title: 'E-Commerce',
          description:
            'Enhance customer engagement and sales processes to drive growth.',
        },
        {
          icon: <LocalHospital />,
          title: 'Healthcare',
          description:
            'Manage patient relationships, appointments, and medical records securely.',
        },
        {
          icon: <School />,
          title: 'Education',
          description:
            'Streamline student enrollment, communication, and alumni management.',
        },
        {
          icon: <HomeWork />,
          title: 'Real Estate',
          description:
            'Manage leads, client relationships, and property listings efficiently.',
        },
        {
          icon: <AccountBalance />,
          title: 'Financial Services',
          description:
            'Track client interactions, manage portfolios, and enhance customer service.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with Your CRM Solution Today!
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    As a leading CRM Software Company in India, Deeva Payon is committed to helping businesses of all sizes leverage the power of CRM technology. Our customized solutions are designed to increase sales, improve customer satisfaction, and enhance operational efficiency.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default CRMSystems;