import React from "react";
import { Container, Grid, Typography, Box, Fade, Button } from "@mui/material";
import AboutUsImg from "../assest/about-us.jpg";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <Box
      sx={{
        width: "100%",
        background: "#fff",
        py: { xs: 8, md: 10 }, // Increased vertical padding
        px: { xs: 3, md: 0 }, // Horizontal padding
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          {/* Image Section (Now on Left) */}
          <Fade in timeout={1500}>
            <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                component="img"
                src={AboutUsImg}
                alt="About Deeva Payon"
                sx={{
                  width: "100%",
                  maxWidth: "550px",
                  borderRadius: "20px",
                  boxShadow: "0 12px 24px rgba(0,0,0,0.2)",
                  transition: "transform 0.3s ease",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              />
            </Grid>
          </Fade>

          {/* Text Section (Now on Right) */}
          <Fade in timeout={1000}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#f72f20",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    letterSpacing: "1px",
                    mb: 1.5,
                  }}
                >
                  Who We Are
                </Typography>

                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#222",
                    lineHeight: 1.3,
                    mb: 2,
                    fontSize: { xs: "1.8rem", md: "2rem" }, // Adjusted font size for responsiveness
                  }}
                >
                  Transforming Ideas into Impactful Software Solutions with Deevapayon
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#555",
                    fontSize: { xs: "1rem", md: "1.1rem" }, // Professional font size
                    lineHeight: 1.8,
                    maxWidth: "100%",
                    mb: 2,
                  }}
                >
                  Deeva Payon is a leading software development company that blends vision,
                  strategy, and expertise to create exceptional digital solutions. Our mission
                  is to empower businesses with custom software, digital innovation, and
                  technology-driven strategies.
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    color: "#555",
                    fontSize: { xs: "1rem", md: "1.1rem" }, // Professional font size
                    lineHeight: 1.8,
                    maxWidth: "100%",
                    mb: 3,
                  }}
                >
                  We work closely with our clients to drive business growth, enhance brand
                  presence, and deliver seamless user experiences. With a strong focus on
                  transparency, excellence, and collaboration, we turn ideas into reality.
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component={Link}
                  to="/about"
                  sx={{
                    backgroundColor: "#1f15e0",
                    px: 4,
                    py: 1.2,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    textTransform: "none",
                    transition: "0.3s",
                    borderRadius: "8px",
                    "&:hover": { backgroundColor: "#1510b4" },
                  }}
                >
                  Learn More About Us
                </Button>
              </Box>
            </Grid>
          </Fade>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUs;
