// src/pages/HostingServer.js
import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CheckCircleOutline,
  Code,
  PhoneIphone,
  Web,
  Cloud,
  People,
  ShoppingCart,
  Api,
  DirectionsBus,
  Bolt,
  WaterDrop,
  LocalFireDepartment,
  Toll,
  CreditCard,
  Fingerprint,
  AccountBalance,
  Paid,
  Dns,
  Computer,
  SettingsApplications,
  Https,
  Email,
  WebAsset,
  Storage,
  Public,
  BarChart,
  Settings,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Best hosting services in India.png';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { FaWordpress } from 'react-icons/fa';

const HostingServer = () => {
  return (
    <>
      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Best Hosting Server Provider in India
              </Typography>
              <Typography variant="body1" paragraph>
              Looking for the best hosting service provider in India? Deeva Payon offers high-performance, reliable, and secure hosting solutions tailored to your needs. Whether you're launching a new website or scaling an existing business, we ensure maximum uptime, fast loading speeds, and exceptional customer support.
              </Typography>
              <Typography variant="body1" paragraph>
              As one of the leading providers, we cater to entrepreneurs, small businesses, and large enterprises, offering hosting solutions that guarantee a seamless online experience, no matter the size of your business.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Best hosting services in India with secure and fast solutions"
                title="Reliable Hosting Services in India - Deeva Payon"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '3' }}
    >
      As the <b>best hosting service provider in India,</b> we offer a variety of hosting solutions to meet the diverse needs of businesses and individuals:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
          {
            icon: <Storage />,
            title: 'Shared Hosting',
            description:
              'Ideal for small websites and blogs, our shared hosting services offer affordable plans with the power and reliability of high-performance servers. Share resources with other websites and keep your costs low while enjoying a stable service.',
          },
          {
            icon: <Dns />,
            title: 'VPS Hosting',
            description:
              'Our VPS hosting services give you dedicated resources, ensuring better performance and more control over your website. This service is perfect for growing businesses that need more power and customization without the cost of dedicated hosting.',
          },
          {
            icon: <Computer />,
            title: 'Dedicated Hosting',
            description:
              'For large businesses or websites with high traffic, our dedicated hosting solutions provide complete control over your server. Enjoy the benefits of isolated resources, enhanced security, and top performance with your own dedicated server.',
          },
          {
            icon: <Cloud />,
            title: 'Cloud Hosting',
            description:
              'Scalable, flexible, and reliable, our cloud hosting services offer the best solution for growing businesses. Pay only for what you use and scale your resources as your business grows.',
          },
          {
            icon: <FaWordpress />,
            title: 'WordPress Hosting',
            description:
              'For WordPress users, we provide optimized WordPress hosting services that ensure fast loading speeds, enhanced security, and seamless performance. We handle all the technical details so you can focus on creating great content.',
          },
          {
            icon: <SettingsApplications />,
            title: 'Reseller Hosting',
            description:
              'If you are looking to start your own hosting business, our reseller hosting services allow you to resell web hosting plans under your own brand. We provide white-label hosting with robust management tools to help you succeed.',
          },
          {
            icon: <Link />,
            title: 'Domain Registration',
            description:
              'Get your domain name hassle-free with our domain registration services. We offer a wide range of domain extensions, including .com, .in, .net, and many others. Secure your brand’s online identity with ease.',
          },
          {
            icon: <Https />,
            title: 'SSL Certificates',
            description:
              'Ensure your website is secure with our SSL certificates. Protect your visitors\' data and build trust with encryption technology that secures online transactions and sensitive information.',
          },
          {
            icon: <Email />,
            title: 'Email Hosting',
            description:
              'Stay professional with our email hosting services. Manage your email accounts with your own domain name for a polished and credible online presence.',
          },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us as Your Best Hosting Service Provider in India?
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <CheckCircleOutline />,
          title: '1. Reliable Hosting Infrastructure',
          description: 'We understand that downtime means lost opportunities. That’s why we provide robust infrastructure with 99.9% uptime guarantee. Our servers are powered by the latest technology to ensure your website remains online and accessible at all times.',
        },
        {
          icon: <CheckCircleOutline />,
          title: '2. High-Speed Performance',
          description: 'Speed matters! With our optimized servers and state-of-the-art technology, we deliver blazing-fast website performance. Whether it’s loading speeds, content delivery, or user experience, our hosting ensures your visitors enjoy seamless browsing.',
        },
        {
          icon: <CheckCircleOutline />,
          title: '3. Affordable Pricing',
          description: 'We offer the best hosting services in India at competitive prices. Get top-tier features without burning a hole in your pocket. Our hosting plans are flexible and can fit any budget, from startups to enterprises.',
        },
        {
          icon: <CheckCircleOutline />,
          title: '4. Top-Notch Security',
          description: 'Security is a priority. Our hosting services are equipped with advanced security measures, including SSL certificates, firewalls, and malware scanning, to protect your data and keep your website secure from threats.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
      sx={{
        padding: { xs: '4rem 0', md: '3rem 0' },
        backgroundColor: '#f4f6f8', // Light gray background
      }}
    >
      <Container maxWidth="lg">
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#fff", // White text color
            background: "#1976d2", // Blue background
            borderRadius: "16px", // Rounded corners
            padding: "10px 20px", // Padding for better spacing
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
            textTransform: "uppercase", // Uppercase text
            letterSpacing: "2px", // Spacing between letters
            display: "flex", // Use flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            margin: "0 auto", // Center the box itself
            maxWidth: "fit-content", // Ensure the box only wraps the content
            marginBottom: '2rem',
          }}
        >
          Why We Are the Best Hosting Service Provider in India
        </Typography>

        {/* Features Grid */}
        <Grid container spacing={6}>
          {[
            {
              icon: <Public />,
              title: 'Local Expertise',
              description:
                'We understand the Indian market and cater to its unique needs with high-quality hosting solutions.',
            },
            {
              icon: <BarChart />,
              title: 'Scalable Solutions',
              description:
                'Our hosting plans are designed to grow with your business, allowing you to easily upgrade as needed.',
            },
            {
              icon: <Storage />,
              title: 'Global Reach',
              description:
                'With data centers in key global locations, we offer fast and reliable services to customers all over the world.',
            },
            {
              icon: <Settings />,
              title: 'Customization',
              description:
                'We provide flexible hosting plans that allow you to customize your services based on your requirements.',
            },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  backgroundColor: '#ffffff', // White card background
                  borderRadius: '12px', // Rounded corners
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
                  padding: '1.5rem', // Internal padding
                  transition: 'transform 0.3s ease', // Smooth hover effect
                  '&:hover': {
                    transform: 'translateY(-5px)', // Lift on hover
                  },
                  textAlign: 'center', // Center-align content
                }}
              >
                {/* Icon */}
                <Avatar
                  sx={{
                    backgroundColor: '#1976d2', // Blue background
                    width: '50px',
                    height: '50px',
                    margin: '0 auto 1rem', // Centered with spacing below
                  }}
                >
                  {feature.icon}
                </Avatar>

                {/* Title */}
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: '600', color: '#333' }}
                >
                  {feature.title}
                </Typography>

                {/* Description */}
                <Typography
                  variant="body2"
                  sx={{ color: '#555', lineHeight: '1.8' }}
                >
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started Today with the Best Hosting Service Provider in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    When it comes to reliable, fast, and secure hosting services in India, Deeva PayOn is your trusted partner. Whether you're starting a personal blog or launching a major e-commerce site, we offer the best hosting solutions to help you succeed.
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Contact us today to discover the best hosting services in India and find the ideal solution for your business. Let’s work together to make your website faster, more secure, and highly reliable!
    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default HostingServer;