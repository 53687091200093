// src/pages/MobileAppDevelopment.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  BuildCircle,
  BugReport,
  Devices,
  DesignServices,
  Apple,
  Android,
  DoneAll,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Mobile App Development Company, Deevapayon.jpg';
import CallToAction from './CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const MobileAppDevelopment = () => {
  return (
    <>

<Helmet>
  <title>Top Mobile App Development Company in India | Deeva Payon</title>
  <meta
    name="description"
    content="Deeva Payon is a leading mobile app development company in India. We create stunning, high-performing apps for Android & iOS. Get a free consultation today!"
  />
  <meta
    name="keywords"
    content="Best mobile app development company in India, Custom mobile apps, Android app development, iOS app development, Cross platform apps, Mobile App design, App maintenance services"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Mobile App Development Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              In today’s digital world, collaborating with a top mobile app development company in India is crucial for businesses aiming to boost customer engagement and optimize operations. Our tailored mobile app development services cater to various business requirements, ensuring that your app is not only functional but also user-friendly and visually attractive.
              </Typography>
              <Typography variant="body1" paragraph>
              With our expertise, we focus on delivering high-quality applications that enhance your brand’s presence and drive long-term success.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Deevapayon, Mobile App Development Company"
                title="Illustration of mobile app development process, showcasing design, coding, and testing stages."
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Mobile App Development Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      At Deevapayon, our skilled team of mobile app developers brings years of expertise to the table. They are dedicated to turning your business concepts into reality by building mobile applications focused on delivering exceptional user experiences.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <PhoneIphone />,
          title: 'Custom Mobile Apps',
          description:
            'We specialize in designing and building mobile applications to meet the specific needs of your business or individual requirements. This involves creating a unique app from scratch, allowing for greater flexibility, customization, and unique functionality.',
        },
        {
          icon: <Android />,
          title: 'Android App Development',
          description:
            'We build native Android applications optimized for performance, security, and the latest Android features, ensuring a seamless user experience across various devices.',
        },
        {
          icon: <Apple />,
          title: 'iOS App Development',
          description:
            'We develop native iOS applications that are sleek, user-friendly, and fully compliant with Apples App Store guidelines, providing exceptional functionality and design for users.',
        },
        {
          icon: <Search />,
          title: 'Product Discovery & Research',
          description:
            'We conduct in-depth market analysis and user research to identify the unique needs of your target audience, shaping your apps concept effectively.',
        },
        {
          icon: <DesignServices />,
          title: 'Mobile App Design',
          description:
            'Our design team specializes in creating intuitive user experiences (UX) and stunning user interfaces (UI) that enhance engagement and usability.',
        },
        {
          icon: <Devices />,
          title: 'Native Mobile App Development',
          description:
            'We develop robust applications specifically for Android and iOS platforms, ensuring optimal performance and adherence to platform guidelines.',
        },
        {
          icon: <Web />,
          title: 'Cross-Platform Mobile App Development',
          description:
            'Utilizing frameworks like React Native and Flutter, we create apps that work seamlessly across multiple platforms, reducing development time and costs.',
        },
        {
          icon: <Web />,
          title: 'Progressive Web Apps (PWAs)',
          description:
            'Our team builds PWAs that combine the best features of web and mobile apps, providing a fast, reliable, and engaging user experience.',
        },
        {
          icon: <BugReport />,
          title: 'Mobile Testing & Quality Assurance',
          description:
            'Rigorous testing processes are implemented to ensure your app is bug-free and performs well under various conditions.',
        },
        {
          icon: <BuildCircle />,
          title: 'App Maintenance Services',
          description:
            'We provide ongoing maintenance and support to ensure your app remains updated, secure, and performs optimally, addressing bug fixes, security patches, and feature enhancements.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Deevapayon?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted Mobile App Development company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
        {
          icon: <People />,
          title: 'Expertise & Experience',
          description:
            'As one of the leading mobile app development companies in India, we bring years of experience and expertise to every project.',
        },
        {
          icon: <AutoAwesome />,
          title: 'Next-Generation Technologies',
          description:
            'Our team leverages cutting-edge technologies and industry best practices to build innovative and efficient mobile applications.',
        },
        {
          icon: <DoneAll />,
          title: 'High-Quality Applications',
          description:
            'We focus on delivering top-tier applications that drive business growth, ensuring exceptional performance and user experience.',
        },
        {
          icon: <DoneAll />,
          title: 'Agile Approach',
          description:
            'Our agile methodology allows flexibility and rapid iterations based on client feedback, ensuring the final product perfectly matches your vision.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
  <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Mobile App Development Company in India
</Typography>
<Typography variant="body1" align="center" paragraph>
    Partner with Deevapayon, a premier mobile app development company in India, and transform your ideas into seamless, high-performing mobile experiences. Whether you need a custom iOS/Android app, cross-platform solutions, or app optimization, we specialize in crafting innovative, user-centric applications that align with your business goals. Our expertise in cutting-edge technologies, combined with a focus on UI/UX excellence and agile development, ensures your app stands out in a crowded marketplace. From concept to launch and beyond, we’re committed to delivering scalable, secure, and engaging apps that drive user retention and business growth. Let’s collaborate to build a mobile presence that captivates your audience and accelerates your digital success.
</Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default MobileAppDevelopment;