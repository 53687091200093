// src/pages/ERPSolutions.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Restaurant,
  Hotel,
  LocalHospital,
  Settings,
  ListAlt,
  TouchApp,
  Security,
  CloudUpload,
  FactCheck,
  DesignServices,
  Assignment,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/ERP Software Company in India.jpeg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ERPSolutions = () => {
  return (
    <>

<Helmet>
  <title>Leading ERP Software Company in India - Optimize Your Business with Custom ERP Solutions</title>
  <meta
    name="description"
    content="Partner with a top ERP Software Company in India for ERP solutions. We offer systems for healthcare, education, hospitality, and more, improving business efficiency and growth."
  />
  <meta
    name="keywords"
    content="ERP Software Company in India, ERP Solutions for Healthcare, Custom ERP Development, School ERP Systems, Hospitality ERP Solutions, Scalable ERP, Cloud ERP Systems, Inventory Management Software, Business Automation Solutions"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                ERP Software Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              Deeva Payon is a leading ERP software company in India, specializing in the development and implementation of tailored Enterprise Resource Planning solutions. With years of expertise, we provide scalable, customizable ERP systems that streamline operations, enhance efficiency, and support business growth.
              </Typography>
              <Typography variant="body1" paragraph>
              Our solutions integrate key functions like finance, HR, supply chain, and CRM, allowing businesses to manage resources effectively. We offer flexible ERP solutions adaptable to diverse industries, including healthcare, education, hospitality, and retail, optimizing processes and boosting productivity.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="ERP Solutions for Different Industries"
                title="Scalable ERP Software Solutions for Your Business"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our ERP Software Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer specialized ERP software solutions designed to meet the diverse needs of businesses across industries. Our services include:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <LocalHospital />,
          title: 'Hospital Management Software',
          description:
            'We provide a comprehensive Hospital Management ERP solution to help healthcare providers streamline patient management, billing, inventory, scheduling, and other administrative tasks. Our hospital management software ensures that your healthcare facility operates smoothly, with improved patient care and enhanced operational efficiency.',
        },
        {
          icon: <School />,
          title: 'School Management Software',
          description:
            'Our School Management ERP software automates administrative processes such as student enrollment, class scheduling, attendance tracking, exam management, and report generation. It enables schools to manage operations more efficiently, allowing them to focus on student success and growth.',
        },
        {
          icon: <Hotel />,
          title: 'Hotel Management Software',
          description:
            'Our Hotel Management ERP system is designed to help hotels manage reservations, front-desk operations, guest services, housekeeping, and billing seamlessly. This software improves guest experiences, optimizes hotel operations, and maximizes profitability by providing real-time insights and automating day-to-day tasks.',
        },
        {
          icon: <Restaurant />,
          title: 'Restaurant Management Software',
          description:
            'The Restaurant Management ERP software we offer streamlines operations in the food service industry. From order management, inventory tracking, and billing to customer relationship management and employee scheduling, our system is designed to optimize restaurant operations, improve customer service, and increase profitability.',
        },
      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us as Your ERP Software Company in India?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[

            {
              icon: <Settings />,
              title: 'Custom ERP Solutions for Every Business',
              description:
                'As an ERP Software Company in India, we offer custom-built ERP solutions that are designed to address the specific requirements of your business. Whether you\'re a startup or an established enterprise, our team works closely with you to develop an ERP system that meets your needs. From inventory management and procurement to finance and human resources, we provide end-to-end solutions that improve efficiency and streamline your operations.',
            },
            {
              icon: <ListAlt />,
              title: 'Comprehensive ERP Features',
              description:
                'Our ERP software comes equipped with an array of features that cover every aspect of business management: Financial Management, Inventory and Supply Chain Management, Procurement and Order Management, Human Resource Management (HRM), Customer Relationship Management (CRM), Sales and Distribution Management, Business Analytics and Reporting, Project Management, Manufacturing and Production Management',
            },
            {
              icon: <TrendingUp />,
              title: 'Scalable and Flexible Solutions',
              description:
                'Our ERP systems are built to grow with your business. As your company evolves and expands, our ERP software offers the scalability and flexibility you need to manage new processes, departments, and product lines. You can add new modules, features, or integrate third-party applications seamlessly to support your business growth.',
            },
            {
              icon: <Cloud />,
              title: 'Cloud-Based ERP Solutions',
              description:
                'We offer cloud-based ERP solutions that allow you to access your data securely from anywhere, anytime. With our cloud-based ERP system, you can eliminate the need for expensive IT infrastructure and maintenance while enjoying the benefits of real-time updates, enhanced collaboration, and increased operational efficiency.',
            },
            {
              icon: <TouchApp />,
              title: 'User-Friendly Interface',
              description:
                'Our ERP software is designed with the end-user in mind. The user interface is intuitive and easy to navigate, ensuring that your team can adopt the system quickly without extensive training. With a streamlined design, your employees can focus on what matters most—driving business growth.',
            },
            {
              icon: <BarChart />,
              title: 'Enhanced Business Analytics',
              description:
                'Gain actionable insights into your business performance with our powerful analytics and reporting tools. Our ERP software helps you track key metrics, identify trends, and make data-driven decisions that improve operational efficiency, reduce costs, and boost profitability.',
            },
            {
              icon: <Link />,
              title: 'Seamless Integration with Other Systems',
              description:
                'Our ERP system seamlessly integrates with other business applications, such as CRM systems, accounting software, and third-party tools. This ensures that all aspects of your business are interconnected, providing you with a single source of truth for your data.',
            },
            {
              icon: <Security />,
              title: 'Security and Data Protection',
              description:
                'Security is a top priority for us. Our ERP software is designed with robust security features to ensure the protection of your sensitive business data. With advanced encryption, secure data backups, and regular system updates, we ensure that your information is always safe and compliant with industry standards.',
            },
            {
              icon: <SupportAgent />,
              title: 'Ongoing Support and Maintenance',
              description:
                'As your trusted ERP software provider, we offer continuous support and maintenance services to ensure that your system remains optimized and up-to-date. Our team is available to assist with troubleshooting, upgrades, and system improvements to ensure the smooth functioning of your ERP system.',
            },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our ERP Software Development Process
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Assignment />,
      title: 'Requirement Analysis',
      description:
        'We begin by understanding your business goals, processes, and challenges. Our team conducts in-depth consultations with your key stakeholders to gather all necessary requirements, ensuring that the ERP system we develop is tailored to your specific needs.',
    },
    {
      icon: <DesignServices />,
      title: 'Design & Development',
      description:
        'Once the requirements are clear, we design and develop your ERP solution using the latest technologies and best practices. We focus on building a system that is user-friendly, secure, and scalable to grow with your business.',
    },
    {
      icon: <FactCheck />,
      title: 'Testing & Quality Assurance',
      description:
        'Before deployment, we rigorously test the ERP system to ensure it meets the highest quality standards. Our QA team performs functional, performance, and security testing to ensure that the software works seamlessly across all platforms.',
    },
    {
      icon: <CloudUpload />,
      title: 'Deployment & Training',
      description:
        'After the software passes testing, we deploy it across your organization. We also provide comprehensive training for your team to ensure smooth implementation and quick adoption of the new system.',
    },
    {
      icon: <SupportAgent />,
      title: 'Ongoing Support & Maintenance',
      description:
        'We provide ongoing support and maintenance to ensure that your ERP system remains up-to-date, secure, and fully functional. Our team is always available to assist with any questions, updates, or optimizations.',
    },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Industries We Serve
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <LocalHospital />,
      title: 'Healthcare',
      description:
        'Manage patient records, appointments, billing, and inventory efficiently with our hospital management software.',
    },
    {
      icon: <School />,
      title: 'Education',
      description:
        'Streamline student enrollment, faculty management, and administrative processes with our school management software.',
    },
    {
      icon: <Hotel />,
      title: 'Hospitality',
      description:
        'Manage hotel bookings, reservations, and guest services seamlessly with our hotel management software.',
    },
    {
      icon: <Restaurant />,
      title: 'Food & Beverage',
      description:
        'Optimize restaurant operations, inventory, and customer relationships with our restaurant management software.',
    },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>


<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Benefits of Choosing Deevapayon as Your ERP Software Company in India
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Settings />,
      title: 'Increased Efficiency',
      description:
        'Automate business processes, reduce manual work, and improve workflow efficiency across departments.',
    },
    {
      icon: <BarChart />,
      title: 'Better Decision-Making',
      description:
        'Use real-time data and analytics to make informed decisions that drive business success.',
    },
    {
      icon: <Groups />,
      title: 'Improved Collaboration',
      description:
        'Streamline communication and collaboration across departments with integrated tools and processes.',
    },
    {
      icon: <Savings />,
      title: 'Cost Savings',
      description:
        'Reduce operational costs by optimizing resources, automating tasks, and improving resource allocation.',
    },
    {
      icon: <TrendingUp />,
      title: 'Scalable Growth',
      description:
        'Our ERP solutions scale with your business, supporting growth and evolving needs over time.',
    },


      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>



      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best ERP Solutions Agency in India
    </Typography>
    {/* <Typography variant="body1" align="center" paragraph>
    Collaborate with Deevapayon, a leading graphic design company in India, and bring your brand’s narrative to life with outstanding, creative designs. Whether you're looking for a fresh logo, a complete rebrand, or new digital content, we’re dedicated to turning your vision into reality. Our focus on innovation, excellence, and customer satisfaction guarantees that your brand will stand out in the competitive market. Let’s work together to transform your ideas into powerful visuals that leave a lasting impression on your audience.

    </Typography> */}
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default ERPSolutions;