import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { People, Lightbulb, SupportAgent, Speed, Shield, BarChart } from "@mui/icons-material";
import { motion } from "framer-motion";

const features = [
  {
    icon: <Speed fontSize="large" sx={{ color: "#2075BC" }} />,
    title: "High Performance",
    description:
      "Our services are meticulously designed for maximum speed and efficiency, ensuring a seamless user experience. We leverage advanced technologies and best practices to deliver robust solutions that meet your business needs. From development to deployment, expect superior performance, reliability, and smooth operation across all platforms and devices.",
  },
  {
    icon: <People fontSize="large" sx={{ color: "#21767A" }} />,
    title: "Team of Experts",
    description:
      "Our software development team is composed of seasoned experts dedicated to crafting innovative and reliable solutions. We leverage the latest technologies and industry best practices to deliver custom software designed to meet your unique needs. From concept to deployment, we ensure excellence in every phase of development, driving your business forward.",
  },
  {
    icon: <Lightbulb fontSize="large" sx={{ color: "#2075BC" }} />,
    title: "Creative Approach",
    description:
      "We take a creative approach to software development, blending innovative thinking with technical expertise. Our team excels at devising unique and efficient solutions to complex problems, ensuring your software is not only functional but also elegant. We embrace agile methodologies and design thinking to deliver user-centric software that exceeds expectations.",
  },
  {
    icon: <BarChart fontSize="large" sx={{ color: "#21767A" }} />,
    title: "Scalable Solutions",
    description:
      "Our services are built to grow alongside your business, effortlessly adapting to increasing demands and evolving requirements. Whether you're expanding operations, handling higher traffic, or integrating new features, our scalable solutions ensure optimal performance, reliability, and efficiency, empowering your business to thrive without limitations or disruptions.",
  },
  {
    icon: <Shield fontSize="large" sx={{ color: "#2075BC" }} />,
    title: "Data Protection",
    description:
      "We prioritize the safety of your business data with industry-leading security protocols and advanced encryption standards. Our comprehensive approach includes regular audits, threat monitoring, and secure development practices, ensuring your sensitive information remains protected from unauthorized access, breaches, and cyber threats at every stage of your digital journey.",
  },
  {
    icon: <SupportAgent fontSize="large" sx={{ color: "#21767A" }} />,
    title: "Customer Care Support",
    description:
      "We pride ourselves on exceptional customer care support available 24/7 throughout your software development journey. Our dedicated support team provides timely assistance, clear communication, and proactive solutions to ensure a seamless experience. From initial consultation to post-launch support, we're committed to your satisfaction and long-term success.",
  },
];

const WhyHireUs = () => {
  return (
    <Box
      sx={{
        padding: { xs: "40px 5%", md: "60px 10%" },
        backgroundColor: "linear-gradient(135deg, #f0f8ff, #e6f7ff)", // Light blue background
        minHeight: "100vh",
      }}
    >
      {/* Section Title */}
      <Typography
        variant="h3"
        textAlign="center"
        fontWeight="bold"
        sx={{
          marginBottom: "30px",
          color: "#2075BC",
          background: "linear-gradient(135deg, #000, #000)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          // textTransform: "uppercase",
          letterSpacing: 2,
          borderRadius: "16px",
                padding: "10px 20px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        Why Should You Hire Us for Your Company’s Success?
      </Typography>
      {/* Feature Cards */}
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <Card
                sx={{
                  height: "100%",
                  backgroundColor: "#ffffff",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  padding: "20px",
                  textAlign: "center",
                  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 8px 20px rgba(32, 117, 188, 0.3)",
                  },
                }}
              >
                {/* Icon */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  {feature.icon}
                </Box>
                {/* Title */}
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{
                    color: "#2075BC",
                    background: "linear-gradient(45deg, #2075BC, #21767A)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    mb: 1,
                  }}
                >
                  {feature.title}
                </Typography>
                {/* Description */}
                <CardContent>
                  <Typography
                    sx={{
                      color: "#555",
                      fontSize: { xs: "14px", sm: "16px" },
                      lineHeight: 1.6,
                    }}
                  >
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WhyHireUs;