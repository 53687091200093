import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import valuesicon from "../../../assest/values-icon.png";
import missionicon from "../../../assest/misson-icon.png";
import visionicon from "../../../assest/vision-icon.png";

const OurMission = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #374cd5, #000000)",
        py: 8,
        px: 3,
        minHeight: "85vh",
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Mission Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              background: "#ffffff",
              borderRadius: "15px",
              textAlign: "center",
              p: 3,
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-8px)",
                boxShadow: "0px 10px 30px rgba(0,0,0,0.2)",
              },
            }}
          >
            <Box component="img" src={missionicon} alt="Mission Icon" sx={{ width: 90, height: 90, mb: 2 }} />
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#374cd5",
                  pb: 1,
                  borderBottom: "3px solid #e0e0e0",
                }}
              >
                Our Mission
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, fontSize: "1rem", lineHeight: 1.6 }}>
              At Deeva Payon, our mission is to empower businesses and individuals
          through innovative and reliable software solutions. We are committed
          to crafting high-quality, user-centric applications that solve complex
          problems and drive tangible results. By leveraging cutting-edge
          technologies and agile development practices, we strive to exceed
          client expectations and deliver exceptional value. We foster a
          collaborative and growth-oriented environment where our talented team
          can thrive, continuously learn, and create impactful solutions.
          Ultimately, we aim to be a trusted technology partner, enabling our
          clients to achieve their strategic goals and stay ahead in today's
          dynamic digital landscape.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Vision Card - Different Design */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              background: "#f5f5f5",
              borderRadius: "15px",
              textAlign: "center",
              p: 3,
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-8px)",
                boxShadow: "0px 10px 30px rgba(0,0,0,0.2)",
              },
            }}
          >
            <Box component="img" src={valuesicon} alt="Vision Icon" sx={{ width: 90, height: 90, mb: 2 }} />
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#ff9800",
                  pb: 1,
                  borderBottom: "3px solid #e0e0e0",
                }}
              >
                Our Vision
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, fontSize: "1rem", lineHeight: 1.6 }}>
              To be a global leader in software innovation, recognized for
          transforming ideas into impactful realities. We envision a future
          where technology seamlessly integrates into every aspect of life,
          empowering individuals and organizations to achieve more. We aspire to
          be at the forefront of this transformation, driving progress through
          cutting-edge solutions and a commitment to excellence. We see
          ourselves as a catalyst for growth, enabling our clients to thrive in
          an ever-evolving digital world. By fostering a culture of creativity,
          collaboration, and continuous learning, we aim to attract and retain
          the best talent, building a legacy of innovation and positive impact
          for generations to come.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Goals Card - Another Unique Design */}
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              background: "#e3f2fd",
              borderRadius: "15px",
              textAlign: "center",
              p: 3,
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease-in-out",
              "&:hover": {
                transform: "translateY(-8px)",
                boxShadow: "0px 10px 30px rgba(0,0,0,0.2)",
              },
            }}
          >
            <Box component="img" src={visionicon} alt="Goals Icon" sx={{ width: 90, height: 90, mb: 2 }} />
            <CardContent>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#00796b",
                  pb: 1,
                  borderBottom: "3px solid #e0e0e0",
                }}
              >
                Our Goals
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, fontSize: "1rem", lineHeight: 1.6 }}>
              At Deeva Payon, our goals are centered around delivering excellence
          and driving innovation in software development. We aim to create
          robust, scalable, and user-friendly applications that meet the unique
          needs of our clients. Our commitment to continuous improvement ensures
          we stay ahead of industry trends and technologies. We strive to
          enhance customer satisfaction by fostering transparent communication
          and collaboration throughout the development process. By establishing long-term partnerships with our
          clients, we seek to empower them with the tools they need to succeed
          in the digital landscape, ultimately contributing to their business
          growth and success.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OurMission;
