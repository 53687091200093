import React from "react";
import { Grid, Typography, Paper, Box, Container } from "@mui/material";
import {
  DirectionsCar as AutomobileIcon,
  Movie as EntertainmentIcon,
  ShoppingCart as EcommerceIcon,
  LocalHospital as HealthIcon,
  AccountBalance as FinanceIcon,
  Home as RealEstateIcon,
  Flight as TourismIcon,
  LocalShipping as DeliveryIcon,
  CurrencyBitcoin as CryptoIcon,
  School as SchoolIcon,
  Computer as SoftwareIcon,
  Business as ProfessionalIcon,
  PrecisionManufacturing as ManufacturingIcon,
  SportsEsports as GamesIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";

// Styled Paper for industry cards
const IndustryCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px 16px",
  borderRadius: "12px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
  },
}));

// Industry Data with MUI Icons
const industries = [
  { name: "Automobile", icon: <AutomobileIcon sx={{ color: "#2075BC" }} /> },
  { name: "Entertainment", icon: <EntertainmentIcon sx={{ color: "#21767A" }} /> },
  { name: "E-commerce", icon: <EcommerceIcon sx={{ color: "#2075BC" }} /> },
  { name: "Health", icon: <HealthIcon sx={{ color: "#21767A" }} /> },
  { name: "Finance", icon: <FinanceIcon sx={{ color: "#2075BC" }} /> },
  { name: "Real Estate", icon: <RealEstateIcon sx={{ color: "#21767A" }} /> },
  { name: "Tourism", icon: <TourismIcon sx={{ color: "#2075BC" }} /> },
  { name: "Delivery", icon: <DeliveryIcon sx={{ color: "#21767A" }} /> },
  { name: "Crypto", icon: <CryptoIcon sx={{ color: "#2075BC" }} /> },
  { name: "School", icon: <SchoolIcon sx={{ color: "#21767A" }} /> },
  { name: "Software", icon: <SoftwareIcon sx={{ color: "#2075BC" }} /> },
  { name: "Professional", icon: <ProfessionalIcon sx={{ color: "#21767A" }} /> },
  { name: "Manufacturing", icon: <ManufacturingIcon sx={{ color: "#2075BC" }} /> },
  { name: "Games", icon: <GamesIcon sx={{ color: "#21767A" }} /> },
  { name: "Event", icon: <EventIcon sx={{ color: "#2075BC" }} /> },
];

const IndustriesWeServe = () => {
  return (
    <Box sx={{ py: 6, backgroundColor: "#CEF7FC" }}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          fontWeight="bold"
          color="#2075BC"
          textAlign="center"
          gutterBottom
        >
          Industries We Serve
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          textAlign="center"
          sx={{ mb: 4 }}
        >
          As a software development company, we have been fortunate in serving
          clients from different industries. Here is a list of industries for
          whom we have provided software development services.
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {industries.map((industry, index) => (
            <Grid item key={index} xs={6} sm={4} md={3} lg={2}>
              <IndustryCard>
                {industry.icon}
                <Typography variant="subtitle1" fontWeight="600" sx={{ ml: 1 }}>
                  {industry.name}
                </Typography>
              </IndustryCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default IndustriesWeServe;
