// src/pages/GraphicDesign.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/graphic-design.png';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const GraphicDesign = () => {
  return (
    <>

<Helmet>
        <title>Top Graphic Design Company in India | Innovative Design Solutions</title>
        <meta
          name="description"
          content="Expert team at a reliable graphic design company in India offers logo design, brand identity, website design, print materials, and more to elevate your brand."
        />
        <meta
          name="keywords"
          content=" graphic design company in India, logo design company, brand identity, website design company, print design services, social media graphics, packaging design, custom artwork, business card design, banner design, presentation design"
        />
      </Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Graphic Design Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we are passionate about creating visually striking designs that not only captivate but also effectively communicate your brand's message. As a renowned graphic design company in India, we specialize in providing innovative, high-quality design solutions that elevate your brand's identity.
              </Typography>
              <Typography variant="body1" paragraph>
              With a team of experienced designers, we ensure that every project is a perfect blend of creativity, strategy, and functionality, tailored to meet your specific needs and objectives.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Graphic design services by a leading graphic design company in India"
                title="Creative Graphic Design Solutions by Deevapayon - Expert Designers in India"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Graphic Design Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading graphic design company in India, we offer a wide range of creative services designed to enhance your brand's visual presence and create memorable experiences for your audience. Our offerings include:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Brush />,
          title: 'Logo Design',
          description:
            'Your logo is the face of your brand. Our logo design service ensures that your brand has a memorable, professional, and distinctive logo that truly represents your business values and resonates with your audience. We focus on creating timeless logos that can stand the test of time.',
        },
        {
          icon: <Palette />,
          title: 'Brand Identity & Visual Branding',
          description:
            'A strong brand identity is essential for standing out in today’s competitive market. Our brand identity and visual branding services include developing consistent, engaging, and powerful visual elements such as color schemes, typography, and logo systems to create a cohesive look across all platforms.',
        },
        {
          icon: <Web />,
          title: 'Website & User Interface Design',
          description:
            'A great website is key to online success. Our website and user interface design services ensure your site is not only visually appealing but also user-friendly and optimized for the best experience. We focus on responsive designs that cater to your audience and drive conversions.',
        },
        {
          icon: <Print />,
          title: 'Print Design',
          description:
            'From business cards to brochures, our print design services help bring your brand to life in the physical world. We create compelling and professional designs for all your printed materials that enhance your marketing efforts and reinforce your brand image.',
        },
        {
          icon: <Share />,
          title: 'Social Media Graphics',
          description:
            'Your social media presence matters. Our social media graphics service provides you with eye-catching, on-brand visuals that drive engagement and communicate your message effectively on platforms like Facebook, Instagram, and LinkedIn.',
        },
        {
          icon: <BarChart />,
          title: 'Infographics Design',
          description:
            'Make complex information digestible and shareable with our infographics design services. We craft visually engaging infographics that simplify intricate data, making it easy to understand and visually appealing to your audience.',
        },
        {
          icon: <BarChart />,
          title: 'Packaging Design',
          description:
            'Your product’s packaging can make all the difference. Our packaging design service focuses on creating functional and aesthetically pleasing packaging that stands out on shelves, attracts attention, and enhances your brand’s identity.',
        },
        {
          icon: <ContactPage />,
          title: 'Business Card Design',
          description:
            'First impressions count, and our business card design services ensure that your business card is a memorable representation of your brand. We design elegant, professional, and unique business cards that leave a lasting impression.',
        },
        {
          icon: <AdUnits />,
          title: 'Banner & Advertisement Design',
          description:
            'Effective advertising is all about capturing attention quickly. Our banner & advertisement design services create impactful, creative, and effective ads that drive traffic and conversions, whether online or in print.',
        },
        {
          icon: <Draw />,
          title: 'Illustrations & Custom Artwork',
          description:
            'Stand out with unique illustrations and custom artwork tailored to your brand’s personality. Our team creates bespoke, original designs that help you communicate your message in a visually striking and memorable way.',
        },
        {
          icon: <Slideshow />,
          title: 'Presentation Design',
          description:
            'An impactful presentation can make a big difference. Our presentation design services ensure that your slides are not only visually appealing but also effectively communicate your message with clarity and creativity. We create professional presentations that captivate and engage your audience.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deevapayon?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
        {
          icon: <AutoAwesome />,
          title: 'Innovative Designs',
          description:
            'Our designs are fresh, modern, and tailored to suit your specific needs.',
        },
        {
          icon: <People />,
          title: 'Experienced Designers',
          description:
            'Our team is made up of creative professionals with extensive experience in graphic design.',
        },
        {
          icon: <AccountTree />,
          title: 'Client-Centric Approach',
          description:
            'We focus on understanding your brand and goals to create designs that resonate with your audience.',
        },
        {
          icon: <Schedule />,
          title: 'On-Time Delivery',
          description:
            'We value your time and strive to deliver top-quality work within the agreed timelines.',
        },
        {
          icon: <Savings />,
          title: 'Affordable Solutions',
          description:
            'We provide premium design services at competitive prices, ensuring value for money.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Graphic Design Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Collaborate with Deevapayon, a leading graphic design company in India, and bring your brand’s narrative to life with outstanding, creative designs. Whether you're looking for a fresh logo, a complete rebrand, or new digital content, we’re dedicated to turning your vision into reality. Our focus on innovation, excellence, and customer satisfaction guarantees that your brand will stand out in the competitive market. Let’s work together to transform your ideas into powerful visuals that leave a lasting impression on your audience.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default GraphicDesign;