// src/pages/WebsiteUserInterface.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Devices,
  TouchApp,
  Settings,
  AutoFixHigh,
  Architecture,
  Accessibility,
  ListAlt,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Website and User Interface Design.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const WebsiteUserInterface = () => {
  return (
    <>

<Helmet>
  <title>Website and User Interface Design Services - Custom Web Solutions</title>
  <meta name="description" content="Looking for professional Website & User Interface Design services? We offer custom web design, responsive layouts, UI/UX design, and more to enhance your online presence." />
  <meta name="keywords" content="Website and User Interface Design, Custom Web Design, UI/UX Design, Responsive Web Design, Website Redesign, E-commerce Web Design, User Interface Design Services, CMS Integration, SEO-Friendly Design, Web Design Company" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Website & User Interface Design
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we specialize in creating visually stunning and user-friendly websites that elevate your brand’s digital presence. Our expert team of designers focuses on both Website & User Interface Design, ensuring that every site we build is not only aesthetically pleasing but also highly functional and intuitive for your users.
              </Typography>
              <Typography variant="body1" paragraph>
              Whether you're looking to develop a brand-new website or revamp an existing one, our goal is to provide an exceptional online experience that drives engagement, conversion, and growth.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Custom Website & User Interface Design Services for Enhanced User Experience"
                title="Website & User Interface Design Services by Deevapayon"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Website & User Interface Design Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer a wide range of Website & User Interface Design services tailored to meet the unique needs of your business. From initial concept to final launch, we collaborate with you every step of the way to ensure that your vision is brought to life seamlessly.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Brush />,
      title: 'Custom Website Design',
      description:
        'Every business has unique needs, and your website should reflect that. We offer custom website design services that are tailored specifically to your brand, audience, and goals. Our team will create a visually appealing, user-friendly website that aligns with your business’s objectives while providing an excellent user experience.',
    },
    {
      icon: <Devices />,
      title: 'Responsive Web Design',
      description:
        'With more users accessing websites on mobile devices than ever before, responsive design is essential. Our responsive web design services ensure that your site looks and functions perfectly across all devices, from desktops to smartphones and tablets. We focus on designing websites that provide a seamless user experience regardless of the device used.',
    },
    {
      icon: <TouchApp />,
      title: 'User Interface (UI) Design',
      description:
        'A key component of Website & User Interface Design is creating a smooth, intuitive interface. Our expert UI designers focus on user interaction, ensuring that your website is easy to navigate, visually engaging, and meets the needs of your audience. We carefully craft elements like buttons, menus, and icons, making sure each interface component works harmoniously to create a pleasant user journey.',
    },
    {
      icon: <Accessibility />,
      title: 'User Experience (UX) Design',
      description:
        'The success of a website depends largely on the user experience. Our UX design services focus on understanding your audience and how they interact with your website. We create intuitive, effective, and user-centric designs that enhance usability and foster greater user satisfaction. A seamless UX not only keeps visitors engaged but also encourages return visits.',
    },
    {
      icon: <Architecture />,
      title: 'Wireframing & Prototyping',
      description:
        'Before diving into design, we provide wireframing and prototyping services to map out the structure and flow of your website. This allows us to visualize the layout and interaction elements in the early stages, ensuring a smoother development process. Wireframes and prototypes are a crucial step in Website & User Interface Design, enabling us to make informed decisions and reduce future changes.',
    },
    {
      icon: <AutoFixHigh />,
      title: 'Website Redesign',
      description:
        'Does your existing website feel outdated or not performing as expected? Our website redesign services can help transform your website into a modern, responsive, and high-performing asset for your business. We revamp your website’s design, improve its functionality, and implement best practices in UI and UX to ensure it’s in line with the latest trends and technologies.',
    },
    {
      icon: <ShoppingCart />,
      title: 'E-commerce Website Design',
      description:
        'For businesses looking to sell products online, we offer specialized e-commerce website design services. We create secure, visually appealing online stores that provide an exceptional shopping experience for your customers. Our e-commerce sites are designed with features like easy product navigation, secure checkout, and mobile optimization to drive sales and customer satisfaction.',
    },
    {
      icon: <Settings />,
      title: 'Content Management System (CMS) Integration',
      description:
        'We understand the importance of being able to manage and update your website’s content with ease. Our CMS integration services allow you to take control of your website’s content, whether it’s through WordPress, Joomla, or other CMS platforms. This service enables you to make updates, add pages, and manage content without needing a developer.',
    },
    {
      icon: <Search />,
      title: 'SEO-Friendly Design',
      description:
        'A beautiful website is only effective if it can be found. We implement SEO best practices into our website and UI designs to ensure your site ranks well in search engines. From optimizing images and code to ensuring mobile-friendliness, our designs are optimized for better search engine visibility and higher organic traffic.',
    },
    {
      icon: <SupportAgent />,
      title: 'Website Maintenance and Support',
      description:
        'Our work doesn’t stop after your website goes live. We offer ongoing website maintenance and support services to ensure your site continues to perform optimally. Whether you need updates, security patches, or troubleshooting, we’re here to provide reliable support and keep your website up-to-date with the latest features.',
    },
    

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us for Your Website & User Interface Design Needs?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <Tune />,
              title: 'Tailored Solutions',
              description:
                'We understand that each business is unique. That’s why our website and UI design services are customized to meet your specific needs and objectives.',
            },
            {
              icon: <Groups />,
              title: 'Experienced Designers',
              description:
                'Our team of expert designers brings years of experience in creating stunning websites and intuitive user interfaces. We’re passionate about design and focused on results.',
            },
            {
              icon: <Accessibility />,
              title: 'User-Centered Approach',
              description:
                'Our design process prioritizes your users, ensuring that your website is not only beautiful but also functional and user-friendly.',
            },
            {
              icon: <Settings />,
              title: 'Cutting-Edge Tools & Technology',
              description:
                'We stay up-to-date with the latest design trends and technologies to ensure your website is modern, scalable, and high-performing.',
            },
            {
              icon: <ListAlt />,
              title: 'End-to-End Services',
              description:
                'From the initial concept to post-launch maintenance, we offer a complete range of Website & User Interface Design services to support your business at every stage.',
            },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with Your Website & User Interface Design Project
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Ready to take your website to the next level? Contact Deevapayon today to learn more about our Website & User Interface Design services. Whether you need a new website, a redesign, or ongoing support, we’re here to help you create an outstanding online presence.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default WebsiteUserInterface;