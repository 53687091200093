// src/pages/HRMSystem.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  CloudUpload,
  FactCheck,
  DesignServices,
  Assignment,
  Security,
  TouchApp,
  ListAlt,
  Settings,
  LocalHospital,
  Factory,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/HRM Software Company in India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const HRMSystem = () => {
  return (
    <>

<Helmet>
  <title>HRM Software Company in India - Custom HR Solutions for Employee Management</title>
  <meta
    name="description"
    content="Looking for a leading HRM Software Company in India? Our customized HRM software solutions help businesses streamline HR processes, improve employee management, and boost organizational efficiency."
  />
  <meta
    name="keywords"
    content="HRM Software Company in India, Custom HRM Solutions, HR Software Development, Employee Management Software, HRMS, Payroll Management Software, Scalable HRM Solutions, Cloud HRM Solutions, HR Analytics Software, Workforce Management Solutions"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                HRM Software Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              Deeva Payon is a premier HRM software company in India, committed to revolutionizing human resource management through innovative and customizable solutions. Our software streamlines HR processes, enhances employee management, and improves overall productivity and efficiency.
              </Typography>
              <Typography variant="body1" paragraph>
              With years of expertise in HR tech, we’ve successfully assisted businesses across diverse industries in automating HR workflows, simplifying employee data management, and enabling data-driven decisions that foster growth and success. We aim to optimize HR practices for organizations of all sizes.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="HRM Software Company in India"
                title="HRM Software Solutions for Effective Employee Management"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our HRM Software Development Process
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading graphic design company in India, we offer a wide range of creative services designed to enhance your brand's visual presence and create memorable experiences for your audience. Our offerings include:
    </Typography> */}

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[

      {
        icon: <Assignment />,
        title: 'Requirement Analysis',
        description:
          'We begin by understanding your HR processes, challenges, and goals. Our team conducts detailed consultations with your HR department to gather requirements, ensuring that the HRM software we develop is perfectly aligned with your business needs.',
      },
      {
        icon: <DesignServices />,
        title: 'Design & Development',
        description:
          'Once we have a clear understanding of your requirements, our team of experts designs and develops your HRM solution using the latest technology stacks. We ensure that the system is intuitive, scalable, and secure.',
      },
      {
        icon: <FactCheck />,
        title: 'Testing & Quality Assurance',
        description:
          'Before deploying the software, we conduct rigorous testing to ensure that it meets high-quality standards. Our QA team performs various tests to ensure functionality, performance, and security.',
      },
      {
        icon: <CloudUpload />,
        title: 'Deployment & Training',
        description:
          'After successful testing, we deploy the HRM software within your organization. We also provide training sessions for your HR teams to ensure smooth adoption and usage of the new system.',
      },
      {
        icon: <SupportAgent />,
        title: 'Ongoing Support & Maintenance',
        description:
          'We offer continuous support and maintenance services to ensure your HRM system is always up-to-date and fully functional. Our team is available for troubleshooting, system updates, and optimizations.',
      },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us as Your HRM Software Company in India?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Settings />,
          title: 'Customizable HRM Solutions for Every Business',
          description:
            'As a leading HRM Software Company in India, we understand that every organization has unique human resource management needs. Our customizable HRM solutions are designed to fit your specific business requirements, whether you are a small startup or a large enterprise. From employee onboarding and payroll management to performance evaluations and compliance tracking, we offer HRM software that adapts to your needs.',
        },
        {
          icon: <ListAlt />,
          title: 'Comprehensive HRM Features',
          description:
            'Our HRM software provides a wide range of features to help you manage all aspects of human resource management effectively: Employee data management, Payroll and compensation management, Attendance and leave tracking, Performance appraisal and feedback, Recruitment and talent acquisition, Training and development tracking, Employee self-service portals, HR analytics and reporting',
        },
        {
          icon: <TouchApp />,
          title: 'User-Friendly and Intuitive Interface',
          description:
            'We prioritize ease of use in our HRM software. With a clean, intuitive interface, our platform ensures a seamless experience for HR teams and employees alike. No complex training is required—our software is easy to navigate, making it simple for your HR department to manage employee records, handle payroll, and track employee performance.',
        },
        {
          icon: <Cloud />,
          title: 'Cloud-Based and Scalable Solutions',
          description:
            'As a cloud-based HRM solution, our software provides scalability and flexibility for businesses of all sizes. Whether you need to add new features, expand employee data storage, or integrate with other business systems, our platform grows with you, ensuring that your HR software continues to meet your evolving business needs.',
        },
        {
          icon: <BarChart />,
          title: 'Enhanced HR Analytics and Reporting',
          description:
            'Our HRM software integrates advanced analytics tools that offer deep insights into your workforce data. From tracking employee productivity to monitoring recruitment trends and turnover rates, you can make informed, data-driven decisions to optimize your HR strategy and improve overall organizational performance.',
        },
        {
          icon: <People />,
          title: 'Employee Self-Service and Engagement',
          description:
            'Our HRM software includes self-service portals that empower employees to manage their personal information, request leave, view payslips, and track their performance. This self-service feature not only reduces administrative tasks for HR teams but also enhances employee engagement and satisfaction.',
        },
        {
          icon: <Security />,
          title: 'Compliance and Security',
          description:
            'As an HRM Software Company in India, we ensure that our solutions comply with local and international labor laws and regulations. We use the highest security standards to protect your sensitive employee data, including encryption, data backup, and secure cloud hosting, keeping your organization’s data safe and confidential.',
        },
        {
          icon: <Link />,
          title: 'Seamless Integration with Existing Systems',
          description:
            'Our HRM software is designed to integrate easily with other business systems such as payroll, ERP, and accounting software. This allows for a unified platform where all HR data and business functions are interconnected, reducing duplication, enhancing data accuracy, and improving operational efficiency.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Industries We Serve
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
      {
        icon: <Factory />,
        title: 'Manufacturing',
        description:
          'Streamline workforce management, attendance tracking, and employee productivity.',
      },
      {
        icon: <LocalHospital />,
        title: 'Healthcare',
        description:
          'Manage employee certifications, shift schedules, and compliance requirements.',
      },
      {
        icon: <School />,
        title: 'Education',
        description:
          'Simplify payroll management, recruitment, and staff training for educational institutions.',
      },
      {
        icon: <ShoppingCart />,
        title: 'Retail',
        description:
          'Efficiently handle staff attendance, performance evaluation, and payroll for large teams.',
      },
      {
        icon: <AccountBalance />,
        title: 'Finance',
        description:
          'Optimize talent acquisition, training, and compliance tracking in the financial sector.',
      },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Benefits of Choosing Deevapayon as Your HRM Software Company in India
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
      {
        icon: <Settings />,
        title: 'Increased HR Efficiency',
        description:
          'Automate manual HR tasks, reduce errors, and save time for your HR team.',
      },
      {
        icon: <People />,
        title: 'Better Employee Management',
        description:
          'Easily manage employee records, payroll, performance, and development.',
      },
      {
        icon: <BarChart />,
        title: 'Improved Decision-Making',
        description:
          'Leverage HR analytics to make data-driven decisions that enhance employee engagement and organizational growth.',
      },
      {
        icon: <Savings />,
        title: 'Cost Savings',
        description:
          'Minimize HR administrative costs with automated processes and self-service portals.',
      },
      {
        icon: <Security />,
        title: 'Compliance Assurance',
        description:
          'Stay compliant with labor laws and regulations while protecting employee data.',
      },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best HRM System Agency in India
    </Typography>
    {/* <Typography variant="body1" align="center" paragraph>
    Collaborate with Deevapayon, a leading graphic design company in India, and bring your brand’s narrative to life with outstanding, creative designs. Whether you're looking for a fresh logo, a complete rebrand, or new digital content, we’re dedicated to turning your vision into reality. Our focus on innovation, excellence, and customer satisfaction guarantees that your brand will stand out in the competitive market. Let’s work together to transform your ideas into powerful visuals that leave a lasting impression on your audience.

    </Typography> */}
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default HRMSystem;