import React from "react";
import { Box, Typography, Button, Grid, Container } from "@mui/material";
import aboutus from "../../../assest/Software Development Services.png";

const CompanyStory = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#f8f9fa", // Light gray background
        py: { xs: 8, md: 10 }, // Increased top & bottom padding
        px: { xs: 3, md: 0 }, // Increased horizontal padding
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="lg">
        <Grid container alignItems="center" spacing={6}>
          {/* Text Section */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              sx={{
                color: "#f72f20",
                fontWeight: "bold",
                textTransform: "uppercase",
                letterSpacing: "1.5px",
                mb: 2,
              }}
            >
              About Deeva Payon
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                fontSize: "2rem",
                color: "#222",
                lineHeight: 1.3,
                mb: 3,
              }}
            >
              Innovative Software Development Solutions for Exceptional Digital Experiences
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#555",
                fontSize: "1.2rem",
                lineHeight: 1.9,
                maxWidth: "95%",
                mb: 4,
              }}
            >
              Deeva Payon is a software development company delivering
              innovative solutions in technology and design. Our team excels in
              creating exceptional digital experiences, empowering brands with
              cutting-edge strategies. We transform concepts into reality,
              ensuring success through creativity, collaboration, and precision
              in every project.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#1f15e0",
                px: 5,
                py: 1.5,
                fontSize: "1.2rem",
                fontWeight: "bold",
                textTransform: "none",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: "#1510b4",
                },
              }}
            >
              Learn More
            </Button>
          </Grid>

          {/* Image Section */}
          <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              src={aboutus}
              alt="Deeva Payon Team"
              sx={{
                width: "100%",
                maxWidth: "600px", // Increased max image width
                borderRadius: "20px",
                boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "scale(1.08)",
                },
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CompanyStory;
