// src/pages/AdminPanelDevelopment.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Settings,
  TouchApp,
  Security,
  Explore,
  DesignServices,
  FactCheck,
  CloudUpload,
  AutoGraph,
  HomeWork,
  LocalHospital,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Admin Panel Development & Management.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AdminPanelDevelopment = () => {
  return (
    <>

<Helmet>
  <title>Admin Panel Development & Management Services - Custom Solutions for Your Business</title>
  <meta
    name="description"
    content="Explore expert Admin Panel Development & Management services. We create custom, secure, and scalable admin panels to streamline operations and improve business efficiency."
  />
  <meta
    name="keywords"
    content="Admin Panel Development & Management, Custom Admin Panel, Admin Panel Solutions, Secure Admin Panel, Scalable Admin Panel, Admin Panel Services, Business Management Panel, Admin Panel Design, Back-End Development"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Admin Panel Development & Management Services
              </Typography>
              <Typography variant="body1" paragraph>
              At Deeva Payon, we specialize in Admin Panel Development & Management solutions, helping businesses streamline operations with efficient, user-friendly back-end systems. From managing user roles and controlling content to analyzing crucial business data, a well-designed admin panel is essential for success.
              </Typography>
              <Typography variant="body1" paragraph>
              Our expert team offers comprehensive services to create custom admin panels tailored to your unique business needs, ensuring enhanced functionality, ease of use, and seamless management for improved operational efficiency and growth.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Admin Panel Development & Management"
                title="Admin Panel Development & Management Solutions"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Our Admin Panel Development & Management Services?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading graphic design company in India, we offer a wide range of creative services designed to enhance your brand's visual presence and create memorable experiences for your audience. Our offerings include:
    </Typography> */}

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Settings />,
          title: 'Custom Solutions for Every Business Need',
          description:
            'We understand that every business operates differently. Our Admin Panel Development & Management services ensure that your admin panel is built to align perfectly with your business objectives. We deliver custom features, intuitive dashboards, and secure back-end systems to improve your business operations.',
        },
        {
          icon: <TouchApp />,
          title: 'User-Friendly Interfaces',
          description:
            'Ease of use is at the core of our admin panel designs. We focus on creating admin panels with simple navigation, organized dashboards, and clear workflows, making it easy for your team to manage tasks and make data-driven decisions quickly.',
        },
        {
          icon: <Security />,
          title: 'Robust Security',
          description:
            'Security is a top priority in admin panel development. We implement the latest security protocols to ensure that your sensitive data is always protected from unauthorized access, data breaches, and vulnerabilities. From encrypted logins to user authentication systems, our admin panels are built with the highest level of security.',
        },
        {
          icon: <TrendingUp />,
          title: 'Scalable and Flexible Solutions',
          description:
            'As your business grows, your needs will evolve. Our Admin Panel Development & Management services provide scalable solutions, allowing you to add or modify features without disrupting operations. We build flexible platforms that grow alongside your business, ensuring long-term viability and efficiency.',
        },
        {
          icon: <BarChart />,
          title: 'Data Analytics and Reporting',
          description:
            'Making informed decisions requires data insights. Our admin panels come equipped with advanced reporting tools and data analytics features, allowing you to track key metrics, generate custom reports, and make strategic decisions based on real-time data.',
        },
        {
          icon: <Link />,
          title: 'Seamless Integration',
          description:
            'Whether it\'s integrating with third-party applications, databases, or existing business systems, we ensure your admin panel seamlessly connects with the tools you already use. This reduces the need for manual input and boosts productivity.',
        },
        {
          icon: <SupportAgent />,
          title: 'Ongoing Support and Maintenance',
          description:
            'Our commitment doesn’t end once the admin panel is developed. We offer ongoing support and maintenance services to ensure your panel remains up-to-date, secure, and fully functional. We handle all updates, bug fixes, and performance optimizations, allowing you to focus on your core business activities.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Admin Panel Development Process
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Explore />,
          title: 'Discovery & Planning',
          description:
            'We begin by understanding your business processes, pain points, and goals. Our team works with you to map out the functionality and features required for the admin panel. This phase ensures that we design a solution tailored to your exact needs.',
        },
        {
          icon: <DesignServices />,
          title: 'Design & Development',
          description:
            'Our UI/UX experts create user-friendly wireframes and prototypes, followed by development using the latest technologies. We ensure that every feature is seamlessly integrated and performs optimally.',
        },
        {
          icon: <FactCheck />,
          title: 'Testing & Quality Assurance',
          description:
            'Rigorous testing is conducted to ensure the admin panel functions smoothly across all devices and platforms. We test for performance, security, and usability to deliver a bug-free, high-quality product.',
        },
        {
          icon: <CloudUpload />,
          title: 'Deployment & Training',
          description:
            'After the panel is thoroughly tested, we deploy it on your systems. Our team also provides training to your staff to ensure a smooth transition and efficient use of the admin panel.',
        },
        {
          icon: <AutoGraph />,
          title: 'Continuous Improvement',
          description:
            'We monitor the performance of your admin panel and gather feedback from your team to implement ongoing improvements. Whether it’s adding new features or optimizing existing ones, we’re committed to your admin panel’s long-term success.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Industries We Serve
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[

        {
          icon: <ShoppingCart />,
          title: 'E-Commerce',
          description:
            'Manage products, track orders, and analyze customer data with ease.',
        },
        {
          icon: <LocalHospital />,
          title: 'Healthcare',
          description:
            'Securely manage patient data, appointments, and healthcare resources.',
        },
        {
          icon: <School />,
          title: 'Education',
          description:
            'Simplify course management, grading, and student data management.',
        },
        {
          icon: <HomeWork />,
          title: 'Real Estate',
          description:
            'Control property listings, manage client data, and handle transactions.',
        },
        {
          icon: <AccountBalance />,
          title: 'Finance',
          description:
            'Ensure secure access to financial data and streamline reporting.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>


      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with Your Admin Panel Today
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Unlock the power of efficient business management with Admin Panel Development & Management services from Deeva Payon. Our expert team is ready to provide you with a tailored solution that improves workflow, boosts security, and empowers your business to operate at its best.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default AdminPanelDevelopment;