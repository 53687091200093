import React from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";

const achievements = [
  { value: 3000, label: "Projects Delivered", suffix: "+" },
  { value: 96, label: "Enterprise Clients", suffix: "%" },
  { value: 200, label: "Happy Clients", suffix: "+" },
  { value: 40, label: "Countries Served", suffix: "+" },
  { value: 10, label: "Clients of 8+ Years", suffix: "" },
  { value: 4.8, label: "Customer Ratings", suffix: "/5" },
];

const ratings = [
  { platform: "Clutch", rating: 4.5 },
  { platform: "GoodFirms", rating: 5 },
  { platform: "Google", rating: 4.5 },
  { platform: "AmbitionBox", rating: 5 },
  { platform: "Glassdoor", rating: 4.5 },
];

// Function to render stars dynamically
const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  return (
    <>
      {[...Array(fullStars)].map((_, index) => (
        <StarIcon key={index} sx={{ color: "#FFD700", fontSize: "20px" }} />
      ))}
      {halfStar && <StarHalfIcon sx={{ color: "#FFD700", fontSize: "20px" }} />}
    </>
  );
};

const NumbersSection = () => {
  // Use Intersection Observer to trigger animation on scroll
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <Box
      ref={ref}
      sx={{
        py: { xs: 8, md: 12 },
        px: { xs: 4, md: 8 },
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#f9f9f9", // Light neutral background
      }}
    >
      {/* Background Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          background: "linear-gradient(135deg, #f0f8ff, #e6f7ff)",
          opacity: 0.8,
        }}
      />

      {/* Section Title */}
      <Typography
  variant="h3"
  align="center"
  sx={{
    mb: { xs: 6, md: 8 },
    fontWeight: "bold",
    color: "#fff", // White text color
    background: "#000", // Black background
    borderRadius: "16px", // Rounded corners
    padding: "10px 20px", // Padding for better spacing
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
    display: "flex", // Use flexbox for perfect centering
    alignItems: "center", // Align vertically
    justifyContent: "center", // Align horizontally
    margin: "0 auto", // Center the box itself
    maxWidth: "fit-content", // Ensure the box only wraps the content
    textTransform: "uppercase", // Uppercase text
    letterSpacing: 2, // Spacing between letters
  }}
>
  Numbers That Speak About Us
</Typography>

      {/* Achievements Section */}
      <Grid container spacing={4} justifyContent="center">
        {achievements.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
            <Card
              sx={{
                textAlign: "center",
                borderRadius: "16px",
                background: "#ffffff",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 8px 20px rgba(25, 118, 210, 0.3)",
                },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", md: "2.5rem" },
                  color: "#1976d2",
                  mb: 1,
                }}
              >
                {inView ? (
                  <CountUp start={0} end={item.value} duration={3} separator="," />
                ) : (
                  "0"
                )}
                {item.suffix}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#555",
                  fontSize: { xs: "1rem", md: "1.2rem" },
                }}
              >
                {item.label}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Ratings Section Title */}
      <Typography
  variant="h4"
  align="center"
  sx={{
    mt: { xs: 8, md: 12 },
    mb: { xs: 6, md: 8 },
    fontWeight: "bold",
    color: "#fff", // White text color
    background: "#000", // Black background
    borderRadius: "16px", // Rounded corners
    padding: "10px 20px", // Padding for better spacing
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
    display: "flex", // Use flexbox for perfect centering
    alignItems: "center", // Align vertically
    justifyContent: "center", // Align horizontally
    margin: "0 auto", // Center the box itself
    maxWidth: "fit-content", // Ensure the box only wraps the content
    textTransform: "uppercase", // Uppercase text
    letterSpacing: 2, // Spacing between letters
  }}
>
  Our Ratings Across Platforms
</Typography>

      {/* Ratings Section */}
      <Grid container spacing={4} justifyContent="center">
        {ratings.map((rating, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
            <Box
              sx={{
                textAlign: "center",
                borderRadius: "16px",
                background: "#ffffff",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0px 8px 20px rgba(240, 90, 34, 0.3)",
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1.2rem", md: "1.4rem" },
                  color: "#f05a22",
                  mb: 1,
                }}
              >
                {rating.platform}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                {renderStars(rating.rating)}
              </Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  color: "#555",
                  fontSize: { xs: "1rem", md: "1.2rem" },
                }}
              >
                {rating.rating}/5
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default NumbersSection;