import React from "react";
import { Box, Button, Typography } from "@mui/material";

const CallToAction = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #1D1D1D, #121212)", // Subtle gradient for depth
        color: "white",
        textAlign: "center",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" }, // Stack on small screens, row on larger screens
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        padding: { xs: "20px", md: "40px 60px" }, // Responsive padding
        borderRadius: "8px",
        margin: "auto",
        mt: 4,
        mb: 6, // Add spacing below the component
      }}
    >
      {/* Left Content */}
      <Box
        sx={{
          textAlign: { xs: "center", sm: "left" }, // Center align on small screens
          flexGrow: 1,
          mb: { xs: 3, sm: 0 }, // Add spacing below text on small screens
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            fontSize: { xs: "24px", md: "32px" }, // Responsive font size
            mb: 1,
          }}
        >
          What are you waiting for?
        </Typography>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{
            fontSize: { xs: "16px", md: "18px" }, // Responsive font size
          }}
        >
          Call us now and increase your business sales and ROI{" "}
          <span style={{ color: "#4CAF50" }}>+91 9785909785</span>
        </Typography>
      </Box>

      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: { xs: "center", sm: "flex-end" }, // Center align on small screens
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#2075BC",
            color: "white",
            "&:hover": { backgroundColor: "#105A8D" },
            padding: "10px 20px",
            fontSize: { xs: "14px", md: "16px" }, // Responsive font size
          }}
          href="tel:+919785909785"
        >
          Call Us
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#21767A",
            color: "white",
            "&:hover": { backgroundColor: "#105C5F" },
            padding: "10px 20px",
            fontSize: { xs: "14px", md: "16px" }, // Responsive font size
          }}
          href="/contact"
        >
          Contact Us
        </Button>
      </Box>
    </Box>
  );
};

export default CallToAction;