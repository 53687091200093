// src/pages/Paymentgatewayservices.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Payment,
  Language,
  Smartphone,
  Autorenew,
  Shield,
  Public,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Payment Gateway.png';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Paymentgatewayservices = () => {
  return (
    <>

<Helmet>
  <title>Payment Gateway Services | Secure & Seamless Payment Solutions</title>
  <meta name="description" content="Explore our Payment Gateway Services for seamless, secure transactions. From integration to fraud prevention, we provide comprehensive solutions to help your business accept payments efficiently and securely." />
  <meta name="keywords" content="Payment Gateway Services, Secure Payment Gateway, Payment Gateway Integration, E-commerce Payment Gateway, Multi-Currency Payment Gateway, Mobile Payment Solutions, Recurring Payment Gateway, Custom Payment Gateway, Payment Security Services, Payment Gateway API" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Payment Gateway Services
              </Typography>
              <Typography variant="body1" paragraph>
              In today’s digital economy, a seamless and secure payment experience is crucial for businesses that want to build trust and foster long-term relationships with their customers.
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we specialize in providing comprehensive Payment Gateway Services that ensure smooth, secure, and hassle-free transactions for your e-commerce platform. Our solutions are designed to integrate with your existing systems and provide an intuitive and user-friendly experience for both you and your customers.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Payment gateway integration on a digital device showing secure online transactions"
                title="Secure and Seamless Payment Gateway Solutions for Your Business"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Payment Gateway Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer a wide range of Payment Gateway Services to help you accept payments securely and efficiently. From integration to custom solutions, we provide a comprehensive suite of options to meet your business needs:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <Payment />,
              title: 'Payment Gateway Integration',
              description:
                'We provide seamless Payment Gateway Integration with your website or e-commerce platform. Our team ensures smooth integration, so you can start accepting payments quickly without any disruptions to your business operations.',
            },
            {
              icon: <Language />,
              title: 'Multi-Currency Payment Gateway',
              description:
                'Expand your business globally with our Multi-Currency Payment Gateway. We offer solutions that allow you to accept payments in multiple currencies, providing a seamless experience for your international customers.',
            },
            {
              icon: <Smartphone />,
              title: 'Mobile Payment Gateway Solutions',
              description:
                'With more customers shopping via mobile devices, offering mobile payment options is essential. Our Mobile Payment Gateway Solutions make it easy for you to accept payments via smartphones and tablets, enhancing customer convenience and improving sales.',
            },
            {
              icon: <Autorenew />,
              title: 'Recurring Payment Gateway',
              description:
                'For businesses that offer subscription services or recurring billing, our Recurring Payment Gateway solution ensures automated, secure, and efficient payments. Whether it’s monthly subscriptions or annual payments, we’ve got you covered.',
            },
            {
              icon: <Tune />,
              title: 'Custom Payment Gateway Solutions',
              description:
                'Every business is unique, and we understand that your payment needs may require custom solutions. We provide Custom Payment Gateway Solutions designed to fit your specific requirements, ensuring a seamless and secure payment experience for your customers.',
            },
            {
              icon: <Shield />,
              title: 'Fraud Prevention & Security',
              description:
                'Protect your business and customers with our Fraud Prevention & Security services. We implement the latest security protocols such as PCI-DSS compliance, encryption, and tokenization to safeguard every transaction and reduce the risk of fraud.',
            },
            {
              icon: <Code />,
              title: 'Payment Gateway API',
              description:
                'If you\'re looking for more flexibility, our Payment Gateway API allows you to integrate the payment system directly into your software or mobile app. This provides greater control over your payment processes while maintaining the highest levels of security.',
            },
            {
              icon: <BarChart />,
              title: 'Payment Analytics and Reporting',
              description:
                'Gain deeper insights into your payment data with our Payment Analytics and Reporting service. We provide detailed reports on transaction volumes, success rates, and more, helping you make data-driven decisions for your business.',
            },
            {
              icon: <Public />,
              title: 'Global Payment Gateway Solutions',
              description:
                'For businesses operating across multiple countries, our Global Payment Gateway Solutions support payments from various regions, currencies, and payment methods. This enables you to reach a wider customer base while managing transactions from a single platform.',
            },
            {
              icon: <ShoppingCart />,
              title: 'E-Commerce Payment Gateway Services',
              description:
                'If you run an online store, our E-Commerce Payment Gateway Services are designed to provide a smooth and secure checkout experience for your customers. We ensure fast and reliable payment processing so you can focus on growing your business.',
            },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us for Your Payment Gateway Services?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Link />,
          title: 'Seamless Integration',
          description:
            'Our Payment Gateway Services integrate smoothly with your existing platforms, ensuring minimal disruption to your business operations.',
        },
        {
          icon: <Shield />,
          title: 'Security First',
          description:
            'We prioritize security and compliance, using the latest encryption technologies and fraud protection measures to safeguard your transactions.',
        },
        {
          icon: <Language />,
          title: 'Global Reach',
          description:
            'Accept payments from customers all over the world with our multi-currency payment solutions, enabling you to expand your business internationally.',
        },
        {
          icon: <Tune />,
          title: 'Customizable Solutions',
          description:
            'We offer tailored Payment Gateway Services that cater to the unique needs of your business, providing flexibility and scalability.',
        },
        {
          icon: <SupportAgent />,
          title: 'Expert Support',
          description:
            'Our experienced team provides ongoing support and guidance to ensure that your payment system continues to run smoothly.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with Our Payment Gateway Services
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Ready to simplify your payment process and ensure secure, efficient transactions for your customers? Contact us today to learn more about our Payment Gateway Services and how we can help integrate the best payment solutions for your business. We’re here to support you every step of the way!


    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default Paymentgatewayservices;