import React from "react";
import { Box, Typography, Button, Grid, Container } from "@mui/material";
import BusinessHelpImage from "../assest/BusinessHelpSection.jpeg"; // Import your image

const BusinessHelpSection = () => {
  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        px: { xs: 4, md: 6 },
        backgroundColor: "#F9FAFB",
        borderRadius: "20px",
        boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={6}
          alignItems="center"
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" }, // Stack on mobile
          }}
        >
          {/* Left Side: Content */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: "bold",
                color: "#2075BC",
                fontSize: { xs: "1.8rem", md: "2.3rem" },
                mb: { xs: 2, md: 3 },
                // textTransform: "uppercase",
                letterSpacing: 1,
              }}
            >
              Struggling in business? Top Indian software development company can help Stay Ahead!
            </Typography>

              {/* <Typography
                variant="h6"
                sx={{
                  fontWeight: "medium",
                  color: "#21767A",
                  fontSize: { xs: "1.2rem", md: "1.6rem" },
                  mb: { xs: 3, md: 4 },
                  lineHeight: 1.4,
                }}
              >
                A Leading Software Development Company in India Can Help You Stay Ahead!
              </Typography> */}

            <Typography
              variant="body1"
              sx={{
                color: "#555",
                lineHeight: 1.8,
                fontSize: { xs: "0.9rem", md: "1rem" },
                mb: 4,
              }}
            >
              Deeva Payon is a leading Software Development Company in India, delivering innovative applications for web, mobile, and enterprise needs. We empower businesses with scalable and reliable solutions, leveraging advanced technologies to drive efficiency and growth. Get high-quality software and fintech services designed for your success, with end-to-end support, seamless integration, and customized solutions to meet your unique business requirements.
            </Typography>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2075BC",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#21767A",
                },
                fontWeight: "bold",
                padding: "12px 28px",
                fontSize: "1rem",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              Book a Free Consultation
            </Button>
          </Grid>

          {/* Right Side: Image */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: { xs: "250px", md: "400px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                borderRadius: "16px",
                overflow: "hidden",
                boxShadow: "0 6px 10px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.03)",
                },
              }}
            >
              <img
                src={BusinessHelpImage}
                alt="Business Growth"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "16px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BusinessHelpSection;
