// src/Services/WebsiteDevelopment.js
import React from 'react';
import { Box, Container, Typography, Grid, Button, Card, CardContent } from '@mui/material';
import { Devices, Code, Web, Security, ShoppingCart, Apps, Search, SupportAgent, VerifiedUser, Visibility } from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import webDevelopmentImage from '../assest/developers-team-web-design-with-people-characters-vector.jpg'
import CallToAction from './CallToAction';
import { Helmet } from 'react-helmet';

const WebsiteDevelopment = () => {
  return (
    <>

<Helmet>
  <title>Best Website Development Company in India | Deeva Payon</title>
  <meta
    name="description"
    content="Looking for a reliable Website Development Company in India? We provide custom web development, e-commerce solutions, CMS, and responsive design. Contact us!"
  />
  <meta
    name="keywords"
    content="Best Website Development Company in india, Website Development Company in india, Website Development Company, Custom Web Development Services, E-commerce Solutions, Responsive Web Design"
  />
</Helmet>
      <Header />
      <Box
        sx={{
          backgroundColor: '#f9f9f9',
          padding: '7rem 0',
        }}
      >
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              gap: 4,
              marginBottom: '3rem',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: 2 }}>
              Website Development Company in India
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
              As a leading website development company in India, we specialize in delivering innovative and effective web solutions. Our focus is on building custom websites that promote business growth and strengthen your online presence. Whether you're a startup or a large enterprise, we tailor our services to align with your unique needs and objectives. With our expertise, we create high-performing websites that help you engage your audience and achieve long-term success in the digital landscape.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ marginTop: 3 }}
                href="/contact"
              >
                Get Started
              </Button>
            </Box>
            <Box sx={{ flex: 1 }}>
              <img
                src={webDevelopmentImage}
                alt="Deevapayon, Website Development company"
                title="Website Development - Building Your Online Presence"
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Box>
          </Box>

          {/* Services Offered */}
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  color: "#fff", // White text color
                  background: "#1976d2", // Blue background
                  borderRadius: "16px", // Rounded corners
                  padding: "10px 20px", // Padding for better spacing
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
                  textTransform: "uppercase", // Uppercase text
                  letterSpacing: "2px", // Spacing between letters
                  display: "flex", // Use flexbox for centering
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                  margin: "0 auto", // Center the box itself
                  maxWidth: "fit-content", // Ensure the box only wraps the content
                }}
              >
                Our Website Development Services
              </Typography>

              <Typography
                    variant="body1"
                    align="center"
                    paragraph
                    sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
                  >
                    Team up with Deevapayon to speed up your web application development and stay ahead of the competition!
                  </Typography>



                  <Grid container spacing={4}>
  {[
    {
      icon: <Code fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Custom Web Development',
      description:
        'We specialize in building bespoke websites that reflect your brand identity and cater to your unique business requirements. Our team of experienced developers ensures a seamless and functional online experience for your users.',
    },
    {
      icon: <ShoppingCart fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'E-commerce Solutions',
      description:
        'Expand your reach and boost sales with our comprehensive e-commerce solutions. We create secure, user-friendly online stores with features like shopping carts, payment gateway integration, and inventory management.',
    },
    {
      icon: <Web fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'CMS Development',
      description:
        'Take control of your website content with our robust Content Management Systems (CMS). We offer customized CMS solutions using platforms like WordPress, Shopify, and Drupal, empowering you to easily update and manage your site.',
    },
    {
      icon: <Apps fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Web Application Development',
      description:
        'We develop dynamic web applications using cutting-edge technologies like React.js, Node.js, and Python. Our applications are designed for scalability, performance, and seamless user experience.',
    },
    {
      icon: <Devices fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Responsive Web Design',
      description:
        'Connect with your audience seamlessly across all devices through our responsive web design services. We ensure your website looks and functions flawlessly on desktops, tablets, and smartphones.',
    },
    {
      icon: <Search fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'SEO-Friendly Development',
      description:
        'We build websites with SEO in mind, ensuring they are optimized for search engines and can rank higher in search results. This includes optimizing site structure, content, and meta tags to improve visibility.',
    },
  ].map((card, index) => (
    <Grid item xs={12} md={6} key={index}>
      <Card
        sx={{
          transition: '0.3s',
          '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {card.icon}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {card.title}
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
            {card.description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

{/* Benefits Section */}
<Typography
  variant="h4"
  align="center"
  gutterBottom
  sx={{
    fontWeight: "bold",
    color: "#fff", // White text color
    background: "#1976d2", // Blue background
    borderRadius: "16px", // Rounded corners
    padding: "10px 20px", // Padding for better spacing
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
    textTransform: "uppercase", // Uppercase text
    letterSpacing: "2px", // Spacing between letters
    display: "flex", // Use flexbox for centering
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    margin: "0 auto", // Center the box itself
    maxWidth: "fit-content", // Ensure the box only wraps the content
    marginTop: 5
  }}
>
  Benefits of Investing in Website Development Services in India
</Typography>

<Typography
  variant="body1"
  align="center"
  paragraph
  sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
>
  Discover how investing in professional website development can transform your business.
</Typography>

<Grid container spacing={4}>
  {[
    {
      icon: <Visibility fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Expand Online Visibility',
      description:
        'A website allows you to showcase your services to a wider audience and generate leads.',
    },
    {
      icon: <VerifiedUser fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Establish Credibility',
      description:
        'A professional website builds trust and credibility with potential customers.',
    },
    {
      icon: <ShoppingCart fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Increase Sales',
      description:
        'An e-commerce website enables you to sell products and services online, increasing your revenue.',
    },
    {
      icon: <SupportAgent fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Improve Customer Service',
      description:
        'A website can provide valuable information and support to your customers, improving their overall experience.',
    },
  ].map((card, index) => (
    <Grid item xs={12} md={6} key={index} >
      <Card
        sx={{
          transition: '0.3s',
          '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)', },
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2,  }}>
            {card.icon}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {card.title}
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
            {card.description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

{/* Benefits Section */}
<Typography
  variant="h4"
  align="center"
  gutterBottom
  sx={{
    fontWeight: "bold",
    color: "#fff", // White text color
    background: "#1976d2", // Blue background
    borderRadius: "16px", // Rounded corners
    padding: "10px 20px", // Padding for better spacing
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
    textTransform: "uppercase", // Uppercase text
    letterSpacing: "2px", // Spacing between letters
    display: "flex", // Use flexbox for centering
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    margin: "0 auto", // Center the box itself
    maxWidth: "fit-content", // Ensure the box only wraps the content
    marginTop: 5,
  }}
>
  Benefits of Investing in Website Development Services in India
</Typography>

<Typography
  variant="body1"
  align="center"
  paragraph
  sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
>
  Discover how investing in professional website development can transform your business.
</Typography>

<Grid container spacing={4}>
  {[
    {
      icon: <Visibility fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Expand Online Visibility',
      description:
        'A website allows you to showcase your services to a wider audience and generate leads.',
    },
    {
      icon: <VerifiedUser fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Establish Credibility',
      description:
        'A professional website builds trust and credibility with potential customers.',
    },
    {
      icon: <ShoppingCart fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Increase Sales',
      description:
        'An e-commerce website enables you to sell products and services online, increasing your revenue.',
    },
    {
      icon: <SupportAgent fontSize="large" sx={{ color: '#1976d2' }} />,
      title: 'Improve Customer Service',
      description:
        'A website can provide valuable information and support to your customers, improving their overall experience.',
    },
  ].map((card, index) => (
    <Grid item xs={12} md={6} key={index}>
      <Card
        sx={{
          transition: '0.3s',
          '&:hover': { transform: 'translateY(-10px)', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)' },
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {card.icon}
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {card.title}
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
            {card.description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

{/* Call-to-Action Section */}
<Box
  sx={{
    marginTop: '40px',
    textAlign: 'center',
    padding: '20px',
    background: '#f5f5f5', // Light gray background
    borderRadius: '8px', // Rounded corners
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
  }}
>
  <Typography
    variant="h5"
    sx={{
      fontWeight: 'bold',
      color: '#1976d2', // Blue text color
      marginBottom: '10px',
    }}
  >
    Ready to Transform Your Business?
  </Typography>
  <Typography
    variant="body1"
    sx={{
      color: '#333', // Dark gray text
      marginBottom: '20px',
    }}
  >
    Contact our Website Development Company in India today to discuss your project and discover how we can help you achieve your online goals!
  </Typography>
  <Button
    variant="contained"
    href="/contact"
    sx={{
      background: '#1976d2', // Blue button
      color: '#fff', // White text
      padding: '10px 20px',
      fontWeight: 'bold',
      '&:hover': {
        background: '#1565c0', // Darker blue on hover
      },
    }}
  >
    Contact Us Now
  </Button>
</Box>

          {/* Why Choose Us
          <Box sx={{ marginTop: '4rem', textAlign: 'center' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              Why Choose Deeva Payon for Website Development?
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph sx={{ marginTop: '1rem', marginBottom: '3rem' }}>
              At Deeva Payon, we’re not just building websites—we’re creating digital experiences. From the latest web technologies to responsive and secure designs, we ensure that your business shines online. Let us take care of everything from design to deployment, so you can focus on growing your business.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              href="/contact"
            >
              Start Your Project Today
            </Button>
          </Box> */}
        </Container>
      </Box>
      <CallToAction/>
      <Footer />
    </>
  );
};

export default WebsiteDevelopment;
