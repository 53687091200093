// src/pages/IllustrationsCustomArtwork.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Portrait,
  ChildCare,
  Chat,
  Edit,
  CheckCircle,
  Verified,
  Timer,
  DesignServices,
  Book,
  Person,
  Style,
  ListAlt,
  Settings,
  ContactSupport,
  Gavel,
  SearchOffOutlined,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deeva Payon Social Media Marketing Services India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const SocialMediaMarketing = () => {
  return (
    <>

<Helmet>
  <title>Best Social Media Marketing Company in India | Deeva Payon</title>
  <meta name="description" content="Deeva Payon is the best social media marketing company in India, offering customized strategies to boost brand visibility, engagement, and conversions through effective campaigns" />
  <meta name="keywords" content="Best Social Media Marketing Company in India, Social Media Marketing Services, Social Media Advertising India, Social Media Strategy India, Social Media Campaigns India, Social Media Analytics India, Paid Social Media Advertising India, Social Media Optimization India, Influencer Marketing India, Online Reputation Management India" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Best Social Media Marketing Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              Deeva Payon is recognized as the best social media marketing company in India, offering comprehensive and results-driven social media strategies designed to elevate your brand’s presence. Our expert team specializes in crafting customized social media marketing campaigns that engage, convert, and retain your target audience.
              </Typography>
              <Typography variant="body1" paragraph>
              Whether you're a startup looking to establish your online presence or an established business aiming to expand your digital footprint, we provide the right solutions to boost your brand visibility and achieve your marketing goals.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Social Media Marketing Services by Deeva Payon - Best Company in India"
                title="Deeva Payon Social Media Marketing Services India"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deeva Payon as Your Social Media Marketing Company in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As the best social media marketing company in India, we are committed to delivering innovative and effective social media solutions that drive measurable results. Here’s why businesses trust us with their social media marketing needs:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <ListAlt />,
          title: 'Comprehensive Social Media Strategy',
          description:
            'We create tailored social media strategies that align with your business goals. By understanding your target audience and industry dynamics, we develop a roadmap to help you achieve optimal engagement, increased traffic, and enhanced brand visibility on platforms like Facebook, Instagram, LinkedIn, Twitter, and others.',
        },
        {
          icon: <Edit />,
          title: 'Content Creation and Management',
          description:
            'Content is king, and we provide high-quality content that resonates with your audience. From creating engaging posts to crafting informative blogs and visual content, we ensure that your social media channels stay relevant and engaging. Our content management services ensure consistency and creativity across all your social media platforms.',
        },
        {
          icon: <BarChart />,
          title: 'Paid Social Media Advertising (PPC)',
          description:
            'Maximize your brand\'s reach with our expert paid social media advertising services. We use Facebook Ads, Instagram Ads, LinkedIn Ads, and Twitter Ads to create targeted campaigns that drive qualified traffic and convert leads into loyal customers. Our PPC specialists optimize every campaign to ensure the best ROI for your business.',
        },
        {
          icon: <BarChart />,
          title: 'Social Media Analytics and Reporting',
          description:
            'We believe in data-driven decisions. Our social media analytics services help you track the performance of your campaigns, understand audience behavior, and gain actionable insights. We use advanced tools to analyze key metrics such as engagement, reach, and conversions, providing you with regular reports to optimize your strategy.',
        },
        {
          icon: <Groups />,
          title: 'Community Management',
          description:
            'Building a strong community around your brand is essential for long-term success. Our community management services include responding to customer queries, fostering discussions, and creating a positive brand image. We help you build relationships with your audience that go beyond likes and shares.',
        },
        {
          icon: <Settings />,
          title: 'Influencer Marketing',
          description:
            'Tap into the power of influencers to boost your brand’s credibility and reach. Our influencer marketing services connect you with top influencers in your industry to help promote your products/services to a wider, highly-targeted audience. We manage collaborations from start to finish to ensure a smooth and effective campaign.',
        },
        {
          icon: <Settings />,
          title: 'Social Media Optimization (SMO)',
          description:
            'We specialize in social media optimization (SMO), ensuring that your social profiles are fully optimized for better visibility and engagement. From profile setup to content optimization, we make sure your brand stands out in the crowded social media space.',
        },
        {
          icon: <ThumbUp />,
          title: 'Brand Reputation Management',
          description:
            'Protect and enhance your online reputation with our social media reputation management services. We help manage customer reviews, respond to feedback, and ensure that your brand maintains a positive image across all social media platforms.',
        },
        {
          icon: <Campaign />,
          title: 'Social Media Campaigns and Promotions',
          description:
            'Whether you\'re running a product launch, seasonal sale, or brand awareness campaign, we design and execute engaging social media campaigns that generate buzz, increase conversions, and attract potential customers. We ensure that your campaigns are aligned with your business goals and resonate with your audience.',
        },
        {
          icon: <ContactSupport />,
          title: 'Social Media Consultation',
          description:
            'Not sure where to start or need to optimize your current strategy? Our social media consultation services provide expert guidance to improve your social media presence, build brand loyalty, and achieve tangible results. We help you define objectives, choose the right platforms, and create campaigns that drive results.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Deeva Payon is the Best Social Media Marketing Company in India
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Our goal is to make the creation process enjoyable and collaborative:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
          {
            icon: <Verified />,
            title: 'Proven Expertise',
            description:
              'With years of experience, our team of social media experts is equipped with the knowledge and tools to deliver successful campaigns across all major social media platforms.',
          },
          {
            icon: <Tune />,
            title: 'Tailored Social Media Solutions',
            description:
              'We understand that each business is unique, and so are your social media needs. That’s why we offer customized solutions that are perfectly aligned with your brand\'s objectives and target audience.',
          },
          {
            icon: <Palette />,
            title: 'High-Quality Content Creation',
            description:
              'We produce creative, high-quality content that engages and entertains, driving more interaction and building brand loyalty.',
          },
          {
            icon: <BarChart />,
            title: 'Advanced Analytics and Reporting',
            description:
              'We use cutting-edge analytics tools to track your social media performance, refine strategies, and deliver ongoing improvements.',
          },
          {
            icon: <Campaign />,
            title: 'Engaging and Result-Driven Campaigns',
            description:
              'Our campaigns are designed with the sole purpose of increasing brand visibility, generating qualified leads, and maximizing conversions, ensuring the best ROI.',
          },
          {
            icon: <Gavel />,
            title: 'Transparent and Ethical Practices',
            description:
              'We follow ethical marketing practices and ensure complete transparency with our clients, providing clear reports on campaign performance and ROI.',
          },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Social Media Marketing Services Include:
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Our goal is to make the creation process enjoyable and collaborative:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[

[
  {
    icon: <ListAlt />,
    title: 'Social Media Strategy Development',
  },
  {
    icon: <SearchOffOutlined />,
    title: 'Paid Social Media Advertising (PPC)',
  },
  {
    icon: <Edit />,
    title: 'Content Creation and Management',
  },
  {
    icon: <BarChart />,
    title: 'Social Media Analytics and Reporting',
  },
  {
    icon: <Groups />,
    title: 'Community Management',
  },
  {
    icon: <Settings />,
    title: 'Influencer Marketing',
  },
  {
    icon: <Settings />,
    title: 'Social Media Optimization (SMO)',
  },
  {
    icon: <ThumbUp />,
    title: 'Brand Reputation Management',
  },
  {
    icon: <Campaign />,
    title: 'Social Media Campaigns and Promotions',
  },
  {
    icon: <ContactSupport />,
    title: 'Social Media Consultation',
  },
]

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Social Media Marketing Company in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    If you're looking for a social media marketing company in India that can drive results, Deeva Payon is your ideal partner. We help businesses of all sizes build their brand presence, engage with customers, and achieve measurable results through effective social media strategies.

    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Contact us today to discuss how our expert social media marketing services can help you grow your brand and business in the digital world!

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default SocialMediaMarketing;