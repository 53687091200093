// src/pages/SEO.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Verified,
  Gavel,
  Settings,
  LocationOn,
  Edit,
  Description,
  FactCheck,
  Autorenew,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deevapayon, Best SEO company in India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const SEO = () => {
  return (
    <>

<Helmet>
  <title>Best SEO Company in India | Deeva Payon - SEO Services for Growth</title>
  <meta
    name="description"
    content="Deeva Payon is the best SEO company in India, offering comprehensive SEO services such as SEO audit, keyword research, link building, content optimization, and more to drive organic traffic and increase conversions."
  />
  <meta
    name="keywords"
    content="Best SEO company in India, SEO services, Search Engine Optimization services, On-Page SEO company in India, Off-Page SEO company in India, Keyword research services company in India, Local SEO company in India, Link building company in India, Technical SEO company in India, E-commerce SEO company in India"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Best SEO Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              Deeva Payon is the best SEO company in India, dedicated to helping businesses enhance their online visibility, drive organic traffic, and achieve long-term success. With years of experience in the SEO industry, we specialize in providing customized, results-driven Search Engine Optimization services that align with your business goals.
              </Typography>
              <Typography variant="body1" paragraph>
              Our team of expert SEO professionals focuses on delivering high-quality services to ensure your website ranks higher on search engines, bringing valuable leads and improving conversions.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Deevapayon, Best SEO company in India"
                title="Best SEO Company for Businesses India"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deeva Payon as Your SEO Partner?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading SEO company in India, we focus on delivering measurable results that drive real business growth. Here’s why businesses across various industries trust Deeva Payon for their SEO needs:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
      {
        icon: <Verified />,
        title: 'Proven SEO Expertise',
        description:
          'With a proven track record of successful SEO campaigns, we understand the complexities of SEO and have the knowledge and tools to optimize your website for the best possible results.',
      },
      {
        icon: <Tune />,
        title: 'Tailored SEO Strategies',
        description:
          'We don’t believe in a one-size-fits-all approach. Our team customizes SEO strategies based on your business objectives, industry, and target audience, ensuring the best ROI from your SEO efforts.',
      },
      {
        icon: <Gavel />,
        title: 'White-Hat SEO Practices',
        description:
          'We only use ethical and white-hat SEO techniques that comply with search engine guidelines. Our focus is on building sustainable SEO strategies that will deliver long-term success without compromising your website’s reputation.',
      },
      {
        icon: <BarChart />,
        title: 'Data-Driven Results',
        description:
          'We use data and analytics to measure SEO performance, continuously optimizing campaigns to improve rankings, increase traffic, and maximize conversions. Our data-driven approach helps ensure you get the best results possible.',
      },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our SEO Services
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
      {
        icon: <FactCheck />,
        title: 'Search Engine Optimization (SEO) Audit',
        description:
          'Before starting any SEO campaign, we conduct a thorough SEO audit to identify areas of improvement and ensure your website is optimized for search engines. Our audit process includes evaluating your website’s structure, content, backlinks, and user experience, allowing us to create a comprehensive strategy to improve your rankings.',
      },
      {
        icon: <Description />,
        title: 'On-Page SEO',
        description:
          'Our On-Page SEO services focus on optimizing individual web pages to improve their rankings. This includes keyword research, content optimization, meta tags, internal linking, and enhancing your website’s overall user experience. By optimizing your website’s on-page elements, we ensure it is search-engine friendly and engaging for users.',
      },
      {
        icon: <Link />,
        title: 'Off-Page SEO',
        description:
          'Off-page SEO involves building backlinks from high-authority websites to improve your website’s domain authority and trustworthiness. We provide expert Off-Page SEO services, including link-building, guest posting, and outreach campaigns, that drive valuable backlinks and improve your site’s search engine rankings.',
      },
      {
        icon: <Search />,
        title: 'Keyword Research & Strategy',
        description:
          'Effective keyword research is the foundation of a successful SEO campaign. Our keyword research services ensure that your website targets the right keywords that align with your audience’s search intent. We develop a detailed keyword strategy to help you rank for high-traffic, relevant keywords that will drive targeted visitors to your site.',
      },
      {
        icon: <Edit />,
        title: 'Content Optimization',
        description:
          'Quality content is key to both user engagement and SEO performance. Our content optimization services include optimizing your existing content and creating fresh, engaging content that’s designed to rank on search engines. We focus on creating high-quality, informative content that appeals to both your audience and search engines.',
      },
      {
        icon: <LocationOn />,
        title: 'Local SEO',
        description:
          'If you are a local business looking to reach customers in your area, our local SEO services will help you rank in local search results. We optimize your Google My Business profile, create local citations, and focus on location-based keywords to ensure your business is visible to customers in your region.',
      },
      {
        icon: <Link />,
        title: 'SEO Link Building',
        description:
          'Link building is a critical component of SEO success. Our link-building services focus on acquiring high-quality backlinks from authoritative sources to boost your website’s trustworthiness and rankings. We use white-hat methods to ensure the backlinks we acquire are beneficial and sustainable.',
      },
      {
        icon: <BarChart />,
        title: 'SEO Reporting & Analytics',
        description:
          'We provide comprehensive SEO reports and analytics that track your website’s performance. Our SEO reporting services allow you to see the progress of your SEO campaign, including traffic growth, keyword rankings, and other key metrics. We use this data to optimize strategies and continually improve your SEO performance.',
      },
      {
        icon: <Settings />,
        title: 'Technical SEO',
        description:
          'Technical SEO involves optimizing the backend of your website to ensure it meets search engine requirements. Our technical SEO services include improving site speed, mobile optimization, fixing broken links, enhancing site security, and ensuring your site’s technical elements are fully optimized for search engines.',
      },
      {
        icon: <ShoppingCart />,
        title: 'E-commerce SEO',
        description:
          'For e-commerce businesses, our E-commerce SEO services help optimize product pages, categories, and the overall site structure for higher visibility in search engines. We focus on driving targeted traffic that leads to conversions, ensuring your online store is optimized for search engine success.',
      },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>


<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why We Are the Best SEO Company in India
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[

      {
        icon: <Groups />,
        title: 'Experienced SEO Professionals',
        description:
          'At Deeva Payon, our team consists of highly skilled and experienced SEO professionals who are up-to-date with the latest SEO trends and algorithms. We bring extensive expertise to your SEO campaign, ensuring the best results for your business.',
      },
      {
        icon: <Verified />,
        title: 'Proven Track Record',
        description:
          'With years of experience and a history of successful SEO campaigns, we have earned a reputation as one of the best SEO companies in India. Our clients trust us to deliver high-quality, effective SEO services that drive tangible results.',
      },
      {
        icon: <Tune />,
        title: 'Customized SEO Strategies',
        description:
          'We understand that every business is different. Our SEO services are tailored to your specific needs, ensuring that your SEO campaign aligns with your business goals and objectives. Our personalized approach guarantees the best ROI.',
      },
      {
        icon: <Gavel />,
        title: 'White-Hat SEO Techniques',
        description:
          'We follow ethical, white-hat SEO practices to ensure long-term, sustainable results. Our team focuses on building a strong foundation for your website’s SEO success without risking penalties from search engines.',
      },
      {
        icon: <Autorenew />,
        title: 'Continuous Optimization',
        description:
          'SEO is an ongoing process. We continuously monitor, analyze, and optimize your SEO campaigns to ensure they are delivering the best possible results. Our team is always looking for new opportunities to improve your rankings and drive more traffic.',
      },
      {
        icon: <Savings />,
        title: 'Affordable SEO Services',
        description:
          'As the best SEO company in India, we offer competitive pricing without compromising on quality. Our SEO services are designed to deliver excellent value for your investment, helping businesses of all sizes achieve their SEO goals.',
      },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best SEO Company in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    If you’re looking to improve your website’s search engine rankings and drive organic traffic, Deeva Payon is the best SEO company in India to help you achieve your goals. Contact us today to discuss how our SEO services can boost your online presence and help you grow your business.

    </Typography>

    <Typography variant="body1" align="center" paragraph>
    Let us create a customized SEO strategy that will improve your website’s visibility and drive sustainable growth for your business!
    

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default SEO;