// src/pages/ContentMarketing.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  ListAlt,
  Verified,
  Edit,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Content Marketing Company in India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ContentMarketing = () => {
  return (
    <>

<Helmet>
      <title>Content Marketing Company in India | Deevapayon</title>
      <meta
        name="description"
        content="Looking for a top content marketing company in India? Deevapayon offers customized strategies, SEO content, and creative solutions to enhance your brand's presence."
      />
      <meta
        name="keywords"
        content="Content marketing company in India, Content marketing services, SEO content strategy, Creative content marketing, Digital marketing solutions, Content creation services, Brand awareness strategies, Content promotion and distribution"
      />
    </Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Content marketing company in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, We are a leading content marketing company in India, offering custom-tailored solutions to help brands effectively communicate with their audience. We combine creative storytelling, data-driven strategies, and advanced SEO practices to ensure your business reaches its full potential online.
              </Typography>
              <Typography variant="body1" paragraph>
              Whether you’re looking to improve brand awareness, engage customers, or drive conversions, our content marketing services are designed to help you achieve measurable success in the digital space.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Content Marketing Services by Deevapayon  - India"
                title="Content Marketing Company in India - Deevapayon"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a content marketing company in India, we understand the vital role content plays in modern marketing strategies. Our approach is a perfect blend of creativity, precision, and performance, ensuring your brand consistently stands out. Here’s why clients prefer working with us:
    </Typography> */}

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <Tune />,
              title: 'Personalized Content Strategies',
              description:
                'We don’t believe in a one-size-fits-all approach. Instead, we craft unique content strategies tailored to your business goals, audience preferences, and industry dynamics. This ensures that every piece of content resonates deeply and drives real results.',
            },
            {
              icon: <Search />,
              title: 'SEO-Driven Content Creation',
              description:
                'As an expert content marketing company in India, we prioritize SEO in all our content creation. From keyword research to on-page optimization, our content is designed to rank well on search engines and attract organic traffic.',
            },
            {
              icon: <Palette />,
              title: 'Creative, Engaging Content',
              description:
                'We believe in producing content that captivates and converts. Our team excels in crafting diverse content formats—blog posts, articles, videos, infographics, and more—that not only inform but engage and motivate your target audience.',
            },
            {
              icon: <ListAlt />,
              title: 'End-to-End Content Services',
              description:
                'Whether it’s blog writing, website copy, social media posts, or case studies, we offer a complete suite of content marketing services. Our holistic approach ensures consistency across all your digital channels.',
            },
            {
              icon: <Verified />,
              title: 'Proven Success',
              description:
                'With years of experience as a content marketing company in India, we have a track record of helping businesses grow through strategically crafted content that drives engagement, conversions, and brand loyalty.',
            },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Content Marketing Services
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Tune />,
          title: 'Tailored Content Strategy',
          description:
            'We begin by understanding your business goals, market trends, and audience behavior to develop a content marketing strategy that aligns perfectly with your objectives. Our strategies are designed to yield measurable results and give your brand a competitive edge.',
        },
        {
          icon: <Edit />,
          title: 'High-Quality Content Creation',
          description:
            'Our skilled team of writers, designers, and strategists produce high-quality, engaging content across various formats. From blogs to infographics, we ensure your content stands out and connects with your audience effectively.',
        },
        {
          icon: <Search />,
          title: 'SEO-Optimized Content',
          description:
            'At [Your Company Name], we prioritize SEO to ensure your content ranks well on search engines. Through keyword optimization, technical SEO, and high-quality content creation, we help you improve visibility and drive more organic traffic.',
        },
        {
          icon: <Share />,
          title: 'Content Distribution & Promotion',
          description:
            'Great content is meaningless without effective promotion. We help distribute your content across relevant channels, including social media, email, and blogs, to ensure it reaches the right audience and generates engagement.',
        },
        {
          icon: <BarChart />,
          title: 'Analytics & Performance Tracking',
          description:
            'We believe in data-driven results. Our team uses advanced analytics to track the performance of your content marketing campaigns, providing actionable insights that help optimize future content strategies.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Why Content Marketing Matters for Your Business
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Effective content marketing builds trust, authority, and recognition for your brand. It is essential for attracting new customers, nurturing relationships, and increasing sales. As a leading content marketing company in India, we ensure your content strategy is aligned with your business goals and industry trends. With the right content marketing partner, your business can stand out, engage the right audience, and generate higher returns on investment.

    </Typography>

    <Typography variant="h4" align="center" gutterBottom>
    Get in Touch with the Leading Content Marketing Company in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Is your brand ready for an effective content marketing campaign? Whether you're a startup or an established business, Deevapayon is here to help you grow. Reach out to us to discuss how we can design a customized content strategy that will elevate your brand's digital presence.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default ContentMarketing;