// src/pages/OnlineReputationManagement.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Verified,
  Shield,
  Visibility,
  FactCheck,
  Delete,
  Star,
  Gavel,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Online Reputation Management Agency in India - Deevapayon.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const OnlineReputationManagement = () => {
  return (
    <>

<Helmet>
  <title>Online Reputation Management Agency in India | Deevapayon</title>
  <meta name="description" content="Looking for a reliable online reputation management agency in India? Deevapayon specializes in protecting, improving, and managing your brand's online reputation." />
  <meta name="keywords" content="Online reputation management agency in India, Reputation management services, Online brand reputation management, Negative content removal, Reputation crisis management, SEO for reputation management, Brand monitoring and protection, Online reputation repair services, Reputation management experts" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Online Reputation Management Agency in India
              </Typography>
              <Typography variant="body1" paragraph>
              In today’s digital world, a brand’s online reputation is crucial. As a leading online reputation management agency in India, Deevapayon specializes in protecting and improving your online image. We help businesses and individuals manage negative content, build a positive reputation, and establish a trustworthy online presence.
              </Typography>
              <Typography variant="body1" paragraph>
              With our expert strategies, we ensure your brand maintains a strong reputation, enabling you to succeed in a competitive online environment and build lasting credibility.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Online Reputation Management Services by Deevapayon - India"
                title="Online Reputation Management Agency in India - Deevapayon"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us as Your Online Reputation Management Agency in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      At Deevapayon, we understand the critical importance of maintaining a strong and positive online reputation. As a leading online reputation management agency in India, we offer tailored solutions that protect your digital presence and enhance your brand’s image. Here’s why we stand out:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Verified />,
      title: 'Proven Track Record',
      description:
        'With years of experience, we have successfully helped countless businesses and individuals enhance their online reputation and regain control over their digital presence.',
    },
    {
      icon: <Tune />,
      title: 'Customized Reputation Strategies',
      description:
        'Every brand and individual is unique. We create customized reputation management strategies that align with your specific goals, ensuring a positive impact on your online presence.',
    },
    {
      icon: <Shield />,
      title: 'Expert Crisis Management',
      description:
        'Negative reviews, social media attacks, or unwanted content? Our team is equipped to handle crises swiftly, working to minimize the damage and restore your reputation.',
    },
    {
      icon: <Visibility />,
      title: 'Comprehensive Monitoring',
      description:
        'We constantly monitor your online presence, identifying potential threats and addressing them before they escalate into bigger issues. Our proactive approach ensures that your reputation stays protected.',
    },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Online Reputation Management Services
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <FactCheck />,
          title: 'Reputation Analysis & Audit',
          description:
            'We start by analyzing your current online reputation, identifying any negative content or areas of concern. Through a comprehensive audit, we understand the scope of the issue and develop a strategy that addresses these concerns effectively.',
        },
        {
          icon: <Delete />,
          title: 'Negative Content Removal & Suppression',
          description:
            'Negative content can harm your brand’s image and trustworthiness. We work on removing harmful content and suppressing it on search engine results, replacing it with positive content that showcases your brand in a favorable light.',
        },
        {
          icon: <Visibility />,
          title: 'Brand Monitoring & Social Listening',
          description:
            'Our team monitors social media platforms, blogs, review sites, and forums to track any mentions of your brand. With real-time alerts, we can address any emerging issues or negative content immediately, preventing any lasting damage to your reputation.',
        },
        {
          icon: <Star />,
          title: 'Review Management & Enhancement',
          description:
            'Customer reviews play a significant role in shaping your brand’s reputation. We help businesses manage online reviews, encouraging positive feedback from happy customers while mitigating the impact of any negative reviews. Our team will guide you through the process of improving your review scores across multiple platforms.',
        },
        {
          icon: <Search />,
          title: 'Search Engine Optimization (SEO) for Reputation',
          description:
            'By using advanced SEO techniques, we ensure that positive content ranks higher on search engines, pushing negative content down in search results. This improves your brand’s visibility in a way that reflects your reputation accurately.',
        },
        {
          icon: <Gavel />,
          title: 'Crisis Management & PR Services',
          description:
            'In times of crisis, our expert team takes swift action to handle any PR challenges, ensuring that negative press doesn’t spiral out of control. We implement immediate reputation management tactics to protect your brand’s image and manage the fallout effectively.',
        },
        {
          icon: <Share />,
          title: 'Social Media Reputation Management',
          description:
            'Social media platforms can have a major impact on your reputation. We help you build a strong social media presence by creating positive content, engaging with your audience, and addressing any negative feedback in a constructive manner.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Online Reputation Management is Crucial for Your Business
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      A positive online reputation builds trust with your audience and helps you stand out from competitors. Here’s why it’s so important:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Verified />,
          title: 'Builds Credibility',
          description:
            'A strong online reputation is key to establishing credibility. Customers are more likely to trust a brand that has positive reviews and a strong digital presence.',
        },
        {
          icon: <Groups />,
          title: 'Attracts More Customers',
          description:
            'A good reputation means more customers and more business opportunities. Consumers are more likely to choose a brand with a positive online image.',
        },
        {
          icon: <Shield />,
          title: 'Minimizes Negative Impact',
          description:
            'Negative content can damage a brand’s reputation quickly. Online reputation management prevents the spread of false or harmful information, protecting your brand from potential harm.',
        },
        {
          icon: <TrendingUp />,
          title: 'Enhances Brand Value',
          description:
            'Your online reputation is a direct reflection of your brand’s value. By maintaining a positive image, you increase your brand\'s worth and appeal in the market.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Why You Need an Online Reputation Management Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    With the growing importance of digital platforms, the need for reputation management has never been more crucial. Whether you're a startup, an established business, or an individual, a strong online presence can influence customer decisions. At Deevapayon, we offer expert services that help protect and improve your online reputation, giving you the peace of mind to focus on growing your business.

    </Typography>

    <Typography variant="h4" align="center" gutterBottom>
    Partner with Deevapayon  – Leading Online Reputation Management Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Ready to take control of your online reputation? Let Deevapayon help you create and maintain a positive online image that supports your growth and success. As the top online reputation management agency in India, we specialize in handling both proactive and reactive strategies to keep your reputation intact.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default OnlineReputationManagement;