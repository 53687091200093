// src/pages/DigitalMarketing.js
import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CheckCircleOutline,
  Code,
  PhoneIphone,
  Web,
  Cloud,
  People,
  ShoppingCart,
  Api,
  DirectionsBus,
  Bolt,
  WaterDrop,
  LocalFireDepartment,
  Toll,
  CreditCard,
  Fingerprint,
  AccountBalance,
  Paid,
  School,
  FlashOn,
  Campaign,
  Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Digital marketing agency in India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';

const DigitalMarketing = () => {
  return (
    <>
      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Digital Marketing Agency in India
              </Typography>
              <Typography variant="body1" paragraph>
              Deeva Payon is a leading digital marketing agency in India, offering innovative and results-driven strategies to help businesses thrive in the digital world. As a trusted partner in your digital marketing journey, we specialize in providing customized solutions that drive traffic, increase conversions, and boost brand visibility.
              </Typography>
              <Typography variant="body1" paragraph>
              Whether you’re looking to enhance your online presence or grow your sales, our expert team is committed to providing comprehensive digital marketing services that are designed to meet your unique business goals.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Digital marketing agency in India"
                title=" Expert Digital Marketing Services in India - Deeva Payon"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deeva Payon as Your Digital Marketing Agency in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading digital marketing agency in India, we focus on delivering measurable results that align with your business goals. Our team of experienced marketers, analysts, and creatives work collaboratively to develop strategies that ensure your brand stands out in today’s competitive online marketplace. Here’s why businesses trust us with their digital marketing needs:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Campaign />,
          title: 'Comprehensive Digital Marketing Solutions',
          description:
            'We provide a full suite of digital marketing services, ranging from SEO and PPC to social media marketing and email campaigns.',
        },
        {
          icon: <SettingsApplications  />,
          title: 'Tailored Strategies',
          description:
            'Our digital marketing services are customized to your goals, ensuring you get the best return on investment (ROI).',
        },
        {
          icon: <TrendingUp />,
          title: 'Results-Driven Approach',
          description:
            'Our goal is to help you generate leads, increase traffic, and maximize sales through expert digital marketing techniques.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Digital Marketing Services
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '3' }}
    >
      As a Fintech Software company in India, we pride ourselves on delivering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Search />,
          title: 'Search Engine Optimization (SEO)',
          description:
            'Our SEO services improve your website’s visibility on search engines, driving organic traffic and increasing rankings through advanced techniques.',
        },
        {
          icon: <Paid />,
          title: 'Pay-Per-Click Advertising (PPC)',
          description:
            'Generate immediate traffic and results with our PPC services, optimizing campaigns for platforms like Google Ads and social media.',
        },
        {
          icon: <Share />,
          title: 'Social Media Marketing (SMM)',
          description:
            'Connect with your audience across platforms like Facebook, Instagram, and LinkedIn through engaging content and targeted campaigns.',
        },
        {
          icon: <Article />,
          title: 'Content Marketing',
          description:
            'Create high-quality, engaging content such as blogs, articles, and videos to drive traffic, establish authority, and build trust.',
        },
        {
          icon: <Email />,
          title: 'Email Marketing',
          description:
            'Nurture leads and engage customers with personalized email campaigns optimized for higher open rates and conversions.',
        },
        {
          icon: <Groups  />,
          title: 'Affiliate Marketing',
          description:
            'Leverage third-party affiliates to promote your products or services, driving traffic and sales through strategic partnerships.',
        },
        {
          icon: <ThumbUp />,
          title: 'Online Reputation Management (ORM)',
          description:
            'Monitor and protect your online reputation by managing reviews, handling negative comments, and maintaining a positive brand image.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
      sx={{
        padding: { xs: '4rem 0', md: '3rem 0' },
        backgroundColor: '#f4f6f8', // Light gray background
      }}
    >
      <Container maxWidth="lg">
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#fff", // White text color
            background: "#1976d2", // Blue background
            borderRadius: "16px", // Rounded corners
            padding: "10px 20px", // Padding for better spacing
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
            textTransform: "uppercase", // Uppercase text
            letterSpacing: "2px", // Spacing between letters
            display: "flex", // Use flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            margin: "0 auto", // Center the box itself
            maxWidth: "fit-content", // Ensure the box only wraps the content
            marginBottom: '2rem',
          }}
        >
          Why We Are the Best Digital Marketing Agency in India
        </Typography>

        {/* Features Grid */}
        <Grid container spacing={6}>
          {[
            {
              icon: <People />,
              title: 'Expert Team',
              description:
                'Our team consists of experienced digital marketing professionals with expertise in SEO, PPC, social media, content, and more.',
            },
            {
              icon: <Tune />,
              title: 'Tailored Solutions',
              description:
                'Our digital marketing services are tailored to meet your specific needs and goals, ensuring measurable results.',
            },
            {
              icon: <Assessment />,
              title: 'Data-Driven Results',
              description:
                'We use data and analytics to measure success and ensure the best possible results from your investment.',
            },
            {
              icon: <Savings />,
              title: 'Affordable Services',
              description:
                'We deliver high-quality results at competitive prices, making digital marketing accessible for businesses of all sizes.',
            },
            {
              icon: <IntegrationInstructions />,
              title: 'Comprehensive Approach',
              description:
                'We integrate SEO, PPC, social media, content, and email marketing into one cohesive strategy for maximum impact.',
            },
            {
              icon: <VerifiedUser />,
              title: 'Proven Track Record',
              description:
                'We have a proven track record of helping businesses grow their online presence and achieve their marketing goals.',
            },

          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  backgroundColor: '#ffffff', // White card background
                  borderRadius: '12px', // Rounded corners
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
                  padding: '1.5rem', // Internal padding
                  transition: 'transform 0.3s ease', // Smooth hover effect
                  '&:hover': {
                    transform: 'translateY(-5px)', // Lift on hover
                  },
                  textAlign: 'center', // Center-align content
                }}
              >
                {/* Icon */}
                <Avatar
                  sx={{
                    backgroundColor: '#1976d2', // Blue background
                    width: '50px',
                    height: '50px',
                    margin: '0 auto 1rem', // Centered with spacing below
                  }}
                >
                  {feature.icon}
                </Avatar>

                {/* Title */}
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: '600', color: '#333' }}
                >
                  {feature.title}
                </Typography>

                {/* Description */}
                <Typography
                  variant="body2"
                  sx={{ color: '#555', lineHeight: '1.8' }}
                >
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Digital Marketing Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    If you're looking to enhance your online presence and achieve tangible results, Deeva Payon is your ideal digital marketing agency in India. Our expert team is here to help you drive traffic, increase conversions, and build a strong brand presence online.
Contact us today to discuss your goals, and let’s create a customized digital marketing strategy that will elevate your business to new heights!

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default DigitalMarketing;