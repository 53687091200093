// src/pages/PresentationDesign.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Verified,
  CorporateFare,
  RocketLaunch,
  Keyboard,
  DesignServices,
  MonetizationOn,
  Chat,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Presentation Design.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PresentationDesign = () => {
  return (
    <>

<Helmet>
  <title>Professional Presentation Design Services | Custom PowerPoint & Keynote Designs</title>
  <meta name="description" content="Looking for top-notch presentation design services? We specialize in custom PowerPoint, Keynote, and business presentations. Create engaging, visually stunning presentations that captivate your audience and communicate your message effectively." />
  <meta name="keywords" content="Presentation Design, Custom Presentation Design, Business Presentation Design, Sales Pitch Presentation Design, PowerPoint Presentation Design, Keynote Presentation Design, Investor Presentation Design, Pitch Deck Design, Corporate Presentation Design, Educational Presentation Design" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Presentation Design | Professional & Creative Presentation Design Services
              </Typography>
              <Typography variant="body1" paragraph>
              In the world of business, how you present your ideas is just as important as the ideas themselves. Presentation design plays a crucial role in ensuring your message is communicated clearly, effectively, and professionally.
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we specialize in crafting visually engaging and impactful presentations that capture your audience's attention and leave a lasting impression.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                // alt="Graphic design services by a leading graphic design company in India"
                // title="Creative Graphic Design Solutions by Deevapayon - Expert Designers in India"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us for Your Presentation Design?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      At Deevapayon, we understand that a great presentation design is more than just slides with text and images. It’s about creating a visually appealing narrative that enhances your message and keeps your audience engaged. Our team of experienced designers ensures every aspect of your presentation is polished, from layout and typography to color schemes and animations.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <Tune />,
              title: 'Custom Designs',
              description:
                'Every presentation we design is tailored to your brand, message, and audience.',
            },
            {
              icon: <Palette />,
              title: 'Engaging Visuals',
              description:
                'We use graphics, icons, charts, and images to make your presentation visually appealing and easy to understand.',
            },
            {
              icon: <Verified />,
              title: 'Professionalism',
              description:
                'We focus on delivering presentations that reflect the professionalism and credibility of your business or personal brand.',
            },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Presentation Design Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer a wide range of presentation design services to help you create the perfect presentation for any occasion, whether it's for business meetings, conferences, sales pitches, or educational purposes. Here's how we can assist:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Business />,
      title: 'Business Presentation Design',
      description:
        'A well-designed business presentation is essential for showcasing your company, pitching ideas, and communicating effectively with stakeholders. Our business presentation design services are aimed at creating professional, polished presentations that align with your brand and message.',
    },
    {
      icon: <MonetizationOn />,
      title: 'Sales Pitch Presentation Design',
      description:
        'When you\'re presenting to potential clients or investors, first impressions matter. Our sales pitch presentation design services help you create compelling, persuasive presentations that highlight the key benefits of your products or services and effectively close the deal.',
    },
    {
      icon: <CorporateFare />,
      title: 'Corporate Presentation Design',
      description:
        'A corporate presentation reflects your company’s values, culture, and expertise. We offer corporate presentation design services to create presentations that match your corporate identity and deliver your message in a clear, engaging manner.',
    },
    {
      icon: <Assessment />,
      title: 'Investor Presentation Design',
      description:
        'An investor presentation is crucial for gaining trust and securing funding. Our investor presentation design services help you craft persuasive, data-driven slides that highlight your business model, growth potential, and investment opportunities.',
    },
    {
      icon: <RocketLaunch />,
      title: 'Pitch Deck Design',
      description:
        'A pitch deck is a vital tool for startups and entrepreneurs looking to secure funding. Our pitch deck design services are focused on creating visually appealing, concise, and persuasive slides that capture the attention of investors and stakeholders.',
    },
    {
      icon: <Slideshow />,
      title: 'PowerPoint Presentation Design',
      description:
        'PowerPoint is one of the most widely used platforms for creating presentations. Our PowerPoint presentation design services ensure your slides are visually striking, easy to follow, and fully aligned with your goals.',
    },
    {
      icon: <Keyboard />,
      title: 'Keynote Presentation Design',
      description:
        'For Apple users, Keynote presentations are an excellent option for creating sleek, visually captivating presentations. Our Keynote presentation design services ensure your slides are beautifully designed and optimized for your specific needs.',
    },
    {
      icon: <School />,
      title: 'Training and Educational Presentation Design',
      description:
        'Whether for corporate training or educational seminars, a well-designed presentation can enhance the learning experience. Our training and educational presentation design services help you deliver information effectively with clear visuals, diagrams, and easy-to-read slides.',
    },
    {
      icon: <Groups />,
      title: 'Conference Presentation Design',
      description:
        'When you’re presenting at a conference, you need to stand out from the crowd. Our conference presentation design services are aimed at creating slides that are both informative and visually dynamic, ensuring your audience stays engaged.',
    },
    {
      icon: <DesignServices />,
      title: 'Custom Presentation Design',
      description:
        'If you have a specific need or unique project, we offer custom presentation design services. Whether it\'s for a product launch, seminar, or internal report, our team will tailor the design to suit your objectives and audience.',
    },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Invest in Professional Presentation Design?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      An impactful presentation can significantly enhance how your ideas are perceived. Professional presentation design does more than just present information – it makes your message more compelling, memorable, and impactful. Whether it’s for internal team meetings or external client pitches, a well-designed presentation ensures you convey professionalism and credibility.
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Chat />,
      title: 'Improved Communication',
      description:
        'Great design helps to break down complex information into digestible visuals, making your message clearer and more effective.',
    },
    {
      icon: <ThumbUp />,
      title: 'Enhanced Engagement',
      description:
        'Stunning visuals can keep your audience engaged, ensuring they remain focused throughout the presentation.',
    },
    {
      icon: <Verified />,
      title: 'Professional Appeal',
      description:
        'A polished presentation enhances your credibility and builds trust with your audience.',
    },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started Today!
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Are you looking for a presentation design service that can elevate your next meeting, pitch, or corporate event? Look no further than Deevapayon. Our team of expert designers is ready to help you create a presentation that is not only visually stunning but also communicates your message effectively. Contact us today to get started!

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default PresentationDesign;