// src/pages/MobileRecharge.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Label,
  SettingsSuggest,
  EmojiPeople,
  Shield,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deevapayon, Mobile Recharge Software.jpg';
import CallToAction from './CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const MobileRecharge = () => {
  return (
    <>

<Helmet>
  <title>Mobile Recharge Software Solution Company in India | Deeva Payon</title>
  <meta
    name="description"
    content="Leading mobile recharge software solution company in India, offering multi-recharge, B2B/B2C solutions, API integration, white-label software & 24/7 support."
  />
  <meta
    name="keywords"
    content="Mobile Recharge Software, Mobile Recharge Software Solution Company in India, B2B Mobile Recharge Software, B2C Mobile Recharge Software, Multi Recharge Software, White Label Recharge Software, Mobile Recharge API, Recharge Solutions"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Mobile Recharge Software Solution Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              In today’s fast-paced digital world, mobile recharge software is essential for businesses and individuals. As a leading provider of innovative solutions, we specialize in software that simplifies and streamlines mobile, data card, and utility bill recharges. Our solutions cater to the evolving market needs, ensuring clients stay ahead of the curve.
              </Typography>
              <Typography variant="body1" paragraph>
              Focusing on convenience, reliability, and user experience, our software empowers businesses to offer seamless recharge services. It supports managing multiple recharge services and integrating with various payment gateways, providing a comprehensive recharge platform.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Mobile Recharge Software for Seamless Recharge Services"
                title="Mobile Recharge Software for Efficient and Reliable Recharge Solutions"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Mobile Recharge Software Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Our comprehensive suite of services is designed to cater to diverse needs, offering a wide range of solutions that are both flexible and scalable. This includes:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
            icon: <PhoneIphone />,
            title: 'Multi Recharge Software',
            description:
              'Our software allows you to manage multiple recharge services from a single platform, making it easier to handle prepaid and postpaid mobile recharges, data cards, and more.',
          },
          {
            icon: <Business />,
            title: 'B2B and B2C Solutions',
            description:
              'We offer tailored solutions for both businesses and consumers, ensuring seamless transactions and user-friendly interfaces.',
          },
          {
            icon: <Api />,
            title: 'Mobile Recharge API',
            description:
              'Our Mobile recharge API is designed to integrate with various payment gateways, providing customers with a wide range of payment options.',
          },
          {
            icon: <Label />,
            title: 'White Label Recharge Software',
            description:
              'We provide customizable white label Recharge software that can be branded with your company logo and design, helping you maintain a unique identity in the market.',
          },
          {
            icon: <SupportAgent />,
            title: '24/7 Technical Support',
            description:
              'Our dedicated support team is always available to assist with any queries or technical issues, ensuring uninterrupted service.',
          },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Benefits of Our Mobile Recharge Software
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
        {
            icon: <SettingsSuggest />,
            title: 'Efficient Management',
            description:
              'Our software automates the recharge process, reducing manual errors and increasing efficiency.',
          },
          {
            icon: <EmojiPeople />,
            title: 'Enhanced Customer Experience',
            description:
              'With our user-friendly platforms, customers can easily recharge their mobiles and pay bills from anywhere, at any time.',
          },
          {
            icon: <TrendingUp />,
            title: 'Scalability',
            description:
              'Our solutions are scalable, allowing your business to grow without limitations.',
          },
          {
            icon: <Shield />,
            title: 'Security',
            description:
              'We prioritize security, ensuring all transactions are safe and secure.',
          },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Mobile Recharge Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    You can transform your mobile recharge services into a seamless, secure, and user-centric experience by leveraging our expertise. Whether you're a business looking to expand your offerings or an individual seeking a hassle-free recharge solution, we have the technology to meet your needs as a mobile recharge software solution company in India.


    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default MobileRecharge;