// src/pages/InfographicsDesign.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Timeline,
  ShowChart,
  TouchApp,
  DesignServices,
  DateRange,
  Compare,
  Lightbulb,
  Visibility,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/infographics design.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const InfographicsDesign = () => {
  return (
    <>

<Helmet>
  <title>Infographics Design Services | Custom & Creative Infographic Designs</title>
  <meta name="description" content="Looking for professional infographics design services? We create custom, data-driven, and visually engaging infographics to help businesses communicate complex information effectively. Contact us to create impactful infographics." />
  <meta name="keywords" content="Infographics Design, Custom Infographics Design, Data Infographics, Process Infographics, Business Infographics Design, Timeline Infographics Design, Statistical Infographics Design, Marketing Infographics Design, Interactive Infographics, Infographic Design Services" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Infographics Design | Create Engaging Visual Content with Infographics
              </Typography>
              <Typography variant="body1" paragraph>
              In a world where attention spans are shrinking, infographics design is a powerful tool to communicate complex information in a visually appealing and easy-to-understand format.
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we specialize in creating high-quality, custom infographics design that helps businesses simplify their messages, engage their audience, and enhance content marketing efforts.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                // alt="Graphic design services by a leading graphic design company in India"
                // title="Creative Graphic Design Solutions by Deevapayon - Expert Designers in India"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us for Your Infographics Design?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Infographics are one of the most effective ways to present information in a visually compelling manner. Whether you need to explain data, convey a story, or highlight key points, our expert designers at Deevapayon create infographics that are both informative and visually attractive.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    
        {
          icon: <Tune />,
          title: 'Custom Designs',
          description:
            'Every infographic we create is tailor-made to suit your brand identity and the message you wish to convey.',
        },
        {
          icon: <BarChart />,
          title: 'Data-Driven Visuals',
          description:
            'We specialize in transforming complex data into easy-to-understand visuals that tell your story in a clear, engaging way.',
        },
        {
          icon: <Groups />,
          title: 'Professional Design Team',
          description:
            'Our experienced designers ensure your infographics are polished, informative, and visually striking.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Infographics Design Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer a comprehensive range of infographics design services to help your business communicate effectively, inform your audience, and make a lasting impact. Here’s how we can assist you:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <BarChart />,
          title: 'Data Infographics Design',
          description:
            'Our data infographics design services transform complex data and statistics into easy-to-digest visuals. From charts and graphs to timelines and comparisons, we help you present data in a way that engages and educates your audience.',
        },
        {
          icon: <Business />,
          title: 'Business Infographics Design',
          description:
            'Whether it’s for presentations, reports, or marketing materials, our business infographics design services help companies clearly showcase their services, processes, and achievements. These designs are crafted to increase comprehension and drive business success.',
        },
        {
          icon: <Timeline />,
          title: 'Process Infographics Design',
          description:
            'Process infographics break down workflows, procedures, or step-by-step guides. Our process infographics design services make intricate processes simple to follow, whether you’re explaining a business process or offering a how-to guide.',
        },
        {
          icon: <Compare />,
          title: 'Comparison Infographics Design',
          description:
            'Comparison infographics are a great way to highlight the differences or similarities between products, services, or concepts. We create comparison infographics design that clearly showcase features, benefits, and key distinctions to help your audience make informed decisions.',
        },
        {
          icon: <DateRange />,
          title: 'Timeline Infographics Design',
          description:
            'A timeline infographic visually represents events, milestones, or projects over time. Our timeline infographics design services are perfect for showcasing historical events, project progress, or the evolution of a product or service.',
        },
        {
          icon: <ShowChart />,
          title: 'Statistical Infographics Design',
          description:
            'Statistical infographics present numerical data in an engaging and easy-to-understand format. We specialize in designing infographics that make statistics compelling and shareable across social media and other platforms.',
        },
        {
          icon: <Campaign />,
          title: 'Marketing Infographics Design',
          description:
            'In today’s competitive market, effective marketing strategies are crucial. Our marketing infographics design services help communicate marketing strategies, campaign results, and consumer insights in a visually engaging format.',
        },
        {
          icon: <TouchApp />,
          title: 'Interactive Infographics Design',
          description:
            'Bring your infographics to life with interactive designs. Our interactive infographics design services allow you to create dynamic, clickable infographics that engage your audience in a more immersive way.',
        },
        {
          icon: <Share />,
          title: 'Infographic for Social Media',
          description:
            'Infographics are perfect for social media engagement. Our social media infographics design services create visually appealing and shareable infographics that resonate with your followers and drive social media interaction.',
        },
        {
          icon: <DesignServices />,
          title: 'Custom Infographics Design',
          description:
            'If you have a unique project or concept, we offer custom infographics design services tailored to your specific needs. Whether it\'s a promotional infographic, educational piece, or an internal business document, we’ll create a custom design to suit your vision.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Benefits of Infographics Design
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Infographics are more than just visually appealing; they offer tangible benefits that can boost your content marketing efforts:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Lightbulb />,
          title: 'Enhanced Understanding',
          description:
            'Infographics simplify complex topics, making them easy to grasp for your audience.',
        },
        {
          icon: <ThumbUp />,
          title: 'Improved Engagement',
          description:
            'Visual content is far more engaging than text-heavy information, leading to higher interaction and shares.',
        },
        {
          icon: <Visibility />,
          title: 'Increased Brand Awareness',
          description:
            'Consistent, branded infographics help build recognition and trust with your audience.',
        },
        {
          icon: <Search />,
          title: 'Boosted SEO',
          description:
            'Infographics are highly shareable, which can increase backlinks and enhance your website’s SEO.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Why Invest in Infographics Design?
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Infographics are a great way to break down complicated concepts into digestible, visual pieces that leave a lasting impression. Whether you're trying to explain a process, present data, or tell a story, infographics design is a powerful tool that can capture attention and communicate effectively.

    </Typography>

    <Typography variant="h4" align="center" gutterBottom>
    Get Started Today!
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    If you’re looking for a creative, professional infographics design company to help convey your message, look no further than [Your Company Name]. Our experienced team is ready to create visually striking infographics that communicate your ideas, engage your audience, and enhance your brand presence. Reach out to us today for a consultation!


    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default InfographicsDesign;