// src/pages/CloudSolutions.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import {Web, Cloud, People,
  SupportAgent,
  Tune,
  Build,
  TrendingUp,
  Security,
  Settings,
  SyncAlt,
  Lock,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deeva Payon colud sevrices.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CloudSolutions = () => {
  return (
    <>

<Helmet>
  <title>Top Cloud Solutions Company in India | Deeva Payon</title>
  <meta
    name="description"
    content="Elevate your business with our leading cloud solutions company in India, providing customized cloud computing, migration, and security services to boost efficiency."
  />
  <meta
    name="keywords"
    content="Cloud solutions company in India, Cloud computing services in India, Cloud migration services India, Cloud hosting solutions India, Best cloud service providers in India, Cloud infrastructure services India, SaaS solutions in India, Managed cloud services India"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Cloud Solutions Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              As a top cloud solutions company in India, we offer innovative and scalable cloud computing services designed to meet the unique needs of businesses across different industries. Our mission is to empower organizations by harnessing the power of cloud technology, providing enhanced efficiency, flexibility, and security.
              </Typography>
              <Typography variant="body1" paragraph>
              With our tailored solutions, we help businesses optimize operations, streamline processes, and stay ahead in a rapidly evolving digital landscape.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Deevapayon, Cloud Solutions Company in India"
                title="Cloud Solutions Company showcasing innovative cloud computing services and solutions in India."
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Cloud Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer a comprehensive suite of cloud solutions designed to support your business objectives.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Cloud />,
          title: 'Cloud Infrastructure Services',
          description:
            'We provide robust Infrastructure as a Service (IaaS) solutions that allow businesses to build and manage their IT infrastructure in the cloud. This includes virtual servers, storage, and networking capabilities that scale with your business needs.',
        },
        {
          icon: <Build />,
          title: 'Platform as a Service (PaaS)',
          description:
            'Our PaaS offerings enable developers to create, deploy, and manage applications without the complexity of building and maintaining the underlying infrastructure. This service accelerates development cycles and enhances productivity.',
        },
        {
          icon: <Web />,
          title: 'Software as a Service (SaaS)',
          description:
            'We deliver user-friendly software applications hosted on the cloud. This allows businesses to access powerful tools without the need for extensive hardware or software installations.',
        },
        {
          icon: <SyncAlt />,
          title: 'Cloud Migration Services',
          description:
            'Transitioning to the cloud can be daunting. Our expert team guides you through every step of the migration process, ensuring minimal disruption to your operations while maximizing efficiency and security.',
        },
        {
          icon: <Settings />,
          title: 'Managed Cloud Services',
          description:
            'Concentrate on your primary business activities while we manage your cloud infrastructure. Our managed services include monitoring, maintenance, and optimization of your cloud resources to ensure peak performance.',
        },
        {
          icon: <Security />,
          title: 'Disaster Recovery Solutions',
          description:
            'Protect your data with our reliable disaster recovery services. We implement strategies that ensure business continuity and data integrity in case of unforeseen events.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Deevapayon?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted Cloud Solutions company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
        {
          icon: <People />,
          title: 'Expertise',
          description:
            'With years of experience in the industry, our team of certified professionals understands the intricacies of cloud technology and its application in various business contexts.',
        },
        {
          icon: <Tune />,
          title: 'Customization',
          description:
            'We recognize that each business is unique. Our solutions are tailored to meet specific requirements, ensuring that you get the most out of your investment in cloud technology.',
        },
        {
          icon: <TrendingUp />,
          title: 'Scalability',
          description:
            'Our solutions expand alongside your business as it evolves. We provide flexible options that allow you to scale resources up or down based on demand.',
        },
        {
          icon: <Lock />,
          title: 'Security',
          description:
            'Data security is our top priority. We implement stringent security measures and compliance protocols to protect your sensitive information.',
        },
        {
          icon: <SupportAgent />,
          title: 'Support',
          description:
            'Our dedicated support team is available 24/7 to assist you with any issues or inquiries you may have regarding our services.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Cloud Solutions Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    As a leading cloud solutions company in India, we aim to boost our online visibility through strategic content optimization. This helps attract potential clients who are looking for reliable cloud services that meet their specific needs.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default CloudSolutions;