// src/pages/APIPanelIntegration.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Assignment,
  FactCheck,
  CloudUpload,
  Security,
  Sync,
  Settings,
  Dashboard,
  BugReport,
  DataUsage,
  Flight,
  LocalShipping,
  LocalHospital,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/API Panel Integration & Management.png';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const APIPanelIntegration = () => {
  return (
    <>

<Helmet>
  <title>API Panel Integration & Management Services - Seamless API Connections for Your Business</title>
  <meta
    name="description"
    content="Discover expert API Panel Integration & Management services at Deeva Payon. We offer custom solutions for seamless API integration, real-time monitoring, and secure management."
  />
  <meta
    name="keywords"
    content="API Panel Integration & Management, API Integration, Custom API Solutions, API Management Services, API Security, Scalable API Integration, Real-Time API Monitoring, API Panel Development, API Troubleshooting, API Performance Analytics"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                API Panel Integration & Management services
              </Typography>
              <Typography variant="body1" paragraph>
              At Deeva Payon, we provide robust API Panel Integration & Management services to seamlessly connect your software applications, ensuring smooth data exchange and improved system efficiency. Whether integrating third-party APIs or managing your own, our solutions enhance functionality and scalability.
              </Typography>
              <Typography variant="body1" paragraph>
              Our expert team specializes in creating custom API integration panels that offer flexibility, security, and full control, allowing you to manage and monitor API interactions from a centralized hub for optimal performance and seamless communication between systems.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="API Panel Integration & Management"
                title="API Panel Integration & Management Solutions"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our API Panel Integration & Management Process
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading graphic design company in India, we offer a wide range of creative services designed to enhance your brand's visual presence and create memorable experiences for your audience. Our offerings include:
    </Typography> */}

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Assignment />,
          title: 'Consultation & Planning',
          description:
            'Our process starts with a thorough consultation where we understand your business needs, existing systems, and goals. We create a roadmap for integrating your APIs in a way that aligns with your objectives and streamlines operations.',
        },
        {
          icon: <Build />,
          title: 'Custom Integration Development',
          description:
            'Once the plan is in place, our developers begin building your custom API panel, integrating the required APIs while ensuring compatibility with your current systems. We pay close attention to performance, security, and usability during development.',
        },
        {
          icon: <FactCheck />,
          title: 'Testing & Quality Assurance',
          description:
            'We conduct rigorous testing to ensure that all APIs integrate correctly and that data flows smoothly between systems. Our QA process checks for potential errors, performance issues, and security vulnerabilities to ensure that your API integrations work flawlessly.',
        },
        {
          icon: <CloudUpload />,
          title: 'Deployment & Training',
          description:
            'After the panel is tested and approved, we deploy the solution to your live environment. We also provide training to your team on how to use the API panel effectively, ensuring smooth adoption across your organization.',
        },
        {
          icon: <SupportAgent />,
          title: 'Ongoing Maintenance & Support',
          description:
            'API management is an ongoing process. We offer continuous monitoring, maintenance, and support to keep your integrations running smoothly. Whether it\'s adding new APIs or upgrading existing ones, we ensure your system remains up-to-date and secure.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Our API Panel Integration & Management Services?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Settings />,
          title: 'Custom API Integration Solutions',
          description:
            'Our API Panel Integration & Management services are fully customizable, enabling you to connect your applications, databases, and external services to meet your unique business requirements. Whether you\'re working with internal systems or integrating with external APIs, we ensure seamless connectivity for better functionality and improved user experiences.',
        },
        {
          icon: <Dashboard />,
          title: 'Streamlined Management',
          description:
            'Manage multiple API connections through a single panel, simplifying monitoring, troubleshooting, and usage tracking. With a user-friendly interface, our API Panel Integration & Management services help you keep everything under control with minimal complexity. Stay on top of API performance and handle integrations with ease.',
        },
        {
          icon: <Security />,
          title: 'Enhanced Security',
          description:
            'Data security is essential when dealing with API integrations. Our solutions implement the latest security measures such as authentication protocols, encryption, and secure data transmission to protect sensitive information and safeguard against potential vulnerabilities.',
        },
        {
          icon: <TrendingUp />,
          title: 'Scalable Integration Framework',
          description:
            'As your business grows, so do your integration needs. We build scalable API panels that grow with your business, enabling easy expansion of features, integrations, and capabilities. Whether you\'re adding more APIs or expanding system functionalities, our API panel systems support your future needs.',
        },
        {
          icon: <BarChart />,
          title: 'Real-Time Monitoring & Analytics',
          description:
            'Gain insights into your API performance with advanced monitoring and analytics features. Our API Panel Integration & Management solutions offer real-time tracking, usage statistics, error logs, and other vital data, empowering you to make informed decisions and optimize API performance.',
        },
        {
          icon: <Sync />,
          title: 'Seamless Data Exchange',
          description:
            'One of the key advantages of effective API management is smooth data transfer between systems. We ensure that your APIs work in harmony with other software and platforms, reducing friction in data exchanges and boosting productivity. Our integration panels support a variety of data formats, ensuring compatibility with different systems.',
        },
        {
          icon: <BugReport />,
          title: 'Efficient Troubleshooting & Support',
          description:
            'With our API Panel Integration & Management services, troubleshooting becomes a breeze. You can quickly identify issues, monitor error logs, and receive alerts to minimize downtime. Our team is also available to provide ongoing technical support to address any concerns or integration challenges.',
        },
        {
          icon: <DataUsage />,
          title: 'Optimized API Usage',
          description:
            'We help you track and optimize your API usage by providing detailed analytics and performance data. Monitor how each API is being used, identify inefficiencies, and make data-driven decisions to optimize resource allocation and reduce costs.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>


<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Industries We Serve
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <ShoppingCart />,
          title: 'E-Commerce',
          description:
            'Integrate payment gateways, third-party services, and CRM systems.',
        },
        {
          icon: <LocalHospital />,
          title: 'Healthcare',
          description:
            'Connect medical devices, patient management systems, and external healthcare services.',
        },
        {
          icon: <AccountBalance />,
          title: 'Finance',
          description:
            'Integrate with payment processors, banking systems, and financial reporting tools.',
        },
        {
          icon: <Flight />,
          title: 'Travel & Hospitality',
          description:
            'Connect with booking platforms, flight and hotel APIs, and customer service tools.',
        },
        {
          icon: <LocalShipping />,
          title: 'Logistics',
          description:
            'Integrate GPS systems, inventory management, and shipment tracking APIs.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with Your API Panel Integration & Management Today
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Streamline your API connections and improve operational efficiency with our API Panel Integration & Management services. Deeva Payon is ready to deliver tailored solutions that ensure seamless integration, enhance security, and support your business growth. Contact us today to find out how we can help you integrate and manage APIs for maximum performance and scalability.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default APIPanelIntegration;