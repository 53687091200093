import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Grid, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CallToAction = () => {
  const [visible, setVisible] = useState(false);
  const sectionRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={sectionRef}
      sx={{
        width: '100%',
        backgroundColor: '#222', // Dark background
        color: '#fff',
        py: 3, // Removed space from the top, added space at the bottom
        pb: 4,

      }}
    >
      <Container maxWidth="lg">
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Text Content */}
          <Grid item xs={12} md={8}>
            <Typography variant="h6" fontWeight="bold">
              What are you waiting for?
            </Typography>
            <Typography variant="body1" mt={1}>
              Call us now and increase your business sales and ROI{' '}
              <strong>+91 9785909785</strong>
            </Typography>
          </Grid>

          {/* Buttons */}
          <Grid item xs={12} md={4} display="flex" justifyContent={{ xs: 'start', md: 'end' }} mt={{ xs: 2, md: 0 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
              onClick={() => (window.location.href = 'tel:+919785909785')}
            >
              Call Us
            </Button>
            <Button variant="contained" color="primary" onClick={() => navigate('/contact')}>
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CallToAction;
