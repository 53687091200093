import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import {
  Code,
  Payments,
  Business,
  Cloud,
  Storage,
  Domain,
  ShoppingCart,
  Campaign,
  DesignServices,
} from "@mui/icons-material";

const services = [
  {
    icon: <Code fontSize="large" />,
    title: "Software Development",
    description:
      "Deeva Payon is a leading software development company in India, crafting custom solutions for your business needs. As one of the best software companies in India, we offer innovative and reliable services, including mobile apps and web platforms. Partner with us for quality, scalable, and cost-effective software solutions.",
    path: "/services/softwaredevelopment",
  },
  {
    icon: <Payments fontSize="large" />,
    title: "Fintech Software Services",
    description:
      "As a premier fintech services company in India, Deeva Payon provides advanced technology solutions for the financial industry. We specialize in payments, lending, and wealth management, helping businesses thrive.",
    path: "/services/fintechsoftweredevelopment",
  },
  {
    icon: <Business fontSize="large" />,
    title: "B2B Software Solutions",
    description:
      "Deeva Payon is a leading B2B software solutions company in India, providing innovative technology for businesses. We deliver scalable and customized software to streamline operations, improve efficiency, and drive growth. Partner with us for reliable, high-quality B2B software for your unique needs.",
    path: "/services/b2bsoftware",
  },
  {
    icon: <Cloud fontSize="large" />,
    title: "Enterprise Software Development",
    description:
      "A leading Enterprise Software Development company in India, we deliver robust and scalable solutions. We specialize in custom software development, cloud services, and system integration for large organizations. Enhance your business operations with our reliable enterprise-grade software.",
    path: "/services/enterprise-software",
  },
  {
    icon: <Storage fontSize="large" />,
    title: "Hosting Server Services",
    description:
      "Looking for the best hosting provider company in India? Deeva Payon delivers reliable and scalable web hosting solutions designed to meet your needs. With high uptime, 24/7 support, and Indian data centers for faster speeds, we ensure your website's success. Choose us for affordable plans and top-notch performance.",
    path: "/services/hosting-server",
  },
  {
    icon: <Domain fontSize="large" />,
    title: "Domain Management Services",
    description:
      "As one of the best domain providers in India, we offer a wide range of domain registration services at competitive prices. Our user-friendly platform ensures a seamless experience, backed by 24/7 customer support. Secure your online presence today with our reliable and affordable domain solutions designed for your needs.",
    path: "/services/domain",
  },
  {
    icon: <ShoppingCart fontSize="large" />,
    title: "Salesforce Services",
    description:
      "Looking for the best Salesforce services company in India? We provide expert Salesforce consulting, implementation, customization, and integration services. Our certified consultants deliver solutions to optimize your Salesforce CRM and drive business growth. Maximize your CRM effectiveness with our affordable and high-quality services.",
    path: "/services/SalesforceServices",
  },
  {
    icon: <Campaign fontSize="large" />,
    title: "Digital Marketing",
    description:
      "We're a top digital marketing agency in India, offering all-inclusive digital solutions! From SEO to social media, PPC to engaging content, we've got you covered. Let's boost your brand, drive traffic, and increase sales with our data-smart strategies. We're here to help you shine online!",
    path: "/services/digitalmarketing",
  },
  {
    icon: <DesignServices fontSize="large" />,
    title: "Graphic Design",
    description:
      "As one of the best graphic design companies in India, we specialize in creating stunning visuals that elevate your brand. Our talented team offers a wide range of services, including logo design, branding, and marketing materials. Let us help you make a lasting impression with innovative and impactful designs.",
    path: "/services/graphic-design",
  },
];

const ServicesSection = () => {
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #f0f8ff, #e6f7ff)", // Subtle gradient background
        py: { xs: 8, md: 12 }, // Vertical padding
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          background: "linear-gradient(135deg, #1976d2, #f05a22)",
          opacity: 0.1,
        }}
      />

      {/* Section Title */}
      <Container>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 6,
            }}
          >
            <Box
              sx={{
                background: "linear-gradient(135deg, #000, #000)",
                borderRadius: "16px",
                padding: "10px 20px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                variant="h3"
                textAlign="center"
                fontWeight="bold"
                sx={{
                  color: "#fff", // White text on gradient background
                  // textTransform: "uppercase",
                  letterSpacing: 2,
                }}
              >
                Our Software Development Services
              </Typography>
            </Box>
          </Box>
        </motion.div>

        {/* Service Cards */}
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <Card
                  sx={{
                    height: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "16px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    position: "relative",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      boxShadow: "0px 8px 20px rgba(25, 118, 210, 0.3)",
                      transform: "translateY(-5px)",
                    },
                  }}
                >
                  {/* Gradient Overlay */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "5px",
                      background: "linear-gradient(90deg, #1976d2, #f05a22)",
                    }}
                  />
                  {/* Card Content */}
                  <CardContent sx={{ textAlign: "center" }}>
                    {/* Icon */}
                    <IconButton
                      sx={{
                        background: "linear-gradient(45deg, #1976d2, #1976d2)",
                        color: "#fff",
                        width: "60px",
                        height: "60px",
                        mb: 2,
                      }}
                    >
                      {service.icon}
                    </IconButton>
                    {/* Title */}
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{
                        color: "#1976d2",
                        background: "linear-gradient(45deg, #1976d2, #1976d2)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        mb: 1,
                      }}
                    >
                      {service.title}
                    </Typography>
                    {/* Scrollable Description */}
                    <Box
                      sx={{
                        maxHeight: "120px", // Fixed height for scrollable area
                        overflowY: "auto", // Enable vertical scrolling
                        color: "#555",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: 1.6,
                        mb: 2,
                        px: 1, // Padding for better readability
                        "&::-webkit-scrollbar": {
                          width: "6px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#1976d2",
                          borderRadius: "4px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "#f0f8ff",
                        },
                      }}
                    >
                      {service.description}
                    </Box>
                    {/* Call-to-Action Button */}
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#f05a22",
                        },
                      }}
                      href={service.path}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ServicesSection;