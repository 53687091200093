// src/pages/SalesforceServices.js
import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CheckCircleOutline,
  Code,
  PhoneIphone,
  Web,
  Cloud,
  People,
  ShoppingCart,
  Api,
  DirectionsBus,
  Bolt,
  WaterDrop,
  LocalFireDepartment,
  Toll,
  CreditCard,
  Fingerprint,
  AccountBalance,
  Paid,
  School,
  FlashOn,
  Campaign,
  Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Salesforce service provider in India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';

const SalesforceServices = () => {
  return (
    <>
      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Salesforce Service Provider in India
              </Typography>
              <Typography variant="body1" paragraph>
              Deeva Payon is a top-tier Salesforce service provider in India, delivering a comprehensive suite of services aimed at helping businesses enhance their customer relationship management (CRM) systems. We specialize in offering complete Salesforce solutions customized to fit your specific business requirements, ensuring a smooth implementation and maximum returns on investment.
              </Typography>
              <Typography variant="body1" paragraph>
              Whether you're just starting with Salesforce or looking to optimize your current setup, our expert team is here to provide the guidance and support necessary to unlock Salesforce’s full potential for your organization.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Salesforce service provider in India"
                title="Salesforce CRM customization and integration"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deeva Payon as Your Salesforce Service Provider in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading Salesforce service provider in India, we bring deep knowledge, expertise, and industry experience to deliver results that matter. Here's why businesses trust us for their Salesforce needs:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <IntegrationInstructions />,
          title: 'Comprehensive Salesforce Solutions',
          description:
            'We provide full-spectrum Salesforce services covering implementation, integration, customization, and ongoing support, aligned with your business objectives.',
        },
        {
          icon: <Build />,
          title: 'Salesforce Implementation Services',
          description:
            'Transform sales, marketing, and customer relationship management with seamless Salesforce integration into your workflows.',
        },
        {
          icon: <Tune />,
          title: 'Salesforce Customization Services',
          description:
            'Tailor Salesforce to your unique needs with customized workflows, dashboards, reports, and fields.',
        },
        {
          icon: <Link />,
          title: 'Salesforce Integration Services',
          description:
            'Integrate Salesforce with ERP, marketing tools, and support platforms for a unified 360-degree customer view.',
        },
        {
          icon: <SupportAgent />,
          title: 'Salesforce Support and Maintenance',
          description:
            'Proactive maintenance, troubleshooting, and optimization to keep your Salesforce CRM performing at peak efficiency.',
        },
        {
          icon: <Assessment />,
          title: 'Salesforce Analytics and Reporting',
          description:
            'Create custom reports and dashboards to monitor metrics, track performance, and drive data-driven decisions.',
        },
        {
          icon: <Campaign />,
          title: 'Salesforce Marketing Cloud Services',
          description:
            'Automate campaigns, segment data, and personalize communications to boost engagement and ROI.',
        },
        {
          icon: <FlashOn />,
          title: 'Salesforce Lightning Development',
          description:
            'Migrate to Lightning, customize components, and enhance user experience with dynamic interfaces.',
        },
        {
          icon: <PhoneIphone />,
          title: 'Salesforce Mobile App Development',
          description:
            'Build mobile apps integrated with Salesforce for on-the-go access to customer data and task management.',
        },
        {
          icon: <School />,
          title: 'Salesforce Training Services',
          description:
            'Empower your team with tailored training programs to maximize Salesforce adoption and efficiency.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why We Are the Best Salesforce Service Provider in India
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '3' }}
    >
      As a Fintech Software company in India, we pride ourselves on delivering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <People />,
          title: 'Experienced Salesforce Professionals',
          description:
            'At Deeva Payon, we have a team of certified Salesforce professionals with years of experience in CRM implementation, integration, and customization.',
        },
        {
          icon: <SettingsApplications />,
          title: 'End-to-End Salesforce Services',
          description:
            'Whether you’re looking for implementation, integration, customization, or support, we offer complete Salesforce solutions for a smooth experience.',
        },
        {
          icon: <Business />,
          title: 'Tailored Solutions for Your Industry',
          description:
            'We specialize in industry-specific Salesforce services for retail, healthcare, finance, education, and more to optimize your business processes.',
        },
        {
          icon: <Savings />,
          title: 'Cost-Effective and Scalable Solutions',
          description:
            'We provide scalable Salesforce solutions that grow with your business, delivering long-term value within your budget.',
        },
        {
          icon: <SupportAgent />,
          title: 'Ongoing Support and Maintenance',
          description:
            'Our ongoing support ensures your CRM system remains up-to-date, secure, and optimized for high performance.',
        },
        {
          icon: <VerifiedUser />,
          title: 'Proven Track Record',
          description:
            'With a history of successful implementations and satisfied clients, we are one of the most trusted Salesforce service providers in India.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
      sx={{
        padding: { xs: '4rem 0', md: '3rem 0' },
        backgroundColor: '#f4f6f8', // Light gray background
      }}
    >
      <Container maxWidth="lg">
        {/* Title */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#fff", // White text color
            background: "#1976d2", // Blue background
            borderRadius: "16px", // Rounded corners
            padding: "10px 20px", // Padding for better spacing
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
            textTransform: "uppercase", // Uppercase text
            letterSpacing: "2px", // Spacing between letters
            display: "flex", // Use flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            margin: "0 auto", // Center the box itself
            maxWidth: "fit-content", // Ensure the box only wraps the content
            marginBottom: '2rem',
          }}
        >
          Our Salesforce Services
        </Typography>

        {/* Features Grid */}
        <Grid container spacing={6}>
          {[
            {
              icon: <Build />,
              title: 'Salesforce Implementation',
              description: 'Seamless Salesforce deployment tailored to your business needs.',
            },
            {
              icon: <Tune />,
              title: 'Salesforce Customization',
              description: 'Tailored Salesforce solutions to optimize workflows and improve efficiency.',
            },
            {
              icon: <Link />,
              title: 'Salesforce Integration',
              description: 'Connect Salesforce with your existing systems and tools for a unified CRM experience.',
            },
            {
              icon: <SupportAgent />,
              title: 'Salesforce Support & Maintenance',
              description: 'Ongoing support to ensure Salesforce remains efficient and up-to-date.',
            },
            {
              icon: <Assessment />,
              title: 'Salesforce Analytics',
              description: 'Create custom reports and dashboards to make data-driven decisions.',
            },
            {
              icon: <Campaign />,
              title: 'Salesforce Marketing Cloud',
              description: 'Automate and personalize marketing efforts to improve customer engagement.',
            },
            {
              icon: <FlashOn />,
              title: 'Salesforce Lightning Development',
              description: 'Enhance user experience with Salesforce Lightning features.',
            },
            {
              icon: <PhoneIphone />,
              title: 'Salesforce Mobile App Development',
              description: 'Build mobile apps that work seamlessly with Salesforce.',
            },
            {
              icon: <School />,
              title: 'Salesforce Training',
              description: 'Empower your team with customized Salesforce training programs.',
            },

          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box
                sx={{
                  backgroundColor: '#ffffff', // White card background
                  borderRadius: '12px', // Rounded corners
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
                  padding: '1.5rem', // Internal padding
                  transition: 'transform 0.3s ease', // Smooth hover effect
                  '&:hover': {
                    transform: 'translateY(-5px)', // Lift on hover
                  },
                  textAlign: 'center', // Center-align content
                }}
              >
                {/* Icon */}
                <Avatar
                  sx={{
                    backgroundColor: '#1976d2', // Blue background
                    width: '50px',
                    height: '50px',
                    margin: '0 auto 1rem', // Centered with spacing below
                  }}
                >
                  {feature.icon}
                </Avatar>

                {/* Title */}
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: '600', color: '#333' }}
                >
                  {feature.title}
                </Typography>

                {/* Description */}
                <Typography
                  variant="body2"
                  sx={{ color: '#555', lineHeight: '1.8' }}
                >
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
      Get Started with the Best Salesforce Service Provider in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    If you're looking for a Salesforce service provider in India who can help you implement, customize, integrate, and support your Salesforce CRM system, look no further than Deeva Payon. We are committed to helping your business thrive by providing high-quality Salesforce solutions that drive efficiency and growth.
    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default SalesforceServices;