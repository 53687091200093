import React from "react";
import { Box, Typography, Grid, Card, CardContent, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptopCode,
  faChartSimple,
  faPencil,
  faRocket,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

const steps = [
  { title: "Requirement Analysis", icon: faChartSimple },
  { title: "UI/UX Design", icon: faPencil },
  { title: "Architecture & Planning", icon: faChartSimple },
  { title: "Software Development", icon: faLaptopCode },
  { title: "Quality Assurance & Testing", icon: faChartSimple },
  { title: "Performance Optimization", icon: faScrewdriverWrench },
  { title: "Deployment & Site Launch", icon: faRocket },
  { title: "Post-Launch Support & Maintenance", icon: faScrewdriverWrench },
  { title: "Ongoing Updates & Improvements", icon: faRocket },
];

const HowWeWork = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        py: { xs: 8, md: 10 },
        background: "#F9F9FB",
      }}
    >
      {/* Section Heading */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: "#222",
          mb: 4,
          textTransform: "uppercase",
          position: "relative",
          display: "inline-block",
          "&::after": {
            content: '""',
            display: "block",
            width: "60%",
            height: "3px",
            backgroundColor: "#1976D2",
            margin: "8px auto 0",
            borderRadius: "2px",
          },
        }}
      >
        How We Work
      </Typography>

      {/* Process Steps Grid */}
      <Grid container spacing={4} justifyContent="center">
        {steps.map((step, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                maxWidth: 320,
                mx: "auto",
                textAlign: "center",
                boxShadow: 4,
                borderRadius: "12px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "translateY(-8px)",
                  boxShadow: 10,
                },
              }}
            >
              <CardContent
                sx={{
                  padding: "2rem",
                  "&:hover": {
                    background: "#1976D2",
                    color: "white",
                    transition: "background 0.3s ease",
                  },
                }}
              >
                <IconButton
                  sx={{
                    backgroundColor: "#1E88E5",
                    color: "white",
                    mb: 2,
                    width: 60,
                    height: 60,
                    transition: "0.3s",
                    "&:hover": { backgroundColor: "#1565C0" },
                  }}
                >
                  <FontAwesomeIcon icon={step.icon} size="lg" />
                </IconButton>

                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "1rem", md: "1.2rem" },
                  }}
                >
                  {step.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HowWeWork;
