import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { motion } from "framer-motion";

const teamMembers = [
  {
    name: "John Doe",
    role: "CEO & Founder",
    description:
      "A visionary leader with over 15 years of experience in software development and business strategy.",
    image: "https://via.placeholder.com/150", // Replace with actual image URL
  },
  {
    name: "Jane Smith",
    role: "Lead Developer",
    description:
      "An expert in full-stack development with a passion for creating innovative solutions.",
    image: "https://via.placeholder.com/150", // Replace with actual image URL
  },
  {
    name: "Emily Johnson",
    role: "UX/UI Designer",
    description:
      "Specializes in crafting user-centric designs that enhance user experience.",
    image: "https://via.placeholder.com/150", // Replace with actual image URL
  },
  {
    name: "Michael Brown",
    role: "Marketing Specialist",
    description:
      "Drives brand growth through data-driven marketing strategies.",
    image: "https://via.placeholder.com/150", // Replace with actual image URL
  },
];

const OurTeam = () => {
  return (
    <Box
      sx={{
        py: { xs: 2, md: 12 },
        px: { xs: 4, md: 8 },
        backgroundColor: "#fff", // White background
        minHeight: "50vh",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          background: "linear-gradient(135deg, #f9f9f9, #ffffff)",
        }}
      />

      {/* Section Title */}
      <Container>
      <Typography
  variant="h3"
  textAlign="center"
  fontWeight="bold"
  sx={{
    color: "#ffffff", // White text for contrast
    mb: 6,
    textTransform: "uppercase",
    letterSpacing: 2,
    py: 2, // Add vertical padding
    px: 4, // Add horizontal padding
    backgroundColor: "#000000", // Black background
    borderRadius: "8px", // Rounded corners for a modern look
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow for depth
    display: "inline-block", // Make the background only wrap around the text
    position: "relative", // Allows for additional effects
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "inherit", // Match the border radius of the container
      background: "linear-gradient(135deg, #f05a22, #5a7fe6)", // Gradient overlay
      opacity: 0.2, // Subtle gradient effect
      zIndex: -1, // Place behind the text
    },
    mx: "auto", // Center horizontally within the parent container
    display: "flex", // Use flexbox for better alignment
    alignItems: "center", // Align text vertically
    justifyContent: "center", // Center text horizontally
  }}
>
  Meet Our Team
</Typography>

        {/* Team Members Grid */}
        <Grid container spacing={4}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <Card
                  sx={{
                    height: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "16px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    overflow: "hidden",
                    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "rotateY(10deg)",
                      boxShadow: "0px 8px 20px rgba(240, 90, 34, 0.2)",
                    },
                  }}
                >
                  {/* Image */}
                  <Box
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      height: "150px",
                    }}
                  >
                    <Avatar
                      src={member.image}
                      alt={member.name}
                      sx={{
                        width: "120px",
                        height: "120px",
                        margin: "auto",
                        mt: "15px",
                        border: "4px solid #f05a22", // Unique accent color
                        transition: "transform 0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                    />
                  </Box>

                  {/* Name */}
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color: "#5a7fe6", // Unique accent color
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    {member.name}
                  </Typography>

                  {/* Role */}
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#555",
                      mb: 2,
                    }}
                  >
                    {member.role}
                  </Typography>

                  {/* Description */}
                  <CardContent>
                    <Typography
                      sx={{
                        color: "#555",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: 1.6,
                      }}
                    >
                      {member.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default OurTeam;