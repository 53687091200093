// src/pages/About.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Footer from '../components/Footer';
import Header from '../components/Header';
import AboutUs from '../components/About/AnoutUs/AboutUs';
import CompanyStory from '../components/About/CompanyStory/CompanyStory';
import CoFounder from '../components/About/CoFounder/CoFounder';
import Direct from '../components/About/Directer/Direct';
import OurMission from '../components/About/OurMission/OurMission';
import HowWeWork from '../components/About/HowWeWork/HowWeWork';
import SelectDeeva from '../components/About/SelectDeeva/SelectDeeva';
import Software from '../components/About/Software/Software';
import OurBanking from '../components/About/OurBanking/OurBanking';
import Services from '../components/Services';
import WhyChooseDeevaPayon from '../components/About/AnoutUs/WhyChooseDeevaPayon';
import OurTeam from '../components/About/AnoutUs/OurTeam';
import Whoweare from '../components/AboutUs';
import { Helmet } from 'react-helmet-async'; // Import Helmet for meta tags
import CallToAction from '../Services/CallToAction';

const About = () => {
  return (
    <>
      <Helmet>
        {/* Meta Title */}
        <title>About Deeva Payon | A Leading Software company in India</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Deeva Payon, an ISO Certified Software Development Company, is dedicated to delivering exceptional value. As a trusted organization, we focus on providing value-added solutions that drive success for our clients."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Deeva Payon, Deeva Payon Software company, Software Development Company"
        />
      </Helmet>

      <Header />
      <AboutUs />
      <CompanyStory />
      <Whoweare />
      {/* <CoFounder/> */}
      {/* <Direct/> */}
      <OurMission />
      <HowWeWork />
      {/* <Software/> */}
      {/* <SelectDeeva/> */}
      {/* <Services/> */}
      <WhyChooseDeevaPayon />
      <OurTeam />
      <CallToAction/>
      <OurBanking />
      <Footer />
    </>
  );
};

export default About;