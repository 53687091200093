// src/pages/ElectricityBillPayments.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Language,
  Update,
  Receipt,
  Devices,
  Security,
  History,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Electricity Bill Payment Software.jpeg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ElectricityBillPayments = () => {
  return (
    <>

<Helmet>
  <title>Electricity Bill Payment Software Company in India - Deevapayon</title>
  <meta
    name="description"
    content="Looking for a reliable electricity bill payment software company in India? Our secure, user-friendly solutions ensure seamless, hassle-free bill payments for businesses and consumers."
  />
  <meta
    name="keywords"
    content="electricity bill payment software, electricity bill payment software company in India, online electricity payment solutions, secure bill payment software, utility bill payment software, automated billing system, bill payment software for businesses"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Electricity Bill Payment Software
              </Typography>
              <Typography variant="body1" paragraph>
              As a leading electricity bill payment software company in India, we specialize in delivering innovative and efficient solutions that simplify the process of electricity bill payments for businesses and consumers.
              </Typography>
              <Typography variant="body1" paragraph>
              Our software is designed to provide a seamless, secure, and user-friendly experience for paying electricity bills online, ensuring that both customers and service providers can manage transactions with ease.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Electricity Bill Payment Software for Seamless Online Payments"
                title="Electricity Bill Payment Software for Easy and Secure Transactions"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Electricity Bill Payment Software Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Our electricity bill payment software integrates with multiple payment gateways, offering a wide range of payment options to customers. Whether you are a utility provider or a third-party service, our solution streamlines bill collection, tracks payments, and ensures transparency. With real-time updates and easy-to-use interfaces, we empower businesses to enhance customer satisfaction and improve their billing processes.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
  {
    icon: <Security />,
    title: 'Secure Payment Gateway Integration',
    description:
      'Safeguard customer data and ensure smooth transactions through reliable payment gateways.',
  },
  {
    icon: <Devices />,
    title: 'Multi-Platform Compatibility',
    description:
      'Accessible on web and mobile platforms, ensuring ease of use for both service providers and consumers.',
  },
  {
    icon: <Receipt />,
    title: 'Automated Billing',
    description:
      'Simplify the billing process with automated calculations and invoicing, reducing human errors and operational overhead.',
  },
  {
    icon: <Update />,
    title: 'Real-Time Updates',
    description:
      'Get real-time payment updates and insights to enhance operational efficiency.',
  },
  {
    icon: <Language />,
    title: 'Multi-Language & Multi-Currency Support',
    description:
      'Cater to a diverse range of users with multiple language and currency options.',
  },
  {
    icon: <History />,
    title: 'Payment History and Tracking',
    description:
      'Keep track of payment history and allow users to view past transactions for better financial management.',
  },
].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Electricity bill payment Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    By choosing us as your trusted electricity bill payment software company in India, you can improve operational efficiency, reduce manual errors, and offer a hassle-free payment experience to your customers. Whether you're a utility company, a service provider, or a business looking to offer bill payment services, our solution is designed to meet your needs and ensure seamless integration with your existing systems.

    </Typography>

    <Typography variant="body1" align="center" paragraph>
    Let us help you transform your billing process with advanced technology, enhancing both your customer experience and business operations. Contact us today to learn more about our customized electricity bill payment software solutions.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default ElectricityBillPayments;