// src/pages/FintechSoftwareDevelopment.js
import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CheckCircleOutline,
  Code,
  PhoneIphone,
  Web,
  Cloud,
  People,
  ShoppingCart,
  Api,
  DirectionsBus,
  Bolt,
  WaterDrop,
  LocalFireDepartment,
  Toll,
  CreditCard,
  Fingerprint,
  AccountBalance,
  Paid,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deeva Payon, A Fintech Software Company.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FintechSoftwareDevelopment = () => {
  return (
    <>
      {/* Header Component */}
      <Header />

      <Helmet>
  <title>Fintech Software and Digital Payment Solutions in India</title>
  <meta
    name="description"
    content="Deeva Payon, a leading Fintech Software Company in India, offers secure digital solutions for mobile recharge, bill payments, AEPS, DMT, ticket booking, and more."
  />
  <meta
    name="keywords"
    content="Fintech Software company in India, Fintech Software company, Digital Payment Solutions in India"
  />
</Helmet>

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Fintech Software Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deeva Payon, we are a leading Fintech software company in India, committed to delivering innovative and secure financial solutions. Our expertise covers a wide range of services designed to enhance your digital financial journey, ensuring seamless and efficient experiences.
              </Typography>
              <Typography variant="body1" paragraph>
              With a focus on cutting-edge technology and industry best practices, we provide solutions that empower businesses to thrive in the digital finance space. Our services are designed to drive growth, security, and innovation in the financial sector.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Fintech Software Solutions for Secure and Innovative Financial Services"
                title="Fintech Software for Advanced and Secure Financial Solutions"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Fintech Software Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '3' }}
    >
      We offer a comprehensive suite of fintech services to meet the evolving needs of businesses and individuals alike. Our key services include:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <PhoneIphone />,
          title: 'Mobile Recharge App',
          description:
            'Seamlessly recharge mobile phones across all telecom operators, including prepaid and postpaid plans, ensuring quick, secure, and convenient transactions through an intuitive app interface with multiple payment options.',
        },
        {
          icon: <Web />,
          title: 'DTH (Direct-to-Home TV)',
          description:
            'Effortlessly manage DTH subscriptions and recharge for major service providers, offering users a hassle-free viewing experience with timely payments, instant confirmations, and uninterrupted entertainment.',
        },
        {
          icon: <Cloud />,
          title: 'BBPS Services',
          description:
            'Simplify bill payments through the Bharat Bill Payment System (BBPS), ensuring secure, fast, and convenient transactions for utility bills, insurance premiums, and other recurring payments with real-time updates and instant receipts.',
        },
        {
          icon: <People />,
          title: 'AEPS (Aadhaar Enabled Payment System)',
          description:
            'Facilitate secure cash withdrawals, balance inquiries, and mini statements using Aadhaar-based authentication, ensuring user privacy, safety, and convenience at authorized AEPS service points.',
        },
        {
          icon: <ShoppingCart />,
          title: 'Pan Card Services',
          description:
            'Streamline PAN card applications and management, ensuring accurate documentation, fast processing, and hassle-free issuance for both new applications and corrections through a user-friendly platform.',
        },
        {
          icon: <Api />,
          title: 'Railway Ticket Booking',
          description:
            'Book railway tickets seamlessly through an integrated platform, enabling users to plan travel conveniently with real-time seat availability, secure payments, and instant booking confirmations.',
        },

        {
          icon: <DirectionsBus />,
          title: 'Bus Ticket Booking',
          description:
            'Ensure smooth bus travel experiences with an intuitive booking platform, offering real-time schedules, seat selection, secure payments, and instant e-ticket generation for hassle-free journeys.',
        },
        {
          icon: <Bolt />,
          title: 'Electricity Bill Payments',
          description:
            'Simplify electricity bill payments for all major providers, ensuring secure transactions, timely reminders, and instant receipts, while avoiding late fees and service interruptions.',
        },
        {
          icon: <WaterDrop />,
          title: 'Water Bill Payments',
          description:
            'Manage water bill payments efficiently with our platform, ensuring timely processing, secure transactions, and instant confirmations to prevent service disruptions and administrative burdens.',
        },
        {
          icon: <LocalFireDepartment />,
          title: 'Gas Bill Payments',
          description:
            'Pay gas bills securely and conveniently through an easy-to-use platform, promoting on-time payments, reducing service interruptions, and ensuring seamless household energy management.',
        },
        {
          icon: <Toll />,
          title: 'Fastag Services',
          description:
            'Access Fastag services for quick, cashless toll payments, enhancing road travel efficiency with instant recharge options, low-balance alerts, and seamless tracking of toll expenses.',
        },
        {
          icon: <CreditCard />,
          title: 'NSDL Pan Card Services',
          description:
            'Partner with NSDL for secure and accurate PAN card-related services, ensuring quick processing for new applications, corrections, and reprints with end-to-end support.',
        },
        {
          icon: <Fingerprint />,
          title: 'Aadhar Pay',
          description:
            'Enable secure transactions using Aadhaar-linked biometric authentication, ensuring privacy, fast payments, and seamless fund transfers for both customers and merchants.',
        },
        {
          icon: <AccountBalance />,
          title: 'DMT Pay',
          description:
            'Facilitate quick, reliable Digital Money Transfer (DMT) services for instant domestic fund transfers, ensuring secure transactions with real-time tracking and confirmation.',
        },
        {
          icon: <Paid />,
          title: 'Payout',
          description:
            'Ensure fast, secure payouts with efficient disbursements to beneficiaries, simplifying bulk payments, salary processing, and vendor settlements with instant confirmation.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '3' }}
    >
      As a Fintech Software company in India, we pride ourselves on delivering:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <CheckCircleOutline />,
          title: 'Customized Solutions',
          description: 'To meet your specific business needs.',
        },
        {
          icon: <CheckCircleOutline />,
          title: 'Security and Compliance',
          description: 'Ensuring all transactions are secure and compliant with regulatory standards.',
        },
        {
          icon: <CheckCircleOutline />,
          title: 'Innovative Technology',
          description: 'Leveraging the latest fintech innovations to enhance user experience.',
        },
        {
          icon: <CheckCircleOutline />,
          title: 'Customer Support',
          description: 'Dedicated support team available to assist you at every step.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>
      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
      Transform Your Financial Landscape
    </Typography>
    <Typography variant="body1" align="center" paragraph>
      Revolutionize your financial operations with our advanced solutions. As a leading Fintech Software Company in India, we provide innovative fintech services designed to streamline processes, enhance customer engagement, and drive business growth. Our platforms empower businesses to navigate the evolving fintech landscape with confidence and success. Transform your financial ecosystem with secure, efficient, and future-ready technology.
    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default FintechSoftwareDevelopment;