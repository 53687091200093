// src/pages/BusinessCardDesign.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Diamond,
  EjectOutlined,
  PrecisionManufacturing,
  Timer,
  Crop,
  Flip,
  FormatPaint,
  DesignServices,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Business card design agency.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BusinessCardDesign = () => {
  return (
    <>

<Helmet>
  <title>Business Card Design Services | Custom & Creative Business Cards</title>
  <meta name="description" content="Discover professional Business Card Design services tailored to your brand. From custom designs to premium finishes, we create business cards that make a lasting impression. Contact us today!" />
  <meta name="keywords" content="Business Card Design, Custom Business Cards, Premium Business Card Design, Creative Business Cards, Minimalist Business Card Design, Branding Business Cards, Eco-Friendly Business Cards, Professional Business Card Design, Business Card Services" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Business Card Design Company
              </Typography>
              <Typography variant="body1" paragraph>
              A business card is more than just a contact detail; it’s a tangible representation of your brand. At Deevapyon, we offer exceptional Business Card Design services to help your brand leave a lasting impression.
              </Typography>
              <Typography variant="body1" paragraph>
              Our designs are not only visually striking but also functional, ensuring that your business card stands out in a crowded marketplace and speaks volumes about your professionalism and attention to detail.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Custom business card design showcasing unique and creative elements"
                title="Creative and Custom Business Card Design Solutions for Your Brand"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Business Card Design is Important

    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Your business card is often the first physical connection people will have with your brand. It’s a powerful marketing tool that provides an immediate impression of who you are and what your business stands for. A well-designed business card can make a memorable impact and serve as a key networking asset, while a poorly designed card can leave a negative impression.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <DesignServices />,
      title: 'Custom Business Card Design',
      description:
        'Our team specializes in creating custom business card designs that reflect your brand’s identity. We work with you to ensure the design is unique, eye-catching, and aligned with your company’s values. Whether you\'re looking for a minimalist style or something more intricate, we’ve got you covered.',
    },
    {
      icon: <Diamond />,
      title: 'Premium Business Card Design',
      description:
        'For those looking to make an even more lasting impression, we offer premium business card design services. Using high-quality materials and finishes like embossed lettering, foil stamping, or thick card stock, we create luxurious cards that exude sophistication.',
    },
    {
      icon: <FormatPaint />,
      title: 'Branding and Business Card Design',
      description:
        'Your business card is an extension of your brand. We ensure that the colors, fonts, and design elements of your business card match your overall branding. This cohesive look ensures a professional and polished image for your company.',
    },
    {
      icon: <Palette />,
      title: 'Creative and Unique Designs',
      description:
        'Want your business card to stand out? Our team is skilled in creating creative and unique business card designs that go beyond the basics. We offer innovative concepts, bold typography, and one-of-a-kind designs that are sure to leave a lasting impression.',
    },
    {
      icon: <Flip />,
      title: 'Double-Sided Business Cards',
      description:
        'Make the most of the space on your card with our double-sided business cards. We utilize the extra space to showcase additional information, a product image, or even a QR code that directs clients to your website or portfolio.',
    },
    {
      icon: <Crop />,
      title: 'Minimalist Business Card Design',
      description:
        'Sometimes less is more. If you prefer a sleek, modern look, we can create minimalist business cards that focus on simplicity and clean design. These cards are perfect for professionals looking for a sophisticated yet understated style.',
    },
    {
      icon: <EjectOutlined />,
      title: 'Eco-Friendly Business Card Design',
      description:
        'In today’s world, sustainability matters. We offer eco-friendly business card designs using recycled paper, vegetable-based inks, and other sustainable materials. Your business card can reflect your commitment to the environment without sacrificing style.',
    },
    {
      icon: <Business />,
      title: 'Industry-Specific Business Card Design',
      description:
        'Different industries have unique needs when it comes to business cards. Whether you\'re in tech, fashion, finance, or hospitality, we provide industry-specific business card design services that cater to the look and feel of your sector.',
    },
    {
      icon: <Tune />,
      title: 'Tailored Designs',
      description:
        'Our Business Card Design services are fully customized to meet your needs and ensure that your card reflects your brand identity.',
    },
    {
      icon: <Diamond />,
      title: 'High-Quality Materials',
      description:
        'We use premium materials and advanced printing techniques to ensure that every business card is of the highest quality.',
    },
    {
      icon: <Groups />,
      title: 'Experienced Designers',
      description:
        'Our team of experienced designers brings creativity and expertise to every project, ensuring that your business card stands out.',
    },
    {
      icon: <PrecisionManufacturing />,
      title: 'Attention to Detail',
      description:
        'We focus on every aspect of the design, from color and typography to the card’s texture, ensuring perfection in every detail.',
    },
    {
      icon: <Timer />,
      title: 'Quick Turnaround',
      description:
        'We understand the importance of time in business. Our team works efficiently to deliver your business card design within your deadlines.',
    },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}


      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with Your Business Card Design
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Are you ready to create a business card that leaves a lasting impression? Contact us today to discuss your Business Card Design needs. Whether you need a sleek, modern design or a more traditional look, our team is here to help you make your business stand out. Let’s create something amazing together!

    </Typography>
    <Typography variant="body1" align="center" paragraph>
    With our Business Card Design services, you can be sure that your cards won’t just be a contact tool—they’ll be a reflection of your business’ professionalism and creativity. Get in touch now and take the first step toward enhancing your brand’s identity.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default BusinessCardDesign;