// src/pages/PrintDesign.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  FormatPaint,
  Assignment,
  Mail,
  DesignServices,
  MenuBook,
  LocalPostOffice,
  Description,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Print design company in India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PrintDesign = () => {
  return (
    <>

<Helmet>
  <title>Print Design Company in India | Professional Print Design Services</title>
  <meta name="description" content="Looking for a top-rated print design company in India? We offer expert print design services including brochures, business cards, packaging, posters, and more. Contact us to bring your brand to life with creative, high-quality prints." />
  <meta name="keywords" content="Print design company in India, Print design services, Professional print design, Brochure design India, Business card design India, Packaging design India, Flyer and poster design, Custom print design, Branding print services" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Print Design Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we are a leading print design company in India specializing in delivering high-quality and creative print solutions. Whether you're looking to create stunning brochures, eye-catching business cards, or large-format posters, we provide comprehensive design services tailored to your unique business needs.
              </Typography>
              <Typography variant="body1" paragraph>
              Our expert designers ensure that your brand identity shines through every print material, leaving a lasting impression on your audience.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Print Design Company in India"
                title="Print Design Company in India - Deevapayon"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us as Your Print Design Company in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      At Deevapayon, we understand the power of print in creating tangible, memorable experiences for your customers. With years of experience in the design industry, we are proud to be one of the most trusted print design companies in India. Our team is passionate about delivering designs that not only look great but also effectively communicate your message.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Brush />,
      title: 'Expert Designers',
      description:
        'Our creative designers are skilled at crafting visually appealing designs that align with your brand\'s vision and objectives.',
    },
    {
      icon: <FormatPaint />,
      title: 'Attention to Detail',
      description:
        'We focus on every detail to ensure your prints stand out, from the typography to the colors, layout, and overall aesthetic.',
    },
    {
      icon: <Print />,
      title: 'Quality Prints',
      description:
        'We work with top-tier printing partners to deliver high-quality printed materials that reflect the professionalism of your brand.',
    },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Print Design Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer a wide range of print design services that cater to various needs. Whether you're launching a new brand or revamping your marketing materials, our services will help you create the perfect print solutions.
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <Description />,
              title: 'Brochure Design',
              description:
                'Our brochure design services focus on creating informative, visually engaging, and brand-consistent brochures. We design tri-folds, bi-folds, and custom formats that highlight your products, services, and unique selling points.',
            },
            {
              icon: <ContactPage />,
              title: 'Business Card Design',
              description:
                'Leave a lasting impression with professionally designed business cards. Our business card design services ensure that your contact details are presented in an elegant and memorable way that resonates with your brand.',
            },
            {
              icon: <LocalPostOffice />,
              title: 'Flyer and Poster Design',
              description:
                'Looking to promote an event, sale, or new product? Our flyer and poster design services deliver visually stunning designs that grab attention and communicate your message effectively. We offer designs in various sizes and layouts to suit your promotional needs.',
            },
            {
              icon: <MenuBook />,
              title: 'Packaging Design',
              description:
                'Great product packaging is a vital part of your branding strategy. Our packaging design services focus on creating attractive, functional, and eco-friendly packaging that not only protects your product but also tells your brand story.',
            },
            {
              icon: <MenuBook />,
              title: 'Catalog and Magazine Design',
              description:
                'We create engaging, full-color catalogs and magazines that showcase your offerings in an organized and visually appealing format. Our catalog and magazine design services ensure that your printed materials convey the professionalism and quality of your brand.',
            },
            {
              icon: <Assignment />,
              title: 'Stationery Design',
              description:
                'From letterheads to envelopes and everything in between, our stationery design services ensure that your everyday business materials are both functional and on-brand. We create designs that provide consistency and professionalism across all your business communications.',
            },
            {
              icon: <Campaign />,
              title: 'Banners and Signage Design',
              description:
                'Make an impact at events or in-store with our banners and signage design services. We craft large-scale designs that are visually striking, helping your business attract attention and convey your key messages clearly.',
            },
            {
              icon: <Mail />,
              title: 'Postcard and Invitation Design',
              description:
                'Send out invitations, postcards, or promotional mailers with designs that stand out. Our postcard and invitation design services ensure that your message is delivered beautifully and effectively to your target audience.',
            },
            {
              icon: <Assessment />,
              title: 'Annual Report Design',
              description:
                'Present your company\'s achievements and financial results in a professional manner with our annual report design services. We focus on creating clean, readable, and visually appealing reports that effectively communicate your business\'s progress.',
            },
            {
              icon: <DesignServices />,
              title: 'Custom Print Design',
              description:
                'Need a unique print solution? We offer custom print design services to cater to your specific needs, whether it\'s a special promotional item, event material, or other unique projects.',
            },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Why Invest in Print Design?
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    In today’s digital world, print design still holds immense value in capturing your audience’s attention. The tactile experience of holding a brochure or business card creates a deeper connection with your brand. High-quality print designs can elevate your marketing efforts and help you stand out from the competition.

    </Typography>
    <Typography variant="h4" align="center" gutterBottom>
    Get Started Today!
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    If you're searching for a print design company in India that delivers creativity, quality, and professionalism, look no further than Deevapayon. We are committed to bringing your vision to life through innovative print design services. Reach out to us today for a consultation, and let's create something extraordinary for your business!

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default PrintDesign;