// src/pages/BrandIdentityVisualBranding.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  ListAlt,
  TextFields,
  ListAltOutlined,
  TextFieldsOutlined,
  FormatPaint,
  Description,
  Camera,
  Chat,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Brand identity and visual branding services by Deevapayon.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BrandIdentityVisualBranding = () => {
  return (
    <>

<Helmet>
  <title>Brand Identity and Visual Branding Company in India | Deevapayon</title>
  <meta name="description" content="Looking for a brand identity and visual branding company in India? Deevapayon creates unique, memorable brand identities, logos, and visual assets. Contact us today!" />
  <meta name="keywords" content="brand identity and visual branding company in India, brand strategy, logo design, typography and font selection, color palette design, brand collateral design, social media branding, packaging design, website UI design, brand photography, brand guidelines" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Deevapayon: Your Brand Identity and Visual Branding Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we specialize in creating compelling brand identities that help businesses stand out. As a top brand identity and visual branding company in India, we combine strategic thinking and creative design to develop powerful visual brands. Our team works closely with clients to craft unique brand identities that align with their business goals.
              </Typography>
              <Typography variant="body1" paragraph>
              Whether you need a new brand identity or a visual rebranding, we provide tailored solutions that reflect your values and connect with your audience.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Brand identity and visual branding services by Deevapayon, a leading branding company in India"
                title="Deevapayon Brand Identity & Visual Branding Company in India - Expert Branding Services"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Brand Identity & Visual Branding Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading brand identity and visual branding company in India, we offer a range of services designed to create and elevate your brand’s visual presence. Our services include:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <ListAltOutlined />,
          title: 'Brand Strategy Development',
          description:
            'A successful brand starts with a strong strategy. Our brand strategy development services define your brand’s mission, vision, and positioning, ensuring your brand resonates with your target market and sets you up for long-term success.',
        },
        {
          icon: <Brush />,
          title: 'Logo Design & Development',
          description:
            'Your logo is the cornerstone of your brand. Our logo design and development services create unique, memorable logos that accurately reflect your business’s core values and identity, ensuring your brand stands out.',
        },
        {
          icon: <TextFieldsOutlined />,
          title: 'Typography and Font Selection',
          description:
            'Typography is crucial in shaping how your brand communicates. Our typography and font selection services help choose the perfect fonts that enhance your brand’s personality and ensure consistency across all platforms.',
        },
        {
          icon: <Palette />,
          title: 'Color Palette Design',
          description:
            'The right color palette speaks volumes about your brand. Our color palette design services help choose the ideal colors that evoke the right emotions, align with your brand identity, and provide a cohesive look across all visual assets.',
        },
        {
          icon: <FormatPaint />,
          title: 'Visual Brand Guidelines',
          description:
            'Consistency is key in branding. Our visual brand guidelines ensure your brand’s visual identity remains uniform across all platforms. We create detailed guidelines covering logo usage, color schemes, typography, and more to maintain brand consistency.',
        },
        {
          icon: <Description />,
          title: 'Brand Collateral Design',
          description:
            'We design professional brand collateral like business cards, brochures, letterheads, and more to help maintain your brand’s image in every customer interaction. We ensure all marketing materials align with your visual identity.',
        },
        {
          icon: <Share />,
          title: 'Social Media Branding',
          description:
            'In today’s digital world, your social media presence is a critical part of your branding. Our social media branding services include creating cohesive and eye-catching graphics that represent your brand and engage your audience across all platforms.',
        },
        {
          icon: <Web />,
          title: 'Packaging Design',
          description:
            'Our packaging design services help create packaging that not only protects your product but also serves as an effective marketing tool. We design attractive and functional packaging that showcases your brand’s identity.',
        },
        {
          icon: <Web />,
          title: 'Website and User Interface Design',
          description:
            'Your website is a vital element of your brand’s visual identity. We offer website and user interface design services to create websites that are not only visually appealing but also easy to navigate, ensuring an excellent user experience.',
        },
        {
          icon: <Chat />,
          title: 'Brand Messaging and Tone Development',
          description:
            'The tone of your communication matters as much as how you look. Our brand messaging and tone development services ensure that your brand voice aligns with your visual identity and appeals to your target audience effectively.',
        },
        {
          icon: <Camera />,
          title: 'Brand Photography and Videography',
          description:
            'Our brand photography and videography services create customized images and videos that capture your brand’s essence and help tell your story visually. We produce high-quality content that connects with your audience emotionally.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deevapayon as Your Brand Identity and Visual Branding Company in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      At Deevapayon, we are dedicated to crafting compelling, consistent, and visually engaging brand identities. Here's why we’re the best brand identity and visual branding company in India:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Palette />,
          title: 'Creative Expertise',
          description:
            'Our team of designers and strategists delivers innovative branding solutions that help you stand out.',
        },
        {
          icon: <Tune />,
          title: 'Customized Solutions',
          description:
            'We offer tailored brand strategies and designs that align with your unique business goals and values.',
        },
        {
          icon: <ListAlt />,
          title: 'Comprehensive Branding Services',
          description:
            'From strategy to execution, we offer end-to-end branding services that ensure consistency across all platforms.',
        },
        {
          icon: <FormatPaint />,
          title: 'Consistency Across Touchpoints',
          description:
            'We focus on creating cohesive brand identities that shine through in every customer interaction.',
        },
        {
          icon: <Groups />,
          title: 'Collaborative Approach',
          description:
            'We work closely with you to ensure your vision is brought to life exactly as you imagined.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    {/* <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Graphic Design Agency in India
    </Typography> */}
    <Typography variant="body1" align="center" paragraph>
    Transform your brand into a powerful visual identity with Deevapayon, a leading brand identity and visual branding company in India. Contact us today and let’s create a brand that makes a lasting impact.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default BrandIdentityVisualBranding;