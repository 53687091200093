// src/pages/WaterBillPayment.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  CheckCircle,
  AccountBalanceWallet,
  Security,
  TouchApp,
  History,
  Login,
  Receipt,
  Payment,
  Warning,
  AccessTime,
  ElectricCarOutlined,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Water Bill Payment Software.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const WaterBillPayment = () => {
  return (
    <>

<Helmet>
  <title>Water Bill Payment Software - Secure & Convenient Online Bill Payment</title>
  <meta
    name="description"
    content="Manage and pay your water bills securely and conveniently online through our Water Bill Payment Software. Pay anytime, anywhere with multiple payment options."
  />
  <meta
    name="keywords"
    content="Water Bill Payment, Water Bill Payment Software, Pay Water Bill Online, Secure Water Bill Payment, Online Water Bill Payment, Water Bill Payment Portal India, Water Bill Payment Service"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Water Bill Payment Software
              </Typography>
              <Typography variant="body1" paragraph>
              Our Water Bill Payment software offers a seamless and secure way to manage and pay your water bills online. We prioritize your convenience by providing an easy-to-use platform that allows you to settle your bills from the comfort of your home or while on the go.
              </Typography>
              <Typography variant="body1" paragraph>
              Whether you prefer to pay using your phone, tablet, or computer, our portal ensures a smooth and hassle-free experience, giving you control over your water bill payments anytime and anywhere.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Water Bill Payment Software - Pay Your Water Bills Online Securely"
                title="Water Bill Payment Software"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Use Our Water Bill Payment Software?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Our Water Bill Payment Software offers a fast, reliable, and hassle-free way to ensure your payments are always on time. Here’s why you should choose our platform:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
  {
    icon: <TouchApp />,
    title: 'Convenience at Your Fingertips',
    description:
      'Pay your water bills anytime, anywhere using your mobile phone, tablet, or computer. No need to visit the office or wait in line.',
  },
  {
    icon: <Security />,
    title: 'Secure Payment Process',
    description:
      'We use advanced encryption technologies to protect your personal and payment information, ensuring that your transaction is always safe.',
  },
  {
    icon: <AccountBalanceWallet />,
    title: 'Multiple Payment Methods',
    description:
      'Whether you prefer credit cards, debit cards, net banking, or UPI, our Water Bill Payment Software accepts a wide range of payment options to suit your preferences.',
  },
  {
    icon: <CheckCircle />,
    title: 'Instant Confirmation',
    description:
      'Once your payment is processed, you’ll receive an instant confirmation, giving you peace of mind that your bill is paid and up to date.',
  },
  {
    icon: <History />,
    title: 'Access to Payment History',
    description:
      'Easily view and download your payment history from the Software, making it simple to track your payments and keep records.',
  },
].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      How to Pay Your Water Bill Online?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <Login />,
              title: 'Login to the Software',
              description:
                'Start by visiting our Water Bill Payment Software. Log in to your account using your user credentials.',
            },
            {
              icon: <Receipt />,
              title: 'Enter Your Bill Details',
              description:
                'Once logged in, enter your customer number or bill details to retrieve your current bill amount.',
            },
            {
              icon: <AccountBalanceWallet />,
              title: 'Choose Your Payment Method',
              description:
                'Select your preferred payment method from a list of secure options.',
            },
            {
              icon: <Payment />,
              title: 'Make the Payment',
              description:
                'After entering the required payment information, confirm the details, and proceed to make your payment.',
            },
            {
              icon: <CheckCircle />,
              title: 'Confirmation & Receipt',
              description:
                'After your payment is successful, you will receive an instant payment receipt via email or SMS. You can also download it directly from the Software.',
            },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Benefits of Paying Your Water Bill Online
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
                {
                  icon: <AccessTime />,
                  title: 'Save Time',
                  description:
                    'No more waiting in line or making trips to the office. You can pay anytime, from anywhere.',
                },
                {
                  icon: <History />,
                  title: 'Track Your Payments',
                  description:
                    'With easy access to payment history, keeping track of your water bills has never been easier.',
                },
                {
                  icon: <ElectricCarOutlined />,
                  title: 'Environmentally Friendly',
                  description:
                    'Pay online and receive digital receipts, reducing the need for paper bills.',
                },
                {
                  icon: <Warning />,
                  title: 'Avoid Late Fees',
                  description:
                    'Set reminders or pay as soon as your bill arrives to avoid late payment penalties.',
                },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Customer Support
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    If you encounter any issues while using our Water Bill Payment Software, our dedicated customer support team is available to assist you. Whether you need help with payment processing or have questions about your bill, we’re here to help.

    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Contact us through our support page or call our toll-free helpline for immediate assistance.

    </Typography>


    <Typography variant="h4" align="center" gutterBottom>
    Start Using the Water Bill Payment SoftwareToday!
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Experience the convenience and efficiency of managing your water bill payments online. Visit our Water Bill Payment Software and take control of your payments today!

    </Typography>


    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default WaterBillPayment;