// src/pages/ECommerceServices.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  LocalShipping,
  Payments,
  AccountBalanceWallet,
  ViewList,
  ListAlt,
  BusinessCenter,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deevapayon, Ecommerce Services.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ECommerceServices = () => {
  return (
    <>

<Helmet>
  <title>Top E-commerce Service Provider in India | Boost Your Online Business</title>
  <meta
    name="description"
    content="Deeva Payon is a leading Ecommerce Services Provider in India. Our Agency offers Top Ecommerce services that ensure growth in traffic, leads, and sales."
  />
  <meta
    name="keywords"
    content="Ecommerce Service Provider in India, Ecommerce Service Provider, Ecommerce Website Development, Digital Marketing Services for Ecommerce, Payment Gateway Integration for Ecommerce"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                E-commerce Service Provider in India
              </Typography>
              <Typography variant="body1" paragraph>
              As a leading e-commerce service provider in India, we offer comprehensive solutions that help businesses thrive in the digital marketplace. Our services are crafted to improve your online presence, streamline operations, and boost sales growth across multiple platforms.
              </Typography>
              <Typography variant="body1" paragraph>
              By leveraging the latest tools and strategies, we ensure your e-commerce business stays competitive, enhances customer experiences, and drives sustainable growth, helping you succeed in a fast-evolving digital world.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="E-commerce services for boosting online sales and enhancing digital presence"
                title="Deevapayon, E-commerce Services for Business Growth"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our E-commerce Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We provide a wide range of e-commerce services that cater to the diverse needs of our clients.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <BusinessCenter />,
          title: 'E-commerce Strategy Development',
          description:
            'We collaborate with you to create a robust e-commerce strategy that aligns with your business objectives, ensuring a clear path to achieving your goals.',
        },
        {
          icon: <ListAlt />,
          title: 'Product Listing Management',
          description:
            'Our team expertly manages product listings across major marketplaces such as Amazon, Flipkart, and Myntra, ensuring optimal visibility and engagement.',
        },
        {
          icon: <ViewList />,
          title: 'Catalog Management',
          description:
            'We enhance your product catalogs with accurate descriptions, high-quality images, and proper categorization to attract potential customers effectively.',
        },
        {
          icon: <AccountBalanceWallet />,
          title: 'Marketplace Account Management',
          description:
            'Our dedicated account managers oversee your seller accounts on various platforms, optimizing performance and handling daily operations to maximize sales.',
        },
        {
          icon: <Campaign />,
          title: 'Digital Marketing Services',
          description:
            'We offer targeted digital marketing solutions, including SEO, SEM, and social media marketing, to drive traffic and increase conversions for your online store.',
        },
        {
          icon: <Web />,
          title: 'E-commerce Website Development',
          description:
            'Our team builds user-friendly and responsive e-commerce websites tailored to your brand’s identity and customer needs, ensuring a seamless shopping experience.',
        },
        {
          icon: <Payments />,
          title: 'Payment Gateway Integration',
          description:
            'We facilitate secure payment processing by integrating reliable payment gateways that offer multiple payment options for your customers.',
        },
        {
          icon: <LocalShipping />,
          title: 'Logistics and Fulfillment Solutions',
          description:
            'Our logistics services ensure timely delivery of products with efficient inventory management and order processing systems.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Deevapayon?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
        {
          icon: <People />,
          title: 'Expertise',
          description:
            'With extensive experience in the e-commerce sector, we understand the complexities of online selling and provide solutions that deliver measurable results.',
        },
        {
          icon: <Tune />,
          title: 'Customized Solutions',
          description:
            'We recognize that every business is unique; hence, we offer tailored services that specifically address your requirements.',
        },
        {
          icon: <IntegrationInstructions />,
          title: 'End-to-End Support',
          description:
            'From strategy development to execution, we provide comprehensive support throughout your e-commerce journey.',
        },
        {
          icon: <Savings />,
          title: 'Cost-Effective Services',
          description:
            'As a leading e-commerce service provider in India, we offer competitive pricing without compromising on quality or service delivery.',
        },
        {
          icon: <VerifiedUser />,
          title: 'Proven Track Record',
          description:
            'Our successful partnerships with numerous brands across various industries demonstrate our commitment to excellence and client satisfaction.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best E-commerce Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Elevate your online business with our expert e-commerce solutions! Contact us today to learn how our services as an e-commerce service provider in India can help you achieve growth and success in the digital marketplace.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default ECommerceServices;