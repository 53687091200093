import React from 'react';
import { Box, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import AboutImage from '../../../assest/About-us-hero.jpg'; // Ensure this path is correct

const AboutUs = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30vh',
        backgroundImage: `url(${AboutImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        textAlign: 'center',
        color: 'white',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
        },
      }}
    >
      {/* Content */}
      <Box sx={{ position: 'relative', zIndex: 2 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '1.8rem', md: '2.5rem' },
          }}
        >
          We are a software development company
        </Typography>
        {/* Downward Arrow Icon */}
        <KeyboardArrowDown sx={{ fontSize: 40, marginTop: 2 }} />
      </Box>
    </Box>
  );
};

export default AboutUs;
