// src/pages/AffiliateMarketing.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Verified,
  ListAlt,
  Chat,
  MonetizationOn,
  Public,
  Shield,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Affiliate Marketing Company in India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AffiliateMarketing = () => {
  return (
    <>

<Helmet>
  <title>Affiliate Marketing Company in India | Deevapayon</title>
  <meta name="description" content="Looking for a trusted affiliate marketing company in India? Deevapayon provides customized affiliate programs, recruitment, and optimization to grow your revenue." />
  <meta name="keywords" content="Affiliate marketing company in India, Affiliate program strategy, Affiliate recruitment and management, Affiliate marketing services, Performance-based affiliate marketing, Affiliate marketing optimization, Digital affiliate marketing solutions, Affiliate marketing experts, Affiliate program analytics" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Affiliate Marketing Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              Looking to grow your business with affiliate marketing? As a top affiliate marketing company in India, Deevapayon specializes in creating effective and results-driven affiliate programs. Our expert team works with you to design customized campaigns that maximize revenue, enhance brand visibility, and increase conversions.
              </Typography>
              <Typography variant="body1" paragraph>
              By leveraging affiliate partnerships, we help you reach new audiences and achieve scalable, long-term growth. Let us turn your affiliate marketing efforts into a powerful growth engine.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Affiliate Marketing Services by Deevapayon - India"
                title="Affiliate Marketing Company in India - Deevapayon"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us as Your Affiliate Marketing Company in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      At Deevapayon, we combine industry expertise with innovative strategies to build successful affiliate marketing programs. Here’s why we are the preferred affiliate marketing company in India:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <Tune />,
              title: 'Custom Affiliate Strategies',
              description:
                'We tailor affiliate marketing plans that align with your brand goals, ensuring optimal results through strategic partnerships and campaigns.',
            },
            {
              icon: <Groups />,
              title: 'Affiliate Recruitment & Management',
              description:
                'Our team finds and manages top-performing affiliates to grow your network and drive targeted traffic, enhancing your program\'s overall performance.',
            },
            {
              icon: <BarChart />,
              title: 'Advanced Tracking & Reporting',
              description:
                'We use cutting-edge tracking tools and analytics to measure affiliate performance, providing you with actionable insights to improve future campaigns.',
            },
            {
              icon: <Verified />,
              title: 'Proven Success',
              description:
                'With years of experience, we’ve helped businesses across industries achieve significant growth through optimized affiliate marketing programs.',
            },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Affiliate Marketing Services
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <ListAlt />,
              title: 'Affiliate Program Strategy & Setup',
              description:
                'We develop an affiliate marketing strategy tailored to your business’s goals, setting up commission structures, selecting the right affiliates, and implementing the best tools for maximum performance.',
            },
            {
              icon: <Groups />,
              title: 'Affiliate Recruitment & Partner Management',
              description:
                'We recruit top affiliates who align with your brand and drive quality traffic. Our expert management ensures smooth operations, with continuous support for affiliates to maximize results.',
            },
            {
              icon: <Tune />,
              title: 'Affiliate Program Optimization',
              description:
                'We analyze key metrics and continuously optimize your affiliate program to ensure the best possible ROI. From commission adjustments to performance-based strategies, we fine-tune every aspect of your campaign.',
            },
            {
              icon: <BarChart />,
              title: 'Comprehensive Tracking & Analytics',
              description:
                'We use advanced tracking software to monitor your affiliate marketing performance, providing detailed reports on clicks, conversions, and sales. This data helps us adjust strategies and maximize effectiveness.',
            },
            {
              icon: <Chat />,
              title: 'Affiliate Communication & Support',
              description:
                'Maintaining strong relationships with affiliates is essential. We handle regular communication, provide affiliate resources, and offer support to keep your network motivated and engaged.',
            },
            {
              icon: <MonetizationOn />,
              title: 'Performance-Based Payment Models',
              description:
                'We implement flexible payment structures, including Pay-Per-Sale (PPS), Pay-Per-Lead (PPL), and Pay-Per-Click (PPC), ensuring mutually beneficial partnerships that drive results for your business.',
            },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Benefits of Affiliate Marketing for Your Business
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Affiliate marketing offers numerous advantages, including:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
            {
              icon: <Savings />,
              title: 'Cost-Effective',
              description:
                'You only pay for actual results, ensuring a high return on investment.',
            },
            {
              icon: <TrendingUp />,
              title: 'Scalable Growth',
              description:
                'Easily scale your program as your business grows by recruiting more affiliates and expanding reach.',
            },
            {
              icon: <Public />,
              title: 'Wider Market Reach',
              description:
                'Access new customer bases through your affiliates’ networks.',
            },
            {
              icon: <Shield />,
              title: 'Risk-Free',
              description:
                'With performance-based models, affiliate marketing offers minimal risk while maximizing rewards.',
            },
        

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Why Affiliate Marketing is Crucial for Your Business
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Affiliate marketing is one of the most effective ways to drive traffic, build brand awareness, and generate sales. It offers an affordable way to tap into the power of digital marketing while leveraging the expertise of affiliates. As the leading affiliate marketing company in India, we help businesses scale with confidence and maximize the potential of affiliate partnerships.

    </Typography>
    <Typography variant="h4" align="center" gutterBottom>
    Partner with Deevapayon – Leading Affiliate Marketing Company in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Ready to take your business to the next level? Partner with [Your Company Name], the best affiliate marketing company in India, and start building a profitable affiliate program today. Our team will help you grow your business with a comprehensive strategy, tracking, and support for long-term success.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default AffiliateMarketing;