import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton, Divider } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#0d47a1',
        color: '#fff',
        padding: '4rem 0 2rem',
        borderTop: '4px solid #42a5f5',
        marginTop: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          {/* About Company */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
              Deeva Payon
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 1.8 }}>
              Deeva Payon is a leading provider of B2B, B2C, and API software solutions, specializing in fintech, digital marketing, and application development.
            </Typography>
          </Grid>

          {/* Our Services */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
              Our Services
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', paddingLeft: 0 }}>
              <li><Link href="/services/b2bsoftware" color="inherit" underline="hover">B2B Software</Link></li>
              <li><Link href="/services/B2CSoftwere" color="inherit" underline="hover">B2C Software</Link></li>
              <li><Link href="/services/apiservices" color="inherit" underline="hover">API Services</Link></li>
              <li><Link href="/services/fintechsoftweredevelopment" color="inherit" underline="hover">FinTech Technology</Link></li>
              <li><Link href="/services/andriodapp" color="inherit" underline="hover">Application Development</Link></li>
              <li><Link href="/services/websitedevelopment" color="inherit" underline="hover">Website Development</Link></li>
              <li><Link href="/services/softwaredevelopment" color="inherit" underline="hover">Software Development</Link></li>
              <li><Link href="/services/digitalmarketing" color="inherit" underline="hover">Digital Marketing</Link></li>
            </Box>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
              Quick Links
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', paddingLeft: 0 }}>
              <li><Link href="/about" color="inherit" underline="hover">About Us</Link></li>
              <li><Link href="/career" color="inherit" underline="hover">Career</Link></li>
              <li><Link href="/blog" color="inherit" underline="hover">Blogs</Link></li>
              <li><Link href="/contact" color="inherit" underline="hover">Contact Us</Link></li>
            </Box>
          </Grid>

          {/* Policies & Social Media */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#fff' }}>
              Policies
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Link href="/PrivacyPolicy" color="inherit" underline="hover">Privacy Policy</Link>
              <Link href="/RefundPolicy" color="inherit" underline="hover">Refund Policy</Link>
              <Link href="/Term&Condition" color="inherit" underline="hover">Terms & Conditions</Link>
            </Box>
            <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
              <IconButton href="https://www.facebook.com/people/Deeva-Payon-Global-Pvt-Ltd/61565814323246/" color="inherit"><Facebook /></IconButton>
              <IconButton href="https://x.com/deevapayon" color="inherit"><Twitter /></IconButton>
              <IconButton href="https://www.instagram.com/deevapayon/" color="inherit"><Instagram /></IconButton>
              <IconButton href="https://www.linkedin.com/company/deevapayon-private-limited" color="inherit"><LinkedIn /></IconButton>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '1rem 0', backgroundColor: '#42a5f5' }} />
        <Typography variant="body2" align="center">
          &copy; {new Date().getFullYear()} Deeva Payon. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
