// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import B2BSoftware from './Services/B2BSoftware';
import AndroidAppDevelopment from './Services/androidapplicationdevelopment';
import WebsiteDevelopment from './Services/websitedevelopment';
import GraphicDesign from './Services/graphicdesign';
import FintechSoftwareDevelopment from './Services/fintechsoftwaredevelopment';
import SoftwareDevelopment from './Services/softwaredevelopment';
import B2CSoftwere from './Services/B2CSoftwere';
import Blog from './pages/Blog';
import Careers from './pages/Career';
import DigitalMarketing from './Services/DigitalMarketing';
import AboutUs from './pages/AboutNew';
import ApiServices from './Services/api-services';
import CloudSolutions from './Services/cloudsolutions';
import CRMSolutions from './Services/crmsolutions';
import ECommerceServices from './Services/e-commerceservices';
import SalesforceServices from './Services/salesforceservices';
import SocialMediaMarketing from './Services/SocialMediaMarketing';
import SEO from './Services/SEO';
import GoogleAds from './Services/GoogleAds';
import MetaAds from './Services/MetaAds';
import FlutterDevelopment from './Services/FlutterDevelopment';
import IOSDevelopment from './Services/IOSDevelopment';
import WordPressDevelopment from './Services/WordPressDevelopment';
import StaticDynamicWebDevelopment from './Services/StaticDynamicWebDevelopment';
import BlogWebsiteDevelopment from './Services/BlogWebsiteDevelopment';
import FAQ from './pages/FAQ';
import ScrollToTopBotton from './ScrollToTop/ScrollToTop'

import WhatsApp from './WhatsApp/WhatsApp';
import AepsApiPage from './api-service/aepsapi';
import BBPSApi from './api-service/BBPSApi';
import DmtApi from './api-service/DmtApi';
import UpiPaymentApi from './api-service/UipiPaymentApi';
import PayoutApi from './api-service/PayoutApi';
import PancardApi from './api-service/PancardApi';
import WhatsappApi from './api-service/WhatsappApi';
import AadhaarVerificationApi from './api-service/AadhaarVerificationApi';
import RechargeApi from './api-service/RechargeApi';
import ScrollToTop from './components/ScroolToTop';
import PrivacyPolicy from './Services/PrivacyPolicy';
import Refund from './Services/Refund';
import TermCondition from './Services/TermCondition';
import Cart from './pages/Cart';
import Purchase from './pages/Purchase';

import BlogPostFutureFintech from './blog/BlogPostFutureFintech';
import MicroATMblog from './blog/MicroATMblog';
import SDPracticesblog from './blog/SDPracticesblog';
import HostingServer from './Services/HostingServer';
import Domain from './Services/Domain';
import HRMSystem from './Services/HRMSystem';
import HospitalManagement from './Services/HospitalManagement';
import SchoolManagement from './Services/SchoolManagement';
import HotelManagement from './Services/HotelManagement';
import EnterpriseSoftware from './Services/enterprisesoftware';
import MobileAppDevelopment from './Services/MobileAppDevelopment';
import SoftwareTesting from './Services/SoftwareTesting';
import MobileRecharge from './Services/MobileRecharge';
import DTHService from './Services/DTHService';
import BBPSService from './Services/BBPSService';
import AEPSPage from './Services/AEPSPage';
import NSDLPanCard from './Services/NSDLPanCard';
import RailwayTicketBooking from './Services/RailwayTicketBooking';
import BusTicketBooking from './Services/BusTicketBooking';
import ElectricityBillPayments from './Services/ElectricityBillPayments';
import WaterBillPayment from './Services/WaterBillPayment';
import GasBillPayment from './Services/GasBillPayment';
import FastagServices from './Services/FastagServices';
import DMTPay from './Services/DMTPay';
import PayoutAPI from './Services/PayoutAPI';
import WhiteLabelSoftware from './Services/WhiteLabelSoftware';
import AdminPanelDevelopment from './Services/AdminPanelDevelopment';
import APIPanelIntegration from './Services/APIPanelIntegration';
import CRMSystems from './Services/CRMSystems';
import ERPSolutions from './Services/ERPSolutions';
import PayPerClick from './Services/Pay-Per-Click';
import ContentMarketing from './Services/ContentMarketing';
import EmailMarketing from './Services/EmailMarketing';
import AffiliateMarketing from './Services/AffiliateMarketing';
import OnlineReputationManagement from './Services/OnlineReputationManagement';
import LogoDesign from './Services/LogoDesign';
import BrandIdentityVisualBranding from './Services/BrandIdentity&VisualBranding';
import WebsiteUserInterface from './Services/Website&UserInterface';
import PrintDesign from './Services/PrintDesign';
import SocialMediaGraphics from './Services/SocialMediaGraphics';
import InfographicsDesign from './Services/InfographicsDesign';
import PackagingDesign from './Services/PackagingDesign';
import BusinessCardDesign from './Services/BusinessCard';
import PresentationDesign from './Services/PresentationDesign';
import Paymentgatewayservices from './Services/Paymentgateway';
import BannerAdvertisementDesign from './Services/Banner&AdvertisementDesign';
import IllustrationsCustomArtwork from './Services/Illustrations&CustomArtwork';


function App() {
  return (
    <Router>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Routes>

        <Route path="/scrollToTopBotton" element= {<ScrollToTopBotton/>}/>

          {/* Main */}
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route path="/aboutnew" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/services/andriodapp" element={<AndroidAppDevelopment/>} />
          <Route path="/services/B2CSoftwere" element={<B2CSoftwere/>} />
          <Route path="/services/googleads" element={<GoogleAds />} />
          <Route path="/services/metaads" element={<MetaAds />} />
          <Route path="/services/FlutterDevelopment" element={<FlutterDevelopment/>} />
          <Route path="/services/IOSDevelopment" element={<IOSDevelopment />} />
          <Route path="/services/WordPressDevelopment" element={<WordPressDevelopment />} />
          <Route path="/services/StaticDynamicWebDevelopment" element={<StaticDynamicWebDevelopment />} />
          <Route path="/services/BlogWebsiteDevelopment" element={<BlogWebsiteDevelopment />} />

          {/* Software Development */}
          <Route path="/services/software-development-company/" element={<SoftwareDevelopment/>} />
          <Route path="/services/mobile-app-development-company/" element={< MobileAppDevelopment/>} />
          <Route path="/services/website-development-company/" element={<WebsiteDevelopment/>} />
          <Route path="/services/cloud-solutions-company" element={<CloudSolutions />} />
          <Route path="/services/crm-software-company" element={<CRMSolutions />} />
          <Route path="/services/ecommerce-service-Provider" element={<ECommerceServices />} />
          <Route path="/services/software-testing-company" element={< SoftwareTesting/>} />
          <Route path="/services/api-service-provider" element={<ApiServices />} />

          {/* Fintech Services  */}
          <Route path="/services/fintech-softwere-development-company" element={<FintechSoftwareDevelopment/>}/>
          <Route path="/services/mobile-recharge-software-company" element={< MobileRecharge/>} />
          <Route path="/services/dth-service-provider" element={< DTHService/>} />
          <Route path="/services/bbps-service-provider-company" element={< BBPSService/>} />
          <Route path="/services/aeps-software-development-company/" element={< AEPSPage/>} />
          <Route path="/services/nsdl-pan-card-api-provider" element={< NSDLPanCard/>} />
          <Route path="/services/railway-ticket-booking-software-company" element={< RailwayTicketBooking/>} />
          <Route path="/services/bus-ticket-booking-software-development/" element={< BusTicketBooking/>} />
          <Route path="/services/electricity-bill-payment-software/" element={< ElectricityBillPayments/>} />
          <Route path="/services/water-bill-payment-software/" element={< WaterBillPayment/>} />
          <Route path="/services/gas-bill-payment-software-company/" element={< GasBillPayment/>} />
          <Route path="/services/fastag-software-company/" element={< FastagServices/>} />
          <Route path="/services/dmt-money-transfer-software" element={< DMTPay/>} />
          <Route path="/services/payout-api-provider" element={< PayoutAPI/>} />

          {/* B2B Software Solutions */}
          <Route path="/services/b2b-software-company" element={<B2BSoftware/>} />
          <Route path="/services/white-label-software-solutions" element={<WhiteLabelSoftware/>} />
          <Route path="/services/admin-panel-development-and-management-services" element={<AdminPanelDevelopment/>} />
          <Route path="/services/api-panel-integration-and-management-services" element={<APIPanelIntegration/>} />

          {/* Enterprise Software Development */}
          <Route path="/services/enterprise-software-development-company" element={< EnterpriseSoftware/>} />
          <Route path="/services/crm-software-company" element={<CRMSystems />} />
          <Route path="/services/hrm-software-company" element={<HRMSystem />} />
          <Route path="/services/erp-software-company" element={<ERPSolutions />} />
          {/* <Route path="/services/hospital-management" element={<HospitalManagement />} />
          <Route path="/services/school-management" element={<SchoolManagement />} />
          <Route path="/services/hotel-management" element={<HotelManagement />} /> */}
          


          {/* Hosting & Domain Services */}
          <Route path="/services/domain-service-provider" element={<Domain />} />
          <Route path="/services/hosting-server-provider" element={<HostingServer />} />
          

          {/* Salesforce Services */}
          <Route path="/services/salesforce-service-provider" element={<SalesforceServices />} />

          {/* Digital Marketing */}
          <Route path="/services/digital-marketing-agency" element={<DigitalMarketing />} />
          <Route path="/services/best-seo-company-india" element={<SEO />} />
          <Route path="/services/ppc-advertising-company" element={<PayPerClick />} />
          <Route path="/services/social-media-marketing-company" element={<SocialMediaMarketing />} />
          <Route path="/services/content-marketing-company" element={<ContentMarketing />} />
          <Route path="/services/email-marketing-agency" element={<EmailMarketing />} />
          <Route path="/services/affiliate-marketing-company" element={<AffiliateMarketing />} />
          <Route path="/services/online-reputation-management-agency" element={<OnlineReputationManagement />} />

          {/* Graphic Design */}
          <Route path="/services/graphic-design-company" element={<GraphicDesign/>} />
          <Route path="/services/logo-design-company" element={<LogoDesign />} />
          <Route path="/services/brand-identity-and-visual-branding-company" element={<BrandIdentityVisualBranding />} />
          <Route path="/services/website-and-user-interface-design-company" element={<WebsiteUserInterface />} />
          <Route path="/services/print-design-company" element={<PrintDesign />} />
          <Route path="/services/social-media-graphics-design-company" element={<SocialMediaGraphics />} />
          <Route path="/services/infographics-design-company" element={<InfographicsDesign />} />
          <Route path="/services/packaging-design-company" element={<PackagingDesign />} />
          <Route path="/services/business-card-design-company" element={<BusinessCardDesign />} />
          <Route path="/services/banner-and-advertisement-design-company" element={<PresentationDesign />} />
          <Route path="/services/illustrations-and-custom-artwork-company" element={<BannerAdvertisementDesign />} />
          <Route path="/services/presentation-design-company" element={<IllustrationsCustomArtwork />} />

          {/* Payment Gateway Services */}
          <Route path="/services/payment-gateway-services" element={<Paymentgatewayservices />} />
          










          <Route path="/pages/faq" element={<FAQ/>} />
          <Route path="/api-service/aeps" element={<AepsApiPage />} />
          <Route path="/api-service/bbps" element={<BBPSApi/>} />
          <Route path="/api-service/dmt" element={<DmtApi />} />
          <Route path="/api-service/upi" element={<UpiPaymentApi />} />
          <Route path="/api-service/payout" element={<PayoutApi />} />
          <Route path="/api-service/pancard" element={<PancardApi />} />
          <Route path="/api-service/whatsapp" element={<WhatsappApi />} />
          <Route path="/api-service/aadhaar" element={<AadhaarVerificationApi />} />
          <Route path="/api-service/recharge" element={<RechargeApi />} />
          
          {/* Blog Routes */}
          <Route path="/blog/future-of-fintech-2025" element={<BlogPostFutureFintech/>} />
          <Route path="/blog/MicroATMblog" element={<MicroATMblog/>} />
          <Route path="/blog/SDPracticesblog" element={<SDPracticesblog/>} />

          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
          <Route path="/RefundPolicy" element={<Refund/>}/>
          <Route path="/Term&Condition" element={<TermCondition/>}/>

          <Route path="/cart" element={<Cart/>} />
          <Route path="/purchase" element={<Purchase/>} />


        </Routes>
        <WhatsApp/>
        <ScrollToTopBotton/>
        
        <ScrollToTop/>
      </Box>
    </Router>
  );
}

export default App;