// src/pages/CRMSolutions.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  TouchApp,
  Favorite,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/CRM Solutions.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CRMSolutions = () => {
  return (
    <>

<Helmet>
  <title>Leading CRM Software Company in India | Deevapayon</title>
  <meta
    name="description"
    content="Our CRM software company in India specializes in customized customer relationship management solutions to enhance engagement and streamline processes."
  />
  <meta
    name="keywords"
    content="CRM software company in India, customer relationship management solutions, custom CRM development, cloud based CRM solutions, CRM integration services, mobile CRM applications"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                CRM software company in India
              </Typography>
              <Typography variant="body1" paragraph>
              As a leading CRM software company in India, we provide advanced customer relationship management solutions that enhance your business operations. Our CRM software is designed to meet the specific needs of different industries, offering features that boost customer engagement, streamline processes, and increase profitability.
              </Typography>
              <Typography variant="body1" paragraph>
              With our innovative solutions, we help businesses improve efficiency, strengthen customer relationships, and drive growth, ensuring a competitive edge in the marketplace.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Deevapayon, CRM Solutions for Effective Customer Management"
                title="Comprehensive CRM solutions for enhancing customer relationships and business growth"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our CRM Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      We offer a diverse range of CRM services that cater to different aspects of customer relationship management.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <SettingsApplications />,
          title: 'Custom CRM Development',
          description:
            'We create bespoke CRM solutions that align with your specific business requirements, ensuring a perfect fit for your operations.',
        },
        {
          icon: <Cloud />,
          title: 'Cloud-Based CRM Solutions',
          description:
            'Our cloud-based CRM systems provide flexibility and accessibility, allowing you to manage customer interactions anytime, anywhere.',
        },
        {
          icon: <Link />,
          title: 'CRM Integration Services',
          description:
            'We seamlessly integrate our CRM software with your existing systems, ensuring a smooth transition and enhancing overall efficiency.',
        },
        {
          icon: <PhoneIphone />,
          title: 'Mobile CRM Applications',
          description:
            'Stay connected with your customers on the go through our intuitive mobile CRM applications that empower your sales team with real-time data.',
        },
        {
          icon: <BarChart />,
          title: 'Analytics and Reporting',
          description:
            'Gain valuable insights into your customer data with our advanced analytics and reporting tools, helping you make informed business decisions.',
        },
        {
          icon: <SupportAgent />,
          title: 'Customer Support and Training',
          description:
            'Our dedicated support team is always available to assist you, along with comprehensive training programs to help your staff maximize the use of our CRM solutions.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deevapayon?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted CRM Solutions company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        
        {
          icon: <People />,
          title: 'Expertise',
          description:
            'With years of experience in the industry, we understand the intricacies of customer relationship management and offer solutions that drive results.',
        },
        {
          icon: <TrendingUp />,
          title: 'Scalability',
          description:
            'Our CRM solutions are designed to grow with your business, providing the necessary tools to adapt to changing market demands.',
        },
        {
          icon: <TouchApp />,
          title: 'User-Friendly Interface',
          description:
            'We prioritize user experience, ensuring our CRM software is intuitive and easy to navigate for all team members.',
        },
        {
          icon: <Favorite />,
          title: 'Enhanced Customer Engagement',
          description:
            'Our solutions enable you to build lasting relationships with your customers by providing personalized experiences and timely communication.',
        },
        {
          icon: <Savings />,
          title: 'Cost-Effective Solutions',
          description:
            'As a leading CRM software company in India, we offer competitive pricing without compromising on quality or functionality.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best CRM Solutions Agency in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Optimize your customer relationships and unlock business growth with our advanced CRM solutions! Contact us today to explore how our services can transform your organization.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default CRMSolutions;