import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';
import Logo from '../assest/Deevapayon logonew.jpeg';
import ContactFormPopup from './ContactFormPopup';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleContactFormOpen = () => {
    setIsContactFormOpen(true);
    navigate("?popup=open");
  };

  const handleContactFormClose = () => {
    setIsContactFormOpen(false);
    navigate(location.pathname);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('popup') === 'open') {
      setIsContactFormOpen(true);
    }
  }, [location]);

  // Services Menu Data
  const servicesMenu = [
    {
      category: 'Software Development',
      "path": "/services/software-development-company",
      subcategories: [
        { name: 'Mobile App Development', path: '/services/mobile-app-development-company/' },
        { name: 'Website Development', path: '/services/website-development-company/' },
        { name: 'Cloud Solutions', path: '/services/cloud-solutions-company' },
        { name: 'CRM Solutions', path: '/services/crm-software-company' },
        { name: 'E-Commerce Services', path: '/services/ecommerce-service-Provider' },
        { name: 'Software Testing', path: '/services/software-testing-company' },
        { name: 'API Development', path: '/services/api-service-provider' },
      ],
    },
    {
      category: 'Fintech Software Services',
      subcategories: [
        { name: 'Mobile Recharge', path: '/services/mobile-recharge-software-company' },
        { name: 'DTH (Direct-to-Home TV)', path: '/services/dth-service-provider' },
        { name: 'BBPS Services', path: '/services/bbps-service-provider-company' },
        { name: 'AEPS (Aadhaar Enabled Payment System)', path: '/services/aeps-software-development-company/' },
        { name: 'Pan Card Services', path: '/services/nsdl-pan-card-api-provider' },
        { name: 'Railway Ticket Booking', path: '/services/railway-ticket-booking-software-company' },
        { name: 'Bus Ticket Booking', path: '/services/bus-ticket-booking-software-development/' },
        { name: 'Electricity Bill Payments', path: '/services/electricity-bill-payment-software/' },
        { name: 'Water Bill Payments', path: '/services/water-bill-payment-software/' },
        { name: 'Gas Bill Payments', path: '/services/gas-bill-payment-software-company/' },
        { name: 'Fastag Services', path: '/services/fastag-software-company/' },
        { name: 'DMT Pay', path: '/services/dmt-money-transfer-software' },
        { name: 'Payout', path: '/services/payout-api-provider' },
      ],
    },
    {
      category: 'B2B & Enterprise Software Solutions',
      subcategories: [
        { name: 'White Label Software Solutions', path: '/services/white-label-software-solutions' },
        { name: 'Admin Panel Development & Management', path: '/services/admin-panel-development-and-management-services' },
        { name: 'API Panel Integration & Management', path: '/services/api-panel-integration-and-management-services' },
        { name: 'CRM Systems', path: '/services/crm-software-company' },
        { name: 'HRM Systems', path: '/services/hrm-software-company' },
        { name: 'ERP Solutions', path: '/services/erp-software-company' },
      ],
    },

    {
      category: 'Hosting & Domain Services', // Consolidated Category
      subcategories: [
        { name: 'Hosting Server Services', path: '/services/hosting-server-provider' },
        { name: 'Domain Management Services', path: '/services/domain-service-provider' },
        { name: 'Salesforce Services', path: '/services/salesforce-service-provider' },
        { name: 'Payment Gateway Services', path: '/services/payment-gateway-services' },
      ],
    },
    {
      category: 'Digital Marketing',
      subcategories: [
        { name: 'Search Engine Optimization (SEO)', path: '/services/best-seo-company-india' },
        { name: 'Pay-Per-Click Advertising (PPC)', path: '/services/ppc-advertising-company' },
        { name: 'Social Media Marketing (SMM)', path: '/services/social-media-marketing-company' },
        { name: 'Content Marketing', path: '/services/content-marketing-company' },
        { name: 'Email Marketing', path: '/services/email-marketing-agency' },
        { name: 'Affiliate Marketing', path: '/services/affiliate-marketing-company' },
        { name: 'Online Reputation Management (ORM)', path: '/services/online-reputation-management-agency' },
      ],
    },
    {
      category: 'Graphic Design',
      subcategories: [
        { name: 'Logo Design', path: '/services/logo-design-company' },
        { name: 'Brand Identity & Visual Branding', path: '/services/brand-identity-and-visual-branding-company' },
        { name: 'Website & User Interface Design', path: '/services/website-and-user-interface-design-company' },
        { name: 'Print Design', path: '/services/print-design-company' },
        { name: 'Social Media Graphics', path: '/services/social-media-graphics-design-company' },
        { name: 'Infographics Design', path: '/services/infographics-design-company' },
        { name: 'Packaging Design', path: '/services/packaging-design-company' },
        { name: 'Business Card Design', path: '/services/business-card-design-company' },
        { name: 'Banner & Advertisement Design', path: '/services/banner-and-advertisement-design-company' },
        { name: 'Illustrations & Custom Artwork', path: '/services/illustrations-and-custom-artwork-company' },
        { name: 'Presentation Design', path: '/services/presentation-design-company' },
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          padding: '5px 0',
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 25,
        }}
      >
        <Typography variant="body2">
          📞 +91 9785909785 | ✉️ info@deevapayon.com
        </Typography>
        <Box>
          <IconButton component="a" href="https://www.facebook.com/people/Deeva-Payon-Global-Pvt-Ltd/61565814323246/" target="_blank" color="inherit">
            <Facebook />
          </IconButton>
          <IconButton component="a" href="https://x.com/deevapayon" target="_blank" color="inherit">
            <Twitter />
          </IconButton>
          <IconButton component="a" href="https://www.linkedin.com/company/deevapayon-private-limited" target="_blank" color="inherit">
            <LinkedIn />
          </IconButton>
          <IconButton component="a" href="https://www.instagram.com/deevapayon/" target="_blank" color="inherit">
            <Instagram />
          </IconButton>
        </Box>
      </Box>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: '#fff', boxShadow: isScrolled ? '0px 2px 4px rgba(0,0,0,0.2)' : 'none', transition: '0.3s', color: "#000" }}
      >
        <Container maxWidth="lg">
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component={Link} to="/">
              <img src={Logo} alt="Logo" style={{ height: '55px' }} />
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'flex', }, gap: 2 }}>
              <Button color="inherit" component={Link} to="/" sx={{ fontSize: '1rem' }}>
                Home
              </Button>
              <Button color="inherit" component={Link} to="/about" sx={{ fontSize: '1rem' }}>
                About Us
              </Button>
              <Button
  color="inherit"
  onMouseEnter={handleMenuOpen}
  component={Link}
  to="/services" sx={{ fontSize: '1rem' }}
>
  Services
</Button>

{/* Dropdown Menu */}
<Menu
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
  MenuListProps={{
    onMouseLeave: handleMenuClose,
    sx: {
      padding: '16px', // Add padding around the menu
      width: 'auto', // Adjust width automatically based on content
      maxHeight: '400px', // Limit the height of the dropdown
      overflowY: 'auto', // Enable vertical scrolling if needed
      backgroundColor: '#f9f9f9', // Light background for better contrast
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Add subtle shadow
    },
  }}
  PaperProps={{
    elevation: 3,
    sx: {
      overflow: 'hidden',
      borderRadius: 2,
      marginTop: '8px', // Add space above the dropdown
    },
  }}
  anchorOrigin={{
    vertical: 'bottom', // Position the dropdown below the button
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top', // Align the dropdown with the top of the anchor
    horizontal: 'left',
  }}
>
  {/* Render Main Categories and Subcategories */}
  <div style={{ display: 'flex', gap: '32px' }}>
    {servicesMenu.map((category, index) => (
      <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        {/* Main Category */}
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'bold',
            marginBottom: '8px',
            color: '#333', // Darker text for better readability
            cursor: 'pointer', // Make it look clickable
            transition: 'color 0.3s ease', // Smooth hover effect
            '&:hover': {
              color: '#007bff', // Highlight on hover
            },
          }}
        >
          {category.category}
        </Typography>

        {/* Subcategories */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {category.subcategories.map((subcategory, idx) => (
            <MenuItem
              key={idx}
              component={Link}
              to={subcategory.path}
              onClick={handleMenuClose}
              sx={{
                fontSize: '0.9rem',
                padding: '8px 12px', // Smaller padding for compact look
                borderRadius: '4px', // Rounded corners for a modern feel
                backgroundColor: '#fff', // White background for each item
                transition: 'background-color 0.3s ease', // Smooth hover effect
                '&:hover': {
                  backgroundColor: '#e0e0e0', // Light gray on hover
                },
              }}
            >
              {subcategory.name}
            </MenuItem>
          ))}
        </div>
      </div>
    ))}
  </div>
</Menu>
              <Button color="inherit" component={Link} to="/blog" sx={{ fontSize: '1rem' }}>
                Blog
              </Button>
              <Button color="inherit" component={Link} to="/career" sx={{ fontSize: '1rem' }}>
                Career
              </Button>
              <Button color="inherit" component={Link} to="/contact" sx={{ fontSize: '1rem' }}>
                Contact
              </Button>
            </Box>
            <Button variant="contained" color="secondary" onClick={handleContactFormOpen}>
        Get In Touch
      </Button>

      <ContactFormPopup isOpen={isContactFormOpen} onClose={handleContactFormClose} />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
        <Box sx={{ width: 250 }} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
          <IconButton onClick={handleDrawerToggle}><CloseIcon /></IconButton>
          <List>
            <ListItem button component={Link} to="/">
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/about">
              <ListItemText primary="About Us" />
            </ListItem>
            <ListItem button component={Link} to="/services">
              <ListItemText primary="Services" />
            </ListItem>
            <ListItem button component={Link} to="/blog">
              <ListItemText primary="Blog" />
            </ListItem>
            <ListItem button component={Link} to="/career">
              <ListItemText primary="Career" />
            </ListItem>
            <ListItem button component={Link} to="/contact">
              <ListItemText primary="Contact" />
            </ListItem>
            <ListItem button>
              <Button variant="contained" color="secondary" fullWidth onClick={handleContactFormOpen}>
                Get In Touch
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <ContactFormPopup open={isContactFormOpen} onClose={handleContactFormClose} />
    </>
  );
};

export default Header;