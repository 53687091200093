// src/pages/PayPerClick.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  ListAlt,
  Settings,
  Google,
  Facebook,
  Replay,
  FactCheck,
  Science,
  Autorenew,
  Verified,
  BrandingWatermark,
  LocalDining,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Best PPC company in India, Deeva payon.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PayPerClick = () => {
  return (
    <>

<Helmet>
  <title>Best PPC Company in India | Deeva Payon - Effective Pay-Per-Click Advertising</title>
  <meta
    name="description"
    content="Deeva Payon is the best PPC company in India, offering Google Ads, Facebook Ads, remarketing, keyword research, and more to boost conversions and drive traffic."
  />
  <meta
    name="keywords"
    content="Best PPC company in India, PPC services, Pay-Per-Click advertising services, Google Ads management, Facebook Ads management, Keyword research services, Remarketing services, Landing page optimization, Bing Ads management, PPC audits"
  />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Best PPC Advertising Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              Deeva Payon is recognized as the best PPC company in India, specializing in delivering high-performing Pay-Per-Click (PPC) advertising services that help businesses drive targeted traffic, boost sales, and maximize ROI.
              </Typography>
              <Typography variant="body1" paragraph>
              With our proven strategies and in-depth expertise, we create customized PPC campaigns that help your business reach the right audience and achieve tangible results. Whether you’re aiming to drive more leads, enhance brand awareness, or generate sales, our PPC services are designed to meet your unique business goals.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Best PPC Company in India for Targeted Advertising"
                title="Deevapayon, Best PPC company in India"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deeva Payon as Your PPC Partner?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading PPC company in India, we focus on delivering results that matter. Here’s why businesses trust Deeva Payon for their Pay-Per-Click advertising needs:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Tune />,
          title: 'Tailored PPC Campaigns',
          description:
            'We don’t use generic PPC strategies. Instead, we tailor our PPC campaigns based on your specific business goals, audience, and budget, ensuring that each click drives measurable value.',
        },
        {
          icon: <Groups />,
          title: 'Expert PPC Professionals',
          description:
            'Our team of certified PPC experts has years of experience in optimizing PPC campaigns for businesses across various industries. We are dedicated to staying updated on the latest trends and best practices to ensure your campaigns are effective and efficient.',
        },
        {
          icon: <BarChart />,
          title: 'Data-Driven Approach',
          description:
            'At Deeva Payon, we focus on results backed by data. We use analytics and performance metrics to continuously refine and optimize your campaigns for maximum return on investment.',
        },
        {
          icon: <ListAlt />,
          title: 'Comprehensive PPC Services',
          description:
            'From keyword research to ad creation, bidding strategies, and campaign analysis, we provide end-to-end PPC services that cover all aspects of a successful Pay-Per-Click campaign.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our PPC Services
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Settings />,
      title: 'PPC Campaign Management',
      description:
        'Our PPC campaign management services ensure that your Pay-Per-Click campaigns are executed to perfection. We handle everything from setting up your campaigns to monitoring performance, optimizing ads, and ensuring your ads are shown to the most relevant audience.',
    },
    {
      icon: <Google />,
      title: 'Google Ads Management',
      description:
        'Google Ads is one of the most powerful tools for driving targeted traffic to your website. As the best PPC company in India, we specialize in managing Google Ads campaigns that increase visibility, drive quality leads, and generate sales. We optimize your bids, select high-performing keywords, and create compelling ads that resonate with your audience.',
    },
    {
      icon: <Facebook />,
      title: 'Facebook Ads Management',
      description:
        'Social media advertising is an excellent way to reach your target audience. Our Facebook Ads management services help you create effective Facebook campaigns that drive engagement and conversions. From targeting the right demographic to creating captivating ad content, we ensure your campaigns are optimized for success.',
    },
    {
      icon: <Search />,
      title: 'Keyword Research and Strategy',
      description:
        'Effective keyword research is the foundation of any successful PPC campaign. We conduct in-depth keyword research to identify high-converting, relevant keywords for your business. By targeting the right keywords, we ensure that your ads appear to the most qualified leads, improving your ROI.',
    },
    {
      icon: <Replay />,
      title: 'Remarketing and Retargeting',
      description:
        'Remarketing is an essential aspect of PPC advertising that helps you reach potential customers who have previously interacted with your website but didn’t convert. Our remarketing services allow you to show targeted ads to these visitors, bringing them back to complete the purchase or conversion.',
    },
    {
      icon: <LocalDining />,
      title: 'Landing Page Optimization',
      description:
        'A well-optimized landing page can significantly impact your PPC campaign\'s performance. Our landing page optimization services ensure that your landing pages are designed to convert visitors into customers, providing a seamless experience and driving higher conversion rates.',
    },
    {
      icon: <BrandingWatermark />,
      title: 'Bing Ads Management',
      description:
        'Apart from Google Ads, Bing Ads is another effective platform to increase your visibility. We manage Bing Ads campaigns that target high-quality leads and maximize your ROI. With our experience in Bing Ads, we ensure that your business reaches the right audience on multiple search engines.',
    },
    {
      icon: <FactCheck />,
      title: 'PPC Audits',
      description:
        'We provide PPC audit services to identify areas for improvement in your existing campaigns. Our experts analyze your current PPC efforts and provide insights and recommendations to optimize your ads, keywords, and bidding strategies, ensuring you get the best results from your investment.',
    },
    {
      icon: <Science />,
      title: 'A/B Testing for Ads',
      description:
        'A/B testing helps determine which ad creatives, headlines, and strategies resonate best with your audience. We conduct comprehensive A/B testing to ensure that your ads are continuously optimized for the best performance, helping you achieve maximum conversions.',
    },
    {
      icon: <BarChart />,
      title: 'PPC Reporting and Analytics',
      description:
        'We provide detailed PPC reports that track the performance of your campaigns. From click-through rates (CTR) to conversion rates, we provide insights into how well your campaigns are performing and offer recommendations to improve results. Our data-driven approach ensures that you make informed decisions for your advertising strategy.',
    },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why We Are the Best PPC Company in India
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <Groups />,
      title: 'Experienced PPC Experts',
      description:
        'Deeva Payon is home to a team of certified PPC experts with years of experience in creating, managing, and optimizing PPC campaigns for businesses of all sizes. We know how to design successful PPC strategies that get results.',
    },
    {
      icon: <Tune />,
      title: 'Customized PPC Solutions',
      description:
        'We don’t offer one-size-fits-all solutions. We understand that every business is different, so we provide PPC services that are tailored to your specific goals, industry, and target audience, ensuring the best possible results.',
    },
    {
      icon: <Savings />,
      title: 'Focus on ROI',
      description:
        'Our primary focus is on maximizing your return on investment (ROI). By using advanced PPC techniques, optimizing ad spend, and targeting the right audience, we ensure that every penny you spend on ads generates measurable results.',
    },
    {
      icon: <ListAlt />,
      title: 'Comprehensive PPC Services',
      description:
        'We offer a full suite of PPC services, from strategy development to campaign management, optimization, and reporting. Our approach covers all aspects of PPC advertising to ensure your campaigns are set up for success.',
    },
    {
      icon: <Autorenew />,
      title: 'Continuous Optimization',
      description:
        'PPC is an ongoing process. We continuously monitor, test, and optimize your campaigns to improve performance, reduce costs, and ensure long-term success. Our team regularly tweaks your ads, bidding strategies, and keywords to maximize results.',
    },
    {
      icon: <Verified />,
      title: 'Proven Track Record',
      description:
        'With a history of successful campaigns and satisfied clients, we’ve earned our reputation as the best PPC company in India. Our clients trust us to manage their PPC advertising campaigns, and our results speak for themselves.',
    },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best PPC Company in India
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    If you’re looking for a PPC company in India that can help you achieve outstanding results through targeted advertising campaigns, Deeva Payon is the perfect partner for you. Our Pay-Per-Click advertising services are designed to boost your brand’s visibility, increase traffic, and drive conversions.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default PayPerClick;