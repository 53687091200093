// src/pages/FintechSoftwareDevelopment.js
import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CheckCircleOutline,
  Code,
  PhoneIphone,
  Web,
  Cloud,
  People,
  ShoppingCart,
  Api,
  DirectionsBus,
  Bolt,
  WaterDrop,
  LocalFireDepartment,
  Toll,
  CreditCard,
  Fingerprint,
  AccountBalance,
  Paid,
  Groups,
  Business,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deevapayon, Enterprise Software Development Company in India.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';

const EnterpriseSoftware = () => {
  return (
    <>
      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Enterprise Software Development Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deeva Payon, we specialize in delivering comprehensive enterprise software solutions that help businesses streamline operations, boost efficiency, and drive growth. As a leading Enterprise Software Development company in India, we provide a wide range of services designed to meet the specific needs of various industries.
              </Typography>
              <Typography variant="body1" paragraph>
              Our expertise in custom software development ensures that businesses get solutions that enhance productivity, improve workflows, and support long-term success in an ever-evolving digital landscape.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Enterprise Software Development Company in India for Business Solutions"
                title="Enterprise Software Development Company in India for Streamlined Business Operations"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Enterprise Software Development Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '3' }}
    >
      Our Enterprise Software Development services provide customized solutions to optimize business operations and enhance efficiency. We design scalable, secure, and user-friendly software to meet the unique needs of various industries.
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <People />,
              title: 'CRM Systems',
              description:
                'Customer Engagement: Our CRM solutions help businesses manage customer interactions effectively, ensuring personalized experiences and improved loyalty.\n' +
                'Sales Optimization: Enhance sales performance with real-time data insights and automated workflows.\n' +
                'Marketing Automation: Streamline marketing efforts by integrating campaigns and lead management tools.',
            },
            {
              icon: <Groups />,
              title: 'HRM Systems',
              description:
                'Workforce Management: Simplify HR processes with our intuitive HRM systems, including payroll management, attendance tracking, and performance evaluation.\n' +
                'Talent Acquisition: Automate recruitment processes and improve candidate experience.\n' +
                'Employee Engagement: Foster a positive work environment with tools for feedback and training management.',
            },
            {
              icon: <Business />,
              title: 'ERP Solutions',
              description:
                'Integrated Operations: Our ERP systems unify business functions such as finance, inventory, and supply chain management, ensuring seamless operations.\n' +
                'Data-Driven Insights: Make informed decisions with real-time analytics and reporting.\n' +
                'Scalability: Our ERP solutions are designed to grow with your business, adapting to changing needs.',
            },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Deevapayon?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '3' }}
    >
      As a Enterprise Software company in India, we pride ourselves on delivering:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <CheckCircleOutline />,
          title: 'Experience',
          description: 'With years of experience in enterprise software development, we understand the specific business needs.',
        },
        {
          icon: <CheckCircleOutline />,
          title: 'Innovation',
          description: 'We leverage the latest technologies to build scalable, secure, and user-friendly applications.',
        },
        {
          icon: <CheckCircleOutline />,
          title: 'Innovative Technology',
          description: 'Leveraging the latest fintech innovations to enhance user experience.',
        },
        {
          icon: <CheckCircleOutline />,
          title: 'Customer Support',
          description: 'Dedicated support team available to assist you at every step.',
        },
      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>
      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Benefits of Our Enterprise Software Solutions
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    <b>Increased Efficiency:</b> Automate processes, reduce manual errors, and streamline operations.
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    <b>Enhanced Customer Experience:</b> Provide users with intuitive interfaces and personalized experiences.
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    <b>Revenue Growth:</b> Expand your customer base and increase revenue through our scalable and reliable systems.
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Whether you're looking to implement new systems or upgrade existing ones, 
Deeva Payon is here to help. Contact us to discuss how our Enterprise Software Development services can transform your business.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default EnterpriseSoftware;