import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import {
  VerifiedUser,
  SupportAgent,
  TrendingUp,
  Code,
  Cloud,
  Groups,
} from "@mui/icons-material";

const reasons = [
  {
    icon: <VerifiedUser fontSize="large" />,
    title: "Trusted Expertise",
    description:
      "With years of experience, we deliver reliable and innovative solutions tailored to your needs.",
  },
  {
    icon: <SupportAgent fontSize="large" />,
    title: "24/7 Customer Support",
    description:
      "Our dedicated support team is available round-the-clock to assist you with any queries.",
  },
  {
    icon: <TrendingUp fontSize="large" />,
    title: "Proven Track Record",
    description:
      "We have successfully delivered projects for clients across diverse industries.",
  },
  {
    icon: <Code fontSize="large" />,
    title: "Cutting-Edge Technology",
    description:
      "We leverage the latest technologies to build scalable and future-proof solutions.",
  },
  {
    icon: <Cloud fontSize="large" />,
    title: "Cloud Integration",
    description:
      "Seamless integration with cloud platforms for enhanced performance and scalability.",
  },
  {
    icon: <Groups fontSize="large" />,
    title: "Collaborative Approach",
    description:
      "We work closely with you to understand your goals and deliver customized solutions.",
  },
];

const WhyChooseDeevaPayon = () => {
  return (
    <Box
      sx={{
        py: { xs: 8, md: 5 },
        px: { xs: 4, md: 8 },
        backgroundColor: "#fffcfc", // Light neutral background
        minHeight: "100vh",
      }}
    >
      {/* Section Title */}
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 6,
          }}
        >
          <Box
            sx={{
              background: "linear-gradient(135deg, #1976d2, #f05a22)",
              borderRadius: "16px",
              padding: "10px 20px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight="bold"
              sx={{
                color: "#fff", // White text on gradient background
                // textTransform: "uppercase",
                letterSpacing: 2,
              }}
            >
              Why Choose Deeva Payon?
            </Typography>
          </Box>
        </Box>

        {/* Reasons Grid */}
        <Grid container spacing={4}>
          {reasons.map((reason, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <Card
                  sx={{
                    height: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "16px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    textAlign: "center",
                    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0px 8px 20px rgba(25, 118, 210, 0.2)",
                    },
                  }}
                >
                  {/* Icon */}
                  <IconButton
                    sx={{
                      background: "#f9f9f9", // Neutral background for icons
                      border: "2px solid #1976d2", // Accent-colored border
                      color: "#1976d2", // Icon color remains visible
                      width: "60px",
                      height: "60px",
                      mb: 2,
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.1)",
                        background: "#1976d2", // Background changes on hover
                        color: "#fff", // Icon color changes to white on hover
                      },
                    }}
                  >
                    {reason.icon}
                  </IconButton>

                  {/* Title */}
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{
                      color: "#1976d2", // Single accent color
                      mb: 1,
                    }}
                  >
                    {reason.title}
                  </Typography>

                  {/* Description */}
                  <CardContent>
                    <Typography
                      sx={{
                        color: "#555",
                        fontSize: { xs: "14px", sm: "16px" },
                        lineHeight: 1.6,
                      }}
                    >
                      {reason.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default WhyChooseDeevaPayon;