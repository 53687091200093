// src/pages/Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Services from '../components/Services';
import AboutUs from '../components/AboutUs';
import Testimonials from '../components/Testimonials';
import Features from '../components/Features';
import Footer from '../components/Footer';
import ContactUs from '../components/ContactUs';
import PoweredBy from '../components/PoweredBy';
import NumbersSection from '../components/NumbersSection';
import SoftwareDevelopmentCompany from '../components/SoftwareDevelopmentCompany';
import CompanyBanner from '../components/CompanyBanner';
import ServicesSection from '../components/ServicesSection';
import WhyHireUs from '../components/WhyHireUs';
import IndustriesWeServe from '../components/IndustriesWeServe';
import CallToAction from '../components/CallToAction';
import BusinessHelpSection from '../components/BusinessHelpSection';

const Home = () => {
  return (
    <>
      {/* Google Tag Manager - Head */}
      <Helmet>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-M69JQNB5');
          `}
        </script>

        <meta name="google-site-verification" content="BrMSBaVBTja0PI1IL9tAPVKD8rkuZq9lb1_9u_aGRIA" />

        
      </Helmet>

      {/* Google Tag Manager - Body */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-M69JQNB5"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Header />
        <Box
          component="main"
          sx={{
            flex: '1 0 auto', // Allows the main content to expand and fill available space
          }}
        >
          <Hero />
          <BusinessHelpSection/>
          {/* <CompanyBanner/> */}
          {/* <SoftwareDevelopmentCompany/> */}
          {/* <AboutUs /> */}
          <NumbersSection/>
          <ServicesSection/>
          {/* <Services /> */}
          {/* <PoweredBy /> */}
          <IndustriesWeServe/>
          <WhyHireUs/>
          <CallToAction/>
          <Testimonials />
          {/* <Features /> */}
          <ContactUs />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Home;
