// src/pages/PackagingDesign.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Store,
  Fastfood,
  Face,
  FormatPaint,
  DesignServices,
  EjectOutlined,
  ElectricCarOutlined,
  ListAlt,
  PrecisionManufacturing,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/packaging-desgin.jpg';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PackagingDesign = () => {
  return (
    <>

<Helmet>
  <title>Packaging Design Services | Custom & Creative Packaging Solutions</title>
  <meta name="description" content="Discover exceptional packaging design services that make your product stand out. From custom packaging to sustainable solutions, our expert team delivers unique designs tailored to your brand. Contact us today!" />
  <meta name="keywords" content="Packaging Design, Custom Packaging Design, Packaging Design Services, Sustainable Packaging, Branding and Label Design, Retail Packaging, E-commerce Packaging, Product Packaging Design, Eco-friendly Packaging" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Packaging design company in india
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we understand the powerful impact of exceptional packaging design on your brand's identity and product success. Our Packaging Design services are tailored to meet your specific needs, ensuring that your product stands out on the shelves and resonates with your target audience.
              </Typography>
              <Typography variant="body1" paragraph>
              With a combination of creativity, strategy, and industry expertise, we deliver high-quality packaging that not only protects your product but also enhances its visual appeal and marketability.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Custom packaging design showcasing innovative and eco-friendly materials"
                title="Creative and Custom Packaging Design Solutions for Your Brand"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Packaging Design Matters
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      Packaging is much more than a protective layer for your product. It’s an essential element of your brand's story, helping to communicate the value, quality, and uniqueness of your product. A well-designed package grabs attention, creates desire, and influences purchasing decisions. Whether you are launching a new product or revamping an existing one, the right packaging can make all the difference.
    </Typography>
    

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
    {
      icon: <DesignServices />,
      title: 'Custom Packaging Design',
      description:
        'Our team of skilled designers will create unique and innovative packaging tailored to your product\'s needs. We focus on blending aesthetics with functionality, ensuring your packaging is not only visually appealing but also practical.',
    },
    {
      icon: <FormatPaint />,
      title: 'Branding and Label Design',
      description:
        'Packaging plays a crucial role in reinforcing your brand identity. Our design experts work closely with you to develop packaging that effectively communicates your brand\'s personality through color schemes, typography, logos, and design elements.',
    },
    {
      icon: <EjectOutlined />,
      title: 'Sustainable Packaging Solutions',
      description:
        'As sustainability becomes increasingly important to consumers, we provide eco-friendly packaging design solutions. From recyclable materials to minimalist designs, we help you choose the best sustainable options that align with your brand’s values.',
    },
    {
      icon: <Store />,
      title: 'Retail and Shelf Packaging',
      description:
        'Packaging design for retail spaces requires a strategic approach. We create shelf-ready packaging that catches the eye, stands out in-store, and is easy to handle for both customers and retailers. Our designs are optimized for shelf impact and ease of use.',
    },
    {
      icon: <Build />,
      title: 'Packaging Prototyping',
      description:
        'We offer prototyping services to bring your packaging concept to life before mass production. This helps you visualize the design and make necessary adjustments, ensuring the final product is exactly what you envision.',
    },
    {
      icon: <Fastfood />,
      title: 'Food and Beverage Packaging Design',
      description:
        'Packaging for food and beverages needs to be functional, appealing, and compliant with industry regulations. Our designers specialize in creating packaging that maintains freshness, showcases your product, and adheres to health and safety standards.',
    },
    {
      icon: <Face />,
      title: 'Cosmetic and Beauty Packaging Design',
      description:
        'For beauty products, packaging is a key factor in attracting customers. We create elegant, high-quality packaging that reflects the luxurious nature of your cosmetic products and enhances the consumer experience.',
    },
    {
      icon: <ShoppingCart />,
      title: 'E-commerce Packaging Design',
      description:
        'As online shopping continues to grow, we provide e-commerce packaging design solutions that ensure your products are well-protected during transit while providing an unboxing experience that delights customers.',
    },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us for Your Packaging Design?
    </Typography>

    {/* Subtitle */}
    {/* <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a trusted graphic design company in India, we are committed to delivering designs that not only meet your expectations but exceed them. We take pride in offering:
    </Typography> */}

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Tune />,
          title: 'Tailored Solutions',
          description:
            'Our packaging design services are customized to meet the specific needs of your brand and product.',
        },
        {
          icon: <Palette />,
          title: 'Creative Expertise',
          description:
            'Our design team is experienced in creating innovative and standout packaging that leaves a lasting impression.',
        },
        {
          icon: <PrecisionManufacturing />,
          title: 'Attention to Detail',
          description:
            'From material selection to color choice and typography, we ensure every aspect of your packaging design is perfect.',
        },
        {
          icon: <ElectricCarOutlined />,
          title: 'Sustainability',
          description:
            'We prioritize eco-friendly packaging solutions to help your brand reduce its environmental footprint.',
        },
        {
          icon: <ListAlt />,
          title: 'Full-Service Design',
          description:
            'From conceptualization to prototyping and final production, we handle every step of the Packaging Design process.',
        },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    <Typography variant="h4" align="center" gutterBottom>
    Let’s Create Impactful Packaging Together
    </Typography>
    <Typography variant="body1" align="center" paragraph>
    Ready to elevate your product with exceptional Packaging Design? Contact us today to discuss how our services can help transform your product packaging into a powerful marketing tool. Our team is excited to work with you and bring your ideas to life!

    </Typography>
    <Typography variant="body1" align="center" paragraph>
    By focusing on creativity, innovation, and sustainability, we’re committed to delivering the best packaging design solutions for your brand. Whether you need custom packaging, retail packaging, or eco-friendly designs, we have the expertise to meet your needs. Get in touch to start your packaging design journey today!

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default PackagingDesign;