// src/pages/LogoDesign.js
import React from 'react';
import {Box, Typography, Container, Grid, Button, Card, CardContent, CardMedia, CardActions, Avatar, List, ListItem, ListItemIcon, ListItemText, } from '@mui/material';
import { CheckCircleOutline, Code, PhoneIphone, Web, Cloud, People, ShoppingCart, Api, DirectionsBus, Bolt, WaterDrop, LocalFireDepartment, Toll, CreditCard, Fingerprint, AccountBalance, Paid, School, FlashOn, Campaign, Assessment,
  SupportAgent,
  Tune,
  Build,
  IntegrationInstructions,
  VerifiedUser,
  Savings,
  Business,
  SettingsApplications,
  TrendingUp,
  ThumbUp,
  Email,
  Groups,
  Article,
  Share,
  Search,
  Slideshow,
  Draw,
  AdUnits,
  ContactPage,
  BarChart,
  Print,
  Palette,
  Brush,
  Schedule,
  AccountTree,
  AutoAwesome,
  Crop,
  Star,
  AutoFixHigh,
  History,
  TextFields,
  Timer,
} from '@mui/icons-material';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Softimg from '../assest/Deevapayon - Logo Design Company in India.png';
import CallToAction from '../Services/CallToAction';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LogoDesign = () => {
  return (
    <>

<Helmet>
  <title>Professional Logo Design Company in India | Custom Logo Solutions</title>
  <meta name="description" content="Looking for a reliable logo design company in India? We specialize in creating custom, memorable logos that reflect your brand’s identity. Get professional logo design services today." />
  <meta name="keywords" content="logo design company in India, custom logo design, brand identity, minimalist logo design, corporate logo design, 3D logo design, logo redesign, icon-based logo design, typography-based logo design, e-commerce logo design" />
</Helmet>

      {/* Header Component */}
      <Header />

      {/* Hero Section */}
      <Box sx={{ backgroundColor: '#fff', padding: '7rem 0' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            {/* Left Text Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  color: '#1976d2',
                  marginBottom: '1.5rem',
                }}
              >
                Deevapayon: Your Logo Design Company in India
              </Typography>
              <Typography variant="body1" paragraph>
              At Deevapayon, we understand that your logo is the face of your brand. As a logo design company in India, we specialize in crafting logos that speak volumes about your business identity.
              </Typography>
              <Typography variant="body1" paragraph>
              Our expert design team combines creativity, innovation, and strategic thinking to produce unique, memorable, and versatile logos that help your brand stand out in the marketplace. Whether you're a startup or an established business, we ensure your logo captures the essence of your brand and leaves a lasting impression.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/contact"
                sx={{
                  marginTop: '1.5rem',
                  padding: '0.8rem 2rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                Get Started
              </Button>
            </Grid>

            {/* Right Image Section */}
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                image={Softimg}
                alt="Custom logo design by a leading logo design company in India - creating unique, impactful logos for brands"
                title="Professional Logo Design Services by Deevapayon - Logo Design Company in India"
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Our Fintech Software Services Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '0rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Our Logo Design Services
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a leading logo design company in India, we offer a wide range of logo design services tailored to your specific business needs. Our services include:
    </Typography>

    {/* Service Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
        {
          icon: <Brush />,
          title: 'Custom Logo Design',
          description:
            'Your logo should be as unique as your business. Our custom logo design services create original logos tailored to your brand’s vision and values. We ensure that your logo stands out and resonates with your target audience, giving you a competitive edge.',
        },
        {
          icon: <Palette />,
          title: 'Brand Identity Development',
          description:
            'A great logo is just the beginning. We offer brand identity development to create a cohesive look and feel for your brand across all platforms. From color schemes to typography, we make sure your logo aligns with your overall branding strategy.',
        },
        {
          icon: <Crop />,
          title: 'Minimalist Logo Design',
          description:
            'Sometimes, less is more. Our minimalist logo design services focus on clean, simple, and elegant logos that convey your brand’s message clearly and effectively. We believe in the power of simplicity to make a strong visual impact.',
        },
        {
          icon: <Star />,
          title: 'Icon-based Logo Design',
          description:
            'Icons are powerful visual tools for brand recognition. Our icon-based logo design service creates symbols that are easily identifiable, memorable, and scalable across different platforms. We design icons that work well in both digital and print media.',
        },
        {
          icon: <History />,
          title: 'Vintage & Retro Logo Design',
          description:
            'For businesses seeking a nostalgic touch, we offer vintage and retro logo design services. We blend classic design elements with modern trends to create logos that evoke a sense of tradition while remaining fresh and appealing.',
        },
        {
          icon: <dRotation />,
          title: '3D Logo Design',
          description:
            'Take your brand to the next level with our 3D logo design service. Our team creates dynamic, visually striking logos that add depth and dimension, making your brand appear more modern and innovative.',
        },
        {
          icon: <AutoFixHigh />,
          title: 'Logo Redesign & Revamping',
          description:
            'If your existing logo no longer represents your brand accurately or needs a refresh, we provide logo redesign and revamping services. We help modernize your logo while keeping the core elements intact, ensuring continuity and recognition.',
        },
        {
          icon: <TextFields />,
          title: 'Typography-based Logo Design',
          description:
            'Typography is at the heart of many iconic logos. Our typography-based logo design services focus on creating custom letterforms that are both creative and legible, helping your brand stand out with unique fonts and lettering styles.',
        },
        {
          icon: <Business />,
          title: 'Corporate Logo Design',
          description:
            'For large businesses or corporations, we offer professional corporate logo design services that represent your company’s authority, values, and professionalism. We design logos that are sophisticated, versatile, and suitable for various corporate applications.',
        },
        {
          icon: <ShoppingCart />,
          title: 'E-commerce Logo Design',
          description:
            'In the digital world, your logo needs to stand out online. Our e-commerce logo design services are tailored to create logos that are easily recognizable, scalable, and effective across digital platforms, helping your online business build trust and recognition.',
        },

      ].map((service, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {service.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {service.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {service.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Why Choose Us Section */}
<Box
  sx={{
    display: "flex",
    justifyContent: "center", // Center horizontally
    alignItems: "center", // Center vertically
    padding: { xs: '4rem 0', md: '3rem 0' }, // Responsive padding
    backgroundColor: '#fff', // Light gray background
  }}
>
  <Container maxWidth="lg">
    {/* Title */}
    <Typography
      variant="h4"
      align="center"
      gutterBottom
      sx={{
        fontWeight: "bold",
        color: "#fff", // White text color
        background: "#1976d2", // Blue background
        borderRadius: "16px", // Rounded corners
        padding: "10px 20px", // Padding for better spacing
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
        textTransform: "uppercase", // Uppercase text
        letterSpacing: "2px", // Spacing between letters
        display: "flex", // Use flexbox for centering
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        margin: "0 auto", // Center the box itself
        maxWidth: "fit-content", // Ensure the box only wraps the content
      }}
    >
      Why Choose Us as Your Logo Design Company in India?
    </Typography>

    {/* Subtitle */}
    <Typography
      variant="body1"
      align="center"
      paragraph
      sx={{ color: '#555', lineHeight: '1.5', marginTop: '20px' }}
    >
      As a premier logo design company in India, we strive to create logos that not only look visually appealing but also serve as powerful tools for your brand’s growth. Here’s why Deevapayon is the right choice for your logo design needs:
    </Typography>

    {/* Reason Grid */}
    <Grid container spacing={6} sx={{ marginTop: '2rem' }}>
      {[
            {
              icon: <Tune />,
              title: 'Tailored Designs',
              description:
                'Every logo we design is customized to fit your brand’s personality and business goals.',
            },
            {
              icon: <Groups />,
              title: 'Experienced Designers',
              description:
                'Our team of skilled designers brings years of expertise to create impactful logos that resonate with your audience.',
            },
            {
              icon: <Palette />,
              title: 'Creative Solutions',
              description:
                'We focus on innovative design techniques that help your brand stand out from the competition.',
            },
            {
              icon: <Savings />,
              title: 'Affordable Pricing',
              description:
                'We offer high-quality logo design services at competitive rates, ensuring great value for your investment.',
            },
            {
              icon: <Timer />,
              title: 'Quick Turnaround',
              description:
                'We understand the importance of time, which is why we ensure fast delivery without compromising on quality.',
            },

      ].map((reason, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              backgroundColor: '#f9f9f9', // Light gray card background
              borderRadius: '12px', // Rounded corners
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
              transition: 'transform 0.3s ease', // Smooth hover effect
              '&:hover': {
                transform: 'translateY(-5px)', // Lift on hover
              },
            }}
          >
            <CardContent>
              {/* Icon */}
              <Avatar
                sx={{
                  backgroundColor: '#1976d2', // Blue background
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 1rem', // Centered with spacing below
                }}
              >
                {reason.icon}
              </Avatar>

              {/* Title */}
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: '600', color: '#333', textAlign: 'center' }}
              >
                {reason.title}
              </Typography>

              {/* Description */}
              <Typography
                variant="body2"
                paragraph
                sx={{ color: '#555', lineHeight: '1.8', textAlign: 'center' }}
              >
                {reason.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
</Box>

      {/* Conclusion Section */}
<Box sx={{ padding: '4rem 0', backgroundColor: '#ffffff' }}>
  <Container maxWidth="lg">
    {/* <Typography variant="h4" align="center" gutterBottom>
    Get Started with the Best Graphic Design Agency in India
    </Typography> */}
    <Typography variant="body1" align="center" paragraph>
    Deevapayon, a leading logo design company in India, creates a logo that captures your brand’s essence and makes a lasting impression. Get in touch today to start your journey toward a unique and powerful brand identity.

    </Typography>
    {/* Centered Button */}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1.5rem',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        component={Link}
        to="/contact"
        sx={{
          padding: '0.8rem 2rem',
          borderRadius: '50px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        Contact Us
      </Button>
    </Box>
  </Container>
</Box>

<CallToAction />
      {/* Footer Component */}
      <Footer />
    </>
  );
};

export default LogoDesign;